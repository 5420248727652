<template>
  <div id="footer">
    <div class="footer-new">
      <div class="container-fluid">
        <div class="row">
          <div class="col-sm-6">
            <div class="about">
              <router-link :to="{ name: 'DashboardNew' }">
                <img
                  class="footer_logo"
                  src="@/assets/images/logo-iha.svg"
                  alt="logo"
                />
              </router-link>
              <p>
                If you have some technical difficulties Go to:
                <a target="_blank" :href="ticketing_url">
                  Issues Dashboard</a
                >
              </p>
              <br />
              <span
                >&copy; Copyright {{ new Date().getFullYear() }}. All Rights
                Reserved</span
              >
            </div>
          </div>

          <div class="col-sm-6">
<!--            <div class="download">
              <span>Download The Member App</span>
              <div class="app_download">
                <a
                  target="_blank"
                  href="https://apps.apple.com/us/app/corenroll-member/id1539841827"
                  ><img
                    src="../assets/images/applestore.png"
                    alt="apple"
                    width="162"
                    height="52"
                /></a>

                <a
                  target="_blank"
                  href="https://play.google.com/store/apps/details?id=com.neura.corenroll_member"
                  ><img
                    src="../assets/images/playstore.png"
                    alt="apple"
                    width="162"
                    height="50"
                /></a>
              </div>
            </div>-->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ticketing_url } from '../utlis/constant';
export default{
  data(){
return{
  ticketing_url
}
  }
}
</script>
<style>
#footer {
  padding-top: 0px;
  /* background-color: #f3f6f8 !important; */
}

.footer-new {
  background: var(--header-bg-color);
  padding: 30px;
}

/* .footer-new .about {
  background: var(--bg-color);
} */
.footer-new .footer_logo {
  height: 80px;
}

#footer .row {
  margin: 0 0 0px 0;
  background: var(--header-bg-color);
  border-radius: 3px;
  padding: 0px 0px;
}
#footer .footer_logo {
  margin-bottom: 20px !important;
  margin-top: 0px !important;
  height: 80px;
}
#footer p {
  font-size: 13px !important;
  color: var(--primary-text-color) !important;
}
#footer .about p a {
  color: var(--danger-text-color) !important;
}
.footer-new span {
  color: var(--primary-text-color);
}
#footer .app_download {
  margin: 10px 0 !important;
}
#footer .app_download img {
  margin-right: 10px !important;
}
</style>
