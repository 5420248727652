<template>
  <div id="app">
    <vue-confirm-dialog class="member-app-confirm-dialog"></vue-confirm-dialog>
    <div v-if="MemberHeader" class="">
      <Header />
    </div>
    <div v-if="defaultHeader || repHeader" class="">
      <RepRegistrationHeader />
    </div>
    <div v-if="reviewHeader">
      <review-header />
    </div>
    <div class="member-body-wrap">
      <router-view />
    </div>
    <div v-if="contractFooter" class="">
      <ContractFooter />
    </div>
    <div v-if="defaultFooter">
      <Footer />
    </div>
    <div v-if="reviewFooter">
      <review-footer />
    </div>
    <notifications />
    <app-download-modal :appUrl="appDownloadUrl" />
  </div>
</template>
<script>
/*eslint-disable */
import Header from "@/components/Header.vue";

import RepRegistrationHeader from "@/components/RepRegistrationHeader.vue";
import ContractFooter from "@/components/contractform/ContractFooter.vue";
import Footer from "@/components/Footer.vue";
import ReviewFooter from "./components/review_rating/reviewFooter.vue";
import reviewHeader from "./components/review_rating/reviewHeader.vue";
import AppDownloadModal from "@/components/AppDownloadModal.vue";
import endpoint from "@/resource/endpoint";
import moment from "moment";
import DeviceHelper from "@/resource/DeviceHelper";

export default {
  components: {
    Header,
    RepRegistrationHeader,
    ContractFooter,
    Footer,
    ReviewFooter,
    reviewHeader,
    AppDownloadModal,
  },
  data: function () {
    return {
      defaultHeader: true,
      repHeader: false,
      MemberHeader: false,
      contractFooter: false,
      defaultFooter: true,
      reviewFooter: false,
      reviewHeader: false,
      appDownloadUrl: false,
    };
  },
  mounted() {
    /*
    if (
      this.$route.name == "representative-info" ||
      this.$route.name == "personal-info" ||
      this.$route.name == "contact-info" ||
      this.$route.name == "address-info" ||
      this.$route.name == "reg-type" ||
      this.$route.name == "business-info" ||
      this.$route.name == "payment-info" ||
      this.$route.name == "signature-verification" ||
      this.$route.name == "PersonalInfo" ||
      this.$route.name == "ContactInfo" ||
      this.$route.name == "AddressInfo" ||
      this.$route.name == "BillingInfo" ||
      this.$route.name == "Agreement" ||
      this.$route.name == "CommissionSheet" ||
      this.$route.name == "FeeStructure" ||
      this.$route.name == "Personal" ||
      this.$route.name == "Address" ||
      this.$route.name == "TaxpayerId" ||
      this.$route.name == "Certification" ||
      this.$route.name == "GeneralInstruction" ||
      this.$route.name == "SpecificInstruction" ||
      this.$route.name == "Login" ||
      this.$route.name == "SignUp" ||
      this.$route.name == "SignUpContinue" ||
      this.$route.name == "VerifyOTP" ||
      this.$route.name == "VerifyPasswordChangeOTP" ||
      this.$route.name == "ForgotPassword" ||
      this.$route.name == "PasswordChange"
    ) {
      this.defaultHeader = false;
      this.defaultFooter = false;
      this.contractFooter = true;
      this.repHeader = true;
      this.MemberHeader = false;
    } else {
      this.defaultHeader = true;
      this.defaultFooter = true;
     this.repHeader = false;
     this.MemberHeader = false;
     this.contractFooter = false;
      if ("MemberDashboard" == this.$route.name) {
        this.MemberHeader = true;
        this.defaultHeader = false;
        this.defaultFooter = true;
        this.contractFooter = false;
      }
    } */
    setTimeout(() => {
      this.checkUserDevice();
    }, 2000);
    this.renderFavInfo();
  },
  watch: {
    "$route.params": {
      handler() {
        let memberRoutesNotAuthenticated = [
          "Login",
          "SignUp",
          "SignUpContinue",
          "VerifyOTP",
          "VerifyPasswordChangeOTP",
          "ForgotPassword",
          "PasswordChange",
          "PlanChangedConfirmationSign",
        ];
        let memberRoutesAuthenticated = [
          "MemberDashboard",
          "NeuraBlogs",
          "PlanDetail",
          "ViewMessage",
          "ViewKnowledge",
          "MemberReferral",
          "DashboardNew",
          "DocumentViewDetails",
          "Tickets",
          "ProviderList",
          "ClaimListing",
          "AddRequest"
        ];
        if (
          this.$store.state.authenticated &&
          memberRoutesAuthenticated.includes(this.$route.name)
        ) {
          this.defaultHeader = false;
          this.repHeader = false;
          this.MemberHeader = true;
          this.contractFooter = false;
          this.defaultFooter = true;
        } else {
          this.defaultHeader = true;
          this.repHeader = false;
          this.MemberHeader = false;
          this.contractFooter = true;
          this.defaultFooter = false;
        }

        if (memberRoutesNotAuthenticated.includes(this.$route.name)) {
          this.contractFooter = false;
          this.defaultFooter = true;
        }

        if (
          this.$route.name == "ReviewOption" ||
          this.$route.name == "ReviewForm" ||
          this.$route.name == "MessagePage"
        ) {
          this.contractFooter = false;
          this.defaultFooter = false;
          this.reviewFooter = true;
        }

        if (
          this.$route.name == "ReviewOption" ||
          this.$route.name == "ReviewForm" ||
          this.$route.name == "MessagePage"
        ) {
          this.defaultHeader = false;
          this.repHeader = false;
          this.MemberHeader = false;
          this.reviewHeader = true;
        }

        // } else if(this.$store.state.repId) {
        //   this.defaultHeader = true;
        //   this.repHeader = false;
        // } else {
        //    this.defaultHeader = true;
        //   this.repHeader = true;
        // }
      },
      immediate: true,
    },
  },
  methods: {
    logout() {
      helper.logout();
    },
    checkUserDevice() {
      let device = new DeviceHelper();
      if (device.isAndroid()) {
        this.appDownloadUrl = endpoint.ANDROID_APP_URL;
        this.appDownloadModal();
      } else if (device.isIos()) {
        this.appDownloadUrl = endpoint.IOS_APP_URL;
        this.appDownloadModal();
      }
    },

    appDownloadModal() {
      if (localStorage.use_web_status && localStorage.status_expiry) {
        let expiryDate = moment(localStorage.status_expiry);
        let currentDate = moment(new Date());
        let duration = moment.duration(expiryDate.diff(currentDate));
        if (duration.asHours() < 0) {
          localStorage.use_web_status = "";
          localStorage.status_expiry = "";
          this.$bvModal.show("app-download-modal");
        }
      } else {
        this.$bvModal.show("app-download-modal");
      }
    },
    renderFavInfo () {
      let favicon = document.getElementById("favicon");
      favicon.href = 'favicon.png'
      document.title = `Performance Health`
    },
  },
};
</script>
<style>
@import "css/style.css";

@font-face {
  font-family: "SF UI Display Regular";
  font-style: normal;
  font-weight: normal;
  src: local("SF UI Display Regular"),
    url("assets/fonts/SF-UI-Display-Regular.woff") format("woff");
}
@font-face {
  font-family: "SF UI Display Ultralight";
  font-style: normal;
  font-weight: normal;
  src: local("SF UI Display Ultralight"),
    url("assets/fonts/sf-ui-display-ultralight-58646b19bf205.woff")
      format("woff");
}
@font-face {
  font-family: "SF UI Display Thin";
  font-style: normal;
  font-weight: normal;
  src: local("SF UI Display Thin"),
    url("assets/fonts/sf-ui-display-thin-58646e9b26e8b.woff") format("woff");
}
@font-face {
  font-family: "SF UI Display Light";
  font-style: normal;
  font-weight: normal;
  src: local("SF UI Display Light"),
    url("assets/fonts/sf-ui-display-light-58646b33e0551.woff") format("woff");
}
@font-face {
  font-family: "SF UI Display Medium";
  font-style: normal;
  font-weight: normal;
  src: local("SF UI Display Medium"),
    url("assets/fonts/sf-ui-display-medium-58646be638f96.woff") format("woff");
}
@font-face {
  font-family: "SF UI Display Semibold";
  font-style: normal;
  font-weight: normal;
  src: local("SF UI Display Semibold"),
    url("assets/fonts/sf-ui-display-semibold-58646eddcae92.woff") format("woff");
}
@font-face {
  font-family: "SF UI Display Bold";
  font-style: normal;
  font-weight: normal;
  src: local("SF UI Display Bold"),
    url("assets/fonts/sf-ui-display-bold-58646a511e3d9.woff") format("woff");
}
@font-face {
  font-family: "SF UI Display Heavy";
  font-style: normal;
  font-weight: normal;
  src: local("SF UI Display Heavy"),
    url("assets/fonts/sf-ui-display-heavy-586470160b9e5.woff") format("woff");
}
@font-face {
  font-family: "SF UI Display Black";
  font-style: normal;
  font-weight: normal;
  src: local("SF UI Display Black"),
    url("assets/fonts/sf-ui-display-black-58646a6b80d5a.woff") format("woff");
}
/* ******************* */
#app {
  font-family: SF UI Display, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
}
* {
  box-sizing: border-box;
}
#nav {
  padding: 17px 0;
  background: #1b1b1b;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
  /* height: 73px; */
}
#repNav {
  padding: 10px 0;
  /* background: rgba(0, 0, 0, 0.3); */
  display: flex;
  justify-content: center;
  /* position: fixed; */
  top: 0;
  width: 100%;
  z-index: 999;
  align-items: center;
}
#repNav .logo {
  margin: 0;
}

#repNav .logo img {
  height: 80px;
}

.question,
.bell {
  color: #cccccc;
  font-family: "SF UI Display Regular";
}
.question:hover,
.bell:hover {
  text-decoration: none;
  color: white;
}
#dropdown .btn {
  border-color: black;
  background-color: black;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
.logo {
  display: flex;
  align-items: center;
  margin-left: 45px;
}
.right-nav {
  display: flex;
  justify-content: space-between;
}
#nav .search {
  display: flex;
  align-items: center;
}
.search input {
  margin-right: 110px;
  width: 375px;
  height: 45px;
  background: white;
  border-radius: 23px;
  outline: none;
  padding-left: 20px;
}
.search a {
  margin-right: 180px;
  font-size: 16px;
  display: flex;
  align-items: center;
}
.right-most img {
  width: 39px;
  height: 39px;
  border-radius: 50%;
}
.right-most {
  display: flex;
  margin-right: 35px;
}
.right-most .bell {
  margin: 10px 45px 0 0;
}
.right-most .down {
  margin: 10px 0 0 8px;
}

/* footer */
#footer {
  /* background: #0e0e0e; */
  color: white;
  /* padding-top: 50px; */
  font-family: "SF UI Display Regular";
}
#footer .footer_logo {
  margin-bottom: 20px;
  margin-top: 25px;
}
#footer .container {
  display: flex;
  justify-content: space-between;
  padding: 30px;
}
#footer p {
  font-size: 15px;
  color: rgba(255, 255, 255, 0.65);
}
#footer .about p a {
  color: white;
}
#footer .about p a:hover {
  text-decoration: none;
}

#footer .app_download {
  margin: 20px 0;
}
#footer .app_download img {
  margin-right: 15px;
}
#footer .copyright {
  padding: 12px 15px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: #060606; */
  color: var(--primary-text-color);
  margin-top: 30px;
  font-size: 15px;
}
@media only screen and (max-width: 1490px) {
  .search input {
    margin-right: 50px;
  }
  /* .search a {
    margin-right: 100px;
  } */
}

@media (max-width: 575px) {
  #footer .download {
    margin-top: 30px;
  }
}

@media only screen and (max-width: 992px) {
  .search a {
    margin-right: 120px;
  }
  .search input {
    /* width:200px; */
    margin-right: 0;
  }
  /* .right-nav{
   flex-wrap:wrap;
  } */
  #footer {
    padding-top: 20px;
    font-size: 14px;
  }
  #footer .container {
    display: block;
  }
  #footer .download img {
    margin: 5px;
  }
  #footer .copyright {
    margin-top: 20px;
  }
}
@media only screen and (max-width: 767px) {
  .logo {
    margin-left: 15px;
  }
  .right-nav {
    margin-top: 10px;
    margin-left: 15px;
  }
  .search a {
    margin-right: 0;
  }
  .right-most .bell {
    margin-right: 10px;
  }
  .search input {
    width: 320px;
  }
  .right-most {
    margin-right: 10px;
  }
}
@media only screen and (max-width: 499px) {
  .search input {
    width: 180px;
  }
  .logo {
    justify-content: center;
    margin: 0;
  }
}
</style>
