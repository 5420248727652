<template>
    <section class="container-wrapper review-container">
        <div class="container">
            <div class="row">
                <div class="col-md-8 offset-md-2 ">
                    <div class="review-form-wrap white-wrap">
<!--                    <h1>{{ ownerTitle }}</h1>-->
                    <form>
                        <div class="form-group">
                            <h3>Overall Rating <span class="required-alert">*</span></h3>
                            <div class="s-col">
                                <star-rating increment="0.5" star-size="32" padding="1" active-color="#29c4ee" v-model="formFields.stars.value"/>
                                <small class="error_message" v-if="formErrorFlag == true">{{formErrors['stars_message']}}</small>
                            </div>
                        </div>
                        <div class="form-group">
                            <h3>Title of your review <span class="required-alert">*</span></h3>
                            <input type="text" name="title" v-model="formFields.title.value" placeholder="Give your review title here" required>
                            <small class="error_message" v-if="formErrorFlag == true">{{formErrors['title_message']}}</small>
                        </div>
                        <div class="form-group">
                            <h3>Your Review Message <span class="required-alert">*</span></h3>
                            <textarea rows="4" name="message" v-model="formFields.message.value" placeholder="Write your review to help others learn about this business" required></textarea>
                            <small class="error_message" v-if="formErrorFlag == true">{{formErrors['message_message']}}</small>
                        </div>

                        <div class="form-group">
                            <label class="switch" for="checkbox">
                                <input type="checkbox" id="checkbox" v-model="formFields.anonymous.value"/>
                                <div class="slider round"></div>
                            </label>
                            <span class="anoymous-text">Submit feedback as anonymous user</span>
                        </div>

                        <div class="form-group row" v-if="!formFields.anonymous.value">
                            <div class="col-md-4 mb-20">
                                <h3>Your Name <span class="optional-text">(optional)</span></h3>
                                <input type="text" name="name" v-model="formFields.name.value" placeholder="Full Name">
                                <small class="error_message" v-if="formErrorFlag == true">{{formErrors['name_message']}}</small>
                            </div>
                            <div class="col-md-4 mb-20">
                                <h3>Email Address <span class="optional-text">(optional)</span></h3>
                                <input type="email" name="email" v-model="formFields.email.value" placeholder="Email Address">
                                <small class="error_message" v-if="formErrorFlag == true">{{formErrors['email_message']}}</small>
                            </div>
                            <div class="col-md-4">
                                <h3>Phone Number <span class="optional-text">(optional)</span></h3>
                                <input type="text" name="phone" v-model="formFields.phone.value" placeholder="Phone Number">
                                <small class="error_message" v-if="formErrorFlag == true">{{formErrors['phone_message']}}</small>
                            </div>
                        </div>

                        <div class="form-group alignR">

                            <b-button class="submit-btn" @click="checkForm" :disabled="isProcessing">
                                <img src="../../assets/images/loader.svg" alt="" width="20" v-if="isProcessing"> Submit Review
                            </b-button>
                            <b-button class="cancel-btn" @click="$router.push({ path: 'review-option'});">Cancel</b-button>
                        </div>
                    </form>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import StarRating from "vue-star-rating"
import axios from "axios";

export default {
    name: "ReviewForm",
    components: {
        StarRating
    },
    data: function () {
        return {
            owner: '',
            ownerTitle: '',
            formFields: {
                stars: {
                    value: null,
                    required: true,
                },
                title: {
                    value: null,
                    required: true,
                },
                message: {
                    value: null,
                    required: true,
                },
                anonymous: {
                      value: false,
                      required: false,
                },
                name: {
                    value: null,
                    required: false,
                },
                email: {
                    value: null,
                    required: false,
                },
                phone: {
                    value: null,
                    required: false,
                },
            },
            formErrorFlag: false,
            formErrors: [],
            isProcessing: false,
        }
    },
    mounted() {
        this.owner = this.$route.query.owner;
        this.setOwner();
    },
    methods: {
        setOwner: function () {
            if (this.owner == 'members') {
                this.ownerTitle = 'Member / Employee Review';
            } else if(this.owner == 'employers') {
                this.ownerTitle = 'Employers / Groups Review';
            } else if(this.owner == 'brokers') {
                this.ownerTitle = 'Broker / Reps Review';
            } else {
                this.ownerTitle = 'Leave a Review';
            }
        },
        checkForm: function () {
            let app = this;
            app.isProcessing = true;
            app.formErrorFlag = false;
            app.formErrors    = [];
            if (!app.formFields.stars.value || app.formFields.stars.value == '') {
                app.formErrors['stars_message'] = 'Rating is required';
            }
            if (!app.formFields.title.value) {
                app.formErrors['title_message'] = 'Title is required';
            }
            if (!app.formFields.message.value) {
                app.formErrors['message_message'] = 'Message is required';
            }

            if (app.formFields.anonymous.value != true) {
                if (!app.formFields.name.value) {
                    app.formErrors['name_message'] = 'Name is required';
                }

                if (!app.formFields.email.value) {
                    app.formErrors['email_message'] = 'Email is required';
                } else if (!app.validateEmail(app.formFields.email.value)) {
                    app.formErrors['email_message'] = 'Enter a valid email';
                }

                if (!app.formFields.phone.value) {
                    app.formErrors['phone_message'] = 'Phone is required';
                } else if (!app.validatePhoneNumber(app.formFields.phone.value)) {
                    app.formErrors['phone_message'] = 'Enter a valid phone number';
                }
            }

            if (Object.keys(app.formErrors).length <= 0) {
                app.saveFeedback();
            } else {
                app.formErrorFlag = true;
                app.isProcessing = false;
            }
        },
        validateEmail: function (email) {
            let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(email);
        },
        validatePhoneNumber(phone){
            let re = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
            return re.test(phone);
        },
        saveFeedback: function () {
            let app = this;
            app.formErrorFlag = false;
            app.formErrors    = [];
            let params = {
                owner: app.owner,
                stars: app.formFields.stars.value,
                title: app.formFields.title.value,
                message: app.formFields.message.value,
                anonymous: app.formFields.anonymous.value,
                name: app.formFields.name.value,
                email: app.formFields.email.value,
                phone: app.formFields.phone.value,
            };

            // Discard null values
            for (const key of Object.keys(params)) {
                if (params[key] === null) {
                    delete params[key];
                }
            }

            axios.post(process.env.VUE_APP_CORENROLL_SYSTEM+'/feedback/create', params)
                .then(function (response) {
                    if (response.data.status == 'success') {
                        console.log(response.data.status);
                        app.$router.push({ path: 'submit-message', query: { owner: app.owner } });
                    }
                })
                .catch(function (error) {
                    if (error.response && error.response.data.status == 'error') {
                        error.response.data.data.forEach(function(item) {
                            app.formErrorFlag = true;
                            app.formErrors[item.target_element+'_message'] = item.error_message;
                        });
                    }
                })
                .then(function () {
                    app.isProcessing = false;
                });
        },
    }
}
</script>

<style src="../../assets/css/review_rating/review_content.css" scoped>

</style>
