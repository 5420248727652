var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "initial_page"
  }, [_c('div', {
    staticClass: "main_page"
  }, [_c('div', {
    staticClass: "welcome"
  }, [_c('h1', [_vm._v("Welcome")]), _c('h2', [_vm._v("To")]), _c('h2', [_vm._v("Member Dashboard")]), _c('p', [_vm._v("Experience the ease of access in one place.")]), _c('span', {
    staticClass: "d-block text-white mt-3"
  }, [_vm._v("Having Problems ? "), _c('router-link', {
    staticClass: "text-white",
    attrs: {
      "to": "/report-an-issue"
    }
  }, [_vm._v("Submit An Issue")])], 1)]), _c('div', {
    staticClass: "form"
  }, [_c('div', {
    staticClass: "top-button"
  }, [_c('button', {
    staticClass: "login_active"
  }, [_vm._v("Log In")]), _c('router-link', {
    attrs: {
      "to": {
        name: 'SignUp'
      }
    }
  }, [_c('button', {
    staticClass: "sign_up_inactive"
  }, [_vm._v("Register")])])], 1), _c('div', {
    staticClass: "login_form"
  }, [_c('h3', [_vm._v("Log In")]), _c('b-form-group', {
    attrs: {
      "label": "Username",
      "label-for": "input-1"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "input-1",
      "type": "text",
      "required": "",
      "placeholder": "Enter your email"
    },
    model: {
      value: _vm.input.username,
      callback: function ($$v) {
        _vm.$set(_vm.input, "username", $$v);
      },
      expression: "input.username"
    }
  }), _vm.formErrorFlag == true ? _c('div', {
    staticClass: "error_message"
  }, [_vm._v(" " + _vm._s(_vm.formErrors["email"]) + " ")]) : _vm._e()], 1), _c('b-form-group', {
    attrs: {
      "id": "input-group-2",
      "label": "Password",
      "label-for": "input-2"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "input-2",
      "type": "text",
      "required": "",
      "placeholder": "Enter your password"
    },
    model: {
      value: _vm.input.password,
      callback: function ($$v) {
        _vm.$set(_vm.input, "password", $$v);
      },
      expression: "input.password"
    }
  }), _c('eye-button', {
    attrs: {
      "id": "input-2",
      "hideOnDefault": "true",
      "type": "text"
    }
  }), _vm.formErrorFlag == true ? _c('div', {
    staticClass: "error_message"
  }, [_vm._v(" " + _vm._s(_vm.formErrors["password"]) + " ")]) : _vm._e()], 1), _c('a', {}, [_c('button', {
    attrs: {
      "type": "submit",
      "disabled": _vm.loginLoader
    },
    on: {
      "click": function ($event) {
        return _vm.login();
      }
    }
  }, [_vm.loginLoader == true ? _c('b-spinner', {
    attrs: {
      "small": ""
    }
  }) : _vm._e(), _vm._v(" Log In ")], 1)]), _vm.formErrorFlag == true ? _c('div', {
    staticClass: "error_message"
  }, [_vm._v(" " + _vm._s(_vm.formErrors["error"]) + " ")]) : _vm._e(), _c('router-link', {
    attrs: {
      "to": {
        name: 'ForgotPassword'
      }
    }
  }, [_c('p', {
    staticClass: "float-left"
  }, [_vm._v("Forgot Password?")])]), _c('router-link', {
    attrs: {
      "to": {
        name: 'ForgotEmail'
      }
    }
  }, [_c('p', {
    staticClass: "float-right"
  }, [_vm._v("Forgot Email?")])])], 1)])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }