var render = function render(){
  var _this = this;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "resource_new dashboard-card dashboard-card-lt mt-4"
  }, [_c('div', {
    staticClass: "dashboard-title-info d-flex justify-content-between align-items-center"
  }, [_c('h3', [_vm._v("Resources")]), _c('button', {
    staticClass: "add-new",
    on: {
      "click": function () {
        _this.$router.push('/member-add-request');
      }
    }
  }, [_vm._v("Request for a card")])]), _vm.skeletonLoader ? _c('b-skeleton-table', {
    attrs: {
      "rows": 4,
      "columns": 3,
      "table-props": {
        bordered: true,
        striped: true
      }
    }
  }) : _vm._e(), !_vm.skeletonLoader ? _c('div', {
    staticClass: "resources-list-div"
  }, [_c('div', {
    staticClass: "resources-list-div-inner"
  }, _vm._l(_vm.resources.files, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "resources-list"
    }, [_c('div', {
      staticClass: "icon_item",
      on: {
        "click": function ($event) {
          return _vm.downloadFile(item.url);
        }
      }
    }, [_c('div', {
      staticClass: "icon"
    }, [_c('svg', {
      staticStyle: {
        "enable-background": "new 0 0 482.14 482.14"
      },
      attrs: {
        "version": "1.1",
        "id": "Capa_1",
        "xmlns": "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
        "x": "0px",
        "y": "0px",
        "viewBox": "0 0 482.14 482.14",
        "xml:space": "preserve"
      }
    }, [_c('g', [_c('path', {
      attrs: {
        "d": "M142.024,310.194c0-8.007-5.556-12.782-15.359-12.782c-4.003,0-6.714,0.395-8.132,0.773v25.69\n                c1.679,0.378,3.743,0.504,6.588,0.504C135.57,324.379,142.024,319.1,142.024,310.194z"
      }
    }), _c('path', {
      attrs: {
        "d": "M202.709,297.681c-4.39,0-7.227,0.379-8.905,0.772v56.896c1.679,0.394,4.39,0.394,6.841,0.394\n                c17.809,0.126,29.424-9.677,29.424-30.449C230.195,307.231,219.611,297.681,202.709,297.681z"
      }
    }), _c('path', {
      attrs: {
        "d": "M315.458,0H121.811c-28.29,0-51.315,23.041-51.315,51.315v189.754h-5.012c-11.418,0-20.678,9.251-20.678,20.679v125.404\n                c0,11.427,9.259,20.677,20.678,20.677h5.012v22.995c0,28.305,23.025,51.315,51.315,51.315h264.223\n                c28.272,0,51.3-23.011,51.3-51.315V121.449L315.458,0z M99.053,284.379c6.06-1.024,14.578-1.796,26.579-1.796\n                c12.128,0,20.772,2.315,26.58,6.965c5.548,4.382,9.292,11.615,9.292,20.127c0,8.51-2.837,15.745-7.999,20.646\n                c-6.714,6.32-16.643,9.157-28.258,9.157c-2.585,0-4.902-0.128-6.714-0.379v31.096H99.053V284.379z M386.034,450.713H121.811\n                c-10.954,0-19.874-8.92-19.874-19.889v-22.995h246.31c11.42,0,20.679-9.25,20.679-20.677V261.748\n                c0-11.428-9.259-20.679-20.679-20.679h-246.31V51.315c0-10.938,8.921-19.858,19.874-19.858l181.89-0.19v67.233\n                c0,19.638,15.934,35.587,35.587,35.587l65.862-0.189l0.741,296.925C405.891,441.793,396.987,450.713,386.034,450.713z\n                M174.065,369.801v-85.422c7.225-1.15,16.642-1.796,26.58-1.796c16.516,0,27.226,2.963,35.618,9.282\n                c9.031,6.714,14.704,17.416,14.704,32.781c0,16.643-6.06,28.133-14.453,35.224c-9.157,7.612-23.096,11.222-40.125,11.222\n                C186.191,371.092,178.966,370.446,174.065,369.801z M314.892,319.226v15.996h-31.23v34.973h-19.74v-86.966h53.16v16.122h-33.42\n                v19.875H314.892z"
      }
    })]), _c('g'), _c('g'), _c('g'), _c('g'), _c('g'), _c('g'), _c('g'), _c('g'), _c('g'), _c('g'), _c('g'), _c('g'), _c('g'), _c('g'), _c('g')])]), _c('div', {
      staticClass: "resource_item"
    }, [_c('p', [_vm._v(_vm._s(item.filename))]), _c('small', [_c('span'), _vm._v(" " + _vm._s(item.header))])])]), _c('div', {
      staticClass: "test",
      staticStyle: {
        "cursor": "pointer"
      },
      on: {
        "click": function ($event) {
          return _vm.downloadFile(item.url);
        }
      }
    }, [_c('i', {
      staticClass: "fas fa-download"
    })])]);
  }), 0)]) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }