var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    ref: "save",
    staticClass: "content_wrapper"
  }, [_c('agent-info'), _c('div', {
    staticClass: "contract_content"
  }, [_c('contract-form-sidebar'), _c('div', {
    staticClass: "right_container"
  }, [_c('h3', [_vm._v("Confirmation & Signature")]), _c('div', {
    staticClass: "description agreement"
  }, [_c('b-card', {
    staticClass: "mb-1",
    attrs: {
      "no-body": ""
    }
  }, [_c('b-card-header', {
    attrs: {
      "header-tag": "header",
      "role": "tab"
    }
  }, [_c('b-button', {
    directives: [{
      name: "b-toggle",
      rawName: "v-b-toggle.accordion-1",
      modifiers: {
        "accordion-1": true
      }
    }],
    staticStyle: {
      "text-align": "left",
      "font-size": "18px"
    },
    attrs: {
      "block": "",
      "variant": "info"
    }
  }, [_vm._v("Fee Schedule [Level " + _vm._s(_vm.level) + "] "), _c('small', [_vm._v("The following fee schedule is effective January 1,2020")]), _vm.isVisible ? _c('strong', {
    staticClass: "ml-auto",
    staticStyle: {
      "position": "absolute",
      "right": "20px"
    },
    attrs: {
      "aria-hidden": "true"
    }
  }, [_vm._v("-")]) : _c('strong', {
    staticClass: "ml-auto",
    staticStyle: {
      "position": "absolute",
      "right": "20px"
    },
    attrs: {
      "aria-hidden": "true"
    }
  }, [_vm._v("+")])])], 1), _c('b-collapse', {
    attrs: {
      "id": "accordion-1",
      "accordion": "my-accordion",
      "role": "tabpanel"
    },
    model: {
      value: _vm.isVisible,
      callback: function ($$v) {
        _vm.isVisible = $$v;
      },
      expression: "isVisible"
    }
  }, [_c('b-card-body', [_c('b-card-text', [_c('div', {
    staticClass: "fee-schedule"
  }, [_c('table', {
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "id": "fee-schedule"
    }
  }, [!(_vm.contract_type === 'categoryL7' || _vm.contract_type === 'categoryL9' || _vm.contract_type === 'carrier') ? _c('tr', [_c('th', [_vm._v("Association Membership Fees ")]), _c('th', [_c('b', [_c('p', [_vm._v("Monthly Commission Level*")])]), _c('p', [_vm._v("(Paid Monthly and in arrears) ")])])]) : _vm._e(), _vm._l(_vm.items, function (value, index) {
    return _c('tr', {
      key: index
    }, [_c('td', [_c('strong', [_vm._v(_vm._s(value.plan_name || _vm.capitalizeFirstLetter(value.configuration_name)))])]), value.configuration && value.configuration.hasOwnProperty('Lifeline Bronze') && value.configuration.hasOwnProperty('Lifeline Silver, Gold & Platinum') ? _c('td', [_c('table', {
      staticStyle: {
        "width": "100%"
      }
    }, [_c('thead', _vm._l(value.configuration, function (configValue, configKey) {
      return _c('tr', {
        key: 'header-' + configKey
      }, [_c('th', [_c('strong', [_vm._v(_vm._s(configKey))])]), _c('th', [_c('table', {
        staticStyle: {
          "width": "100%"
        }
      }, [_c('thead', _vm._l(configValue, function (configVal, configKey, index) {
        return _c('tr', {
          key: 'tier-header-' + configKey
        }, [index === 0 ? [_c('th', [_c('strong', [_vm._v("Tier")])]), _vm._l(configVal, function (tierVall, tierrKey) {
          return _c('th', {
            key: 'tier-header-' + tierrKey
          }, [_c('strong', [_vm._v(" " + _vm._s(tierrKey) + " ")])]);
        })] : _vm._e()], 2);
      }), 0), _c('tbody', _vm._l(configValue, function (configVal, configKey) {
        return _c('tr', {
          key: 'data-' + configKey
        }, [_c('td', [_c('strong', [_vm._v(_vm._s(configKey))])]), _vm._l(configVal, function (conval, conKey) {
          return _c('td', {
            key: 'data-' + conKey
          }, [_c('strong', [_vm._v(_vm._s(conval))])]);
        })], 2);
      }), 0)])])]);
    }), 0)])]) : value.configuration ? _c('td', [_c('table', {
      staticStyle: {
        "width": "100%"
      }
    }, [_c('thead', [_c('tr', _vm._l(value.configuration, function (configValue, configKey) {
      return _c('th', {
        key: 'header-' + configKey
      }, [_c('strong', [_vm._v(_vm._s(configKey))])]);
    }), 0)]), _c('tbody', [_c('tr', _vm._l(value.configuration, function (configValue, configKey) {
      return _c('td', {
        key: 'data-' + configKey
      }, [configValue == '0.0%' ? _c('strong', [_vm._v(_vm._s('Paid By Up-Line'))]) : _c('strong', [_vm._v(_vm._s(configValue))])]);
    }), 0)])])]) : _c('td', [value.commission == '0.00' ? _c('strong', [_vm._v(_vm._s('Paid By Up-Line'))]) : value.commission == '' || value.commission == null ? _c('strong', [_vm._v(_vm._s('N/A'))]) : _c('strong', [_vm._v(_vm._s(value.commission))])])]);
  })], 2)])])], 1)], 1)], 1), _c('b-card', {
    staticClass: "mb-1",
    attrs: {
      "no-body": ""
    }
  }, [_c('b-card-header', {
    attrs: {
      "header-tag": "header",
      "role": "tab"
    }
  }, [_c('b-button', {
    directives: [{
      name: "b-toggle",
      rawName: "v-b-toggle.accordion-2",
      modifiers: {
        "accordion-2": true
      }
    }],
    staticStyle: {
      "text-align": "left",
      "font-size": "18px"
    },
    attrs: {
      "block": "",
      "variant": "info"
    }
  }, [_vm._v("Signature "), _vm.isNotVisible ? _c('strong', {
    staticClass: "ml-auto",
    staticStyle: {
      "position": "absolute",
      "right": "20px"
    },
    attrs: {
      "aria-hidden": "true"
    }
  }, [_vm._v("-")]) : _c('strong', {
    staticClass: "ml-auto",
    staticStyle: {
      "position": "absolute",
      "right": "20px"
    },
    attrs: {
      "aria-hidden": "true"
    }
  }, [_vm._v("+")])])], 1), _c('b-collapse', {
    attrs: {
      "id": "accordion-2",
      "visible": "",
      "accordion": "my-accordion",
      "role": "tabpanel"
    },
    model: {
      value: _vm.isNotVisible,
      callback: function ($$v) {
        _vm.isNotVisible = $$v;
      },
      expression: "isNotVisible"
    }
  }, [_c('b-card-body', [_c('b-card-text', [_c('div', {
    staticClass: "agreement_signature"
  }, [_c('span', {
    staticClass: "sign_container"
  }, [_c('span', {
    staticClass: "signature_block"
  }, [_c('p', {
    staticClass: "left"
  }, [_c('span', {
    staticClass: "sign"
  }, [_c('p', [_vm._v("Signature of Independent Marketer")]), _c('canvas', {
    staticClass: "signature",
    attrs: {
      "id": "rep_signature"
    }
  }), _c('br'), _vm.formErrorFlag == true ? _c('small', {
    staticClass: "error_message"
  }, [_vm._v(_vm._s(_vm.formErrors['fee_signature_message']))]) : _vm._e(), _c('button', {
    staticClass: "clear",
    attrs: {
      "type": "button",
      "name": "button"
    },
    on: {
      "click": _vm.clear
    }
  }, [_vm._v("Clear")])]), _c('br'), _c('span', [_c('p', {
    staticClass: "print-Name"
  }, [_vm._v("Print Name")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formFields.rep_name.value,
      expression: "formFields.rep_name.value "
    }],
    staticClass: "mt-3",
    attrs: {
      "type": "text",
      "name": "rep_name",
      "value": "",
      "readonly": _vm.formFields.rep_name.read_only
    },
    domProps: {
      "value": _vm.formFields.rep_name.value
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.formFields.rep_name, "value", $event.target.value);
      }
    }
  })]), _c('br'), _vm.formErrorFlag == true ? _c('small', {
    staticClass: "error_message"
  }, [_vm._v(_vm._s(_vm.formErrors['fee_name_message']))]) : _vm._e(), _c('br'), _c('br'), _c('span', [_c('p', [_vm._v("Date Signed")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formFields.date_signed1.value,
      expression: "formFields.date_signed1.value"
    }],
    attrs: {
      "type": "text",
      "name": "date_signed",
      "readonly": _vm.formFields.date_signed1.read_only
    },
    domProps: {
      "value": _vm.formFields.date_signed1.value
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.formFields.date_signed1, "value", $event.target.value);
      }
    }
  })]), _c('br'), _vm.formErrorFlag == true ? _c('small', {
    staticClass: "error_message"
  }, [_vm._v(_vm._s(_vm.formErrors['fee_date_message']))]) : _vm._e()]), _c('p', {
    staticClass: "right"
  }, [_c('span', {
    staticClass: "sign"
  }, [_c('p', [_vm._v(" EWA Membership")]), _c('canvas', {
    staticClass: "signature",
    attrs: {
      "id": "signature"
    }
  })]), _c('br'), _c('span', [_c('p', {
    staticClass: "membership-seciton"
  }, [_vm._v("Print Name and Title")]), _c('br'), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formFields.officer_name.value,
      expression: "formFields.officer_name.value"
    }],
    attrs: {
      "type": "text",
      "name": "officer_name",
      "readonly": _vm.formFields.officer_name.read_only
    },
    domProps: {
      "value": _vm.formFields.officer_name.value
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.formFields.officer_name, "value", $event.target.value);
      }
    }
  })]), _c('br'), _c('br'), _c('span', [_c('p', [_vm._v("Date Signed")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formFields.date_signed1.value,
      expression: "formFields.date_signed1.value"
    }],
    attrs: {
      "type": "text",
      "name": "date_signed1",
      "readonly": _vm.formFields.date_signed1.read_only
    },
    domProps: {
      "value": _vm.formFields.date_signed1.value
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.formFields.date_signed1, "value", $event.target.value);
      }
    }
  })])])])])])])], 1)], 1)], 1)], 1), _c('div', {
    staticClass: "button_container"
  }, [_c('button', {
    staticClass: "back",
    attrs: {
      "type": "button",
      "name": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.navigatePrevious();
      }
    }
  }, [_vm._v("Back")]), _c('div', {
    staticClass: "button"
  }, [_c('button', {
    staticClass: "save",
    on: {
      "click": function ($event) {
        return _vm.saveFeeStructure();
      }
    }
  }, [_vm._v("Save")])])])])], 1)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }