/* eslint-disable */

import axios from "axios";
import router from "../router/index";
import store from "../store/store";
import Vue from "vue";
import Api from "../resource/Api";
import Endpoint from "../resource/EndPoints/Endppoint.vue";

const otherUrl = axios.create({
  baseURL: "",
});

export default class Validator {
  static async validateRoutingNumber(number) {
    let data = await otherUrl.get(`${Endpoint.VALIDATE_ROUTING_NUMBER}/routing-number-bank-lookup?q=` + number);
    if (data.status == "200") {
      return Validator.messageGenerate(true, "success", data.data.data.name);
    }
    return Validator.messageGenerate(false, "Invalid routing number");
  }

  static async validatePhoneNumber(number) {
    return await otherUrl
      .get(Endpoint.VALIDATE_PHONE_NUMBER + number)
      .then((response) => {
        return Validator.messageGenerate(
          true,
          response.data.message,
          response.data.data.result.line_type
        );
      })
      .catch((error) => {

        return Validator.messageGenerate(
          false,
          error.response.data.data[0].error_message
        );
      });
  }

  static async validateSSN(number) {
    return await otherUrl
      .post(Endpoint.VALIDATE_SSN, { ssn: number })
      .then((response) => {
        return Validator.messageGenerate(true, "success", "Valid SSN");
      })
      .catch((error) => {
        return Validator.messageGenerate(false, "Invalid SSN");
      });
  }

  static async validateDate(date) {
    try {
      let dateformat = /^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}$/;
      if (date.match(dateformat)[0] == date) {
        return Validator.messageGenerate(true, "success", "Valid SSN");
      }
      throw "error";
    } catch {
      return Validator.messageGenerate(false, "Invalid Date");
    }
  }

  static messageGenerate(status, message, data = "") {
    return { status: status, message: message, data: data };
  }

  static async validateEmail(email) {
    return await otherUrl
    .post(Endpoint.VALIDATE_EMAIL, { email: email })
    .then((response) => {
      return true;
    })
    .catch((error) => {
      return false;
    });
  }
}
