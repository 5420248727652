var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "side-menu-wrap"
  }, [_c('div', {
    staticClass: "side-menu"
  }, [_c('b-nav', {
    attrs: {
      "vertical": ""
    }
  }, [_c('b-nav-item', {
    staticClass: "app-nav-link",
    class: {
      active: _vm.$route.name === 'DashboardNew'
    },
    attrs: {
      "to": {
        name: 'DashboardNew'
      }
    }
  }, [_c('svg', {
    staticStyle: {
      "enable-background": "new 0 0 606.877 606.877"
    },
    attrs: {
      "version": "1.1",
      "id": "Capa_1",
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlns:xlink": "http://www.w3.org/1999/xlink",
      "x": "0px",
      "y": "0px",
      "fill": "currentColor",
      "width": "20px",
      "height": "20px",
      "viewBox": "0 0 606.877 606.877",
      "xml:space": "preserve"
    }
  }, [_c('g', [_c('g', [_c('g', [_c('path', {
    attrs: {
      "d": "M58.64,280.154h162.654c32.058,0,58.14-26.082,58.14-58.14V59.36c0-32.059-26.082-58.14-58.14-58.14H58.64\n                        C26.582,1.22,0.5,27.301,0.5,59.36v162.654C0.5,254.072,26.582,280.154,58.64,280.154z M43.34,59.36c0-8.45,6.85-15.3,15.3-15.3\n                        h162.654c8.45,0,15.3,6.85,15.3,15.3v162.654c0,8.45-6.85,15.3-15.3,15.3H58.64c-8.45,0-15.3-6.85-15.3-15.3V59.36z"
    }
  }), _c('path', {
    attrs: {
      "d": "M221.294,280.654H58.64c-32.334,0-58.64-26.306-58.64-58.64V59.36C0,27.025,26.306,0.72,58.64,0.72h162.654\n                        c32.334,0,58.64,26.306,58.64,58.64v162.654C279.934,254.348,253.628,280.654,221.294,280.654z M58.64,1.72\n                        C26.857,1.72,1,27.577,1,59.36v162.654c0,31.783,25.857,57.64,57.64,57.64h162.654c31.783,0,57.64-25.857,57.64-57.64V59.36\n                        c0-31.783-25.857-57.64-57.64-57.64H58.64z M221.294,237.813H58.64c-8.712,0-15.8-7.088-15.8-15.8V59.36\n                        c0-8.712,7.088-15.8,15.8-15.8h162.654c8.712,0,15.8,7.088,15.8,15.8v162.654C237.094,230.726,230.006,237.813,221.294,237.813z\n                        M58.64,44.56c-8.161,0-14.8,6.639-14.8,14.8v162.654c0,8.161,6.639,14.8,14.8,14.8h162.654c8.161,0,14.8-6.639,14.8-14.8V59.36\n                        c0-8.161-6.639-14.8-14.8-14.8H58.64z"
    }
  })]), _c('g', [_c('path', {
    attrs: {
      "d": "M548.238,1.22H385.584c-32.059,0-58.141,26.082-58.141,58.14v162.654c0,32.058,26.082,58.14,58.141,58.14h162.654\n                        c32.059,0,58.139-26.082,58.139-58.14V59.36C606.377,27.301,580.297,1.22,548.238,1.22z M563.537,222.014\n                        c0,8.45-6.85,15.3-15.299,15.3H385.584c-8.449,0-15.301-6.85-15.301-15.3V59.36c0-8.45,6.852-15.3,15.301-15.3h162.654\n                        c8.449,0,15.299,6.85,15.299,15.3V222.014z"
    }
  }), _c('path', {
    attrs: {
      "d": "M548.238,280.654H385.584c-32.335,0-58.641-26.306-58.641-58.64V59.36c0-32.334,26.306-58.64,58.641-58.64h162.654\n                        c32.333,0,58.639,26.306,58.639,58.64v162.654C606.877,254.348,580.571,280.654,548.238,280.654z M385.584,1.72\n                        c-31.783,0-57.641,25.857-57.641,57.64v162.654c0,31.783,25.857,57.64,57.641,57.64h162.654c31.782,0,57.639-25.857,57.639-57.64\n                        V59.36c0-31.783-25.856-57.64-57.639-57.64H385.584z M548.238,237.813H385.584c-8.713,0-15.801-7.088-15.801-15.8V59.36\n                        c0-8.712,7.088-15.8,15.801-15.8h162.654c8.712,0,15.799,7.088,15.799,15.8v162.654\n                        C564.037,230.726,556.95,237.813,548.238,237.813z M385.584,44.56c-8.161,0-14.801,6.639-14.801,14.8v162.654\n                        c0,8.161,6.64,14.8,14.801,14.8h162.654c8.16,0,14.799-6.639,14.799-14.8V59.36c0-8.161-6.639-14.8-14.799-14.8H385.584z"
    }
  })]), _c('g', [_c('path', {
    attrs: {
      "d": "M58.64,605.657h162.654c32.058,0,58.14-26.08,58.14-58.139V384.864c0-32.059-26.082-58.141-58.14-58.141H58.64\n                        c-32.058,0-58.14,26.082-58.14,58.141v162.654C0.5,579.577,26.582,605.657,58.64,605.657z M43.34,384.864\n                        c0-8.449,6.85-15.301,15.3-15.301h162.654c8.45,0,15.3,6.852,15.3,15.301v162.654c0,8.449-6.85,15.299-15.3,15.299H58.64\n                        c-8.45,0-15.3-6.85-15.3-15.299V384.864z"
    }
  }), _c('path', {
    attrs: {
      "d": "M221.294,606.157H58.64C26.306,606.157,0,579.852,0,547.519V384.864c0-32.335,26.306-58.641,58.64-58.641h162.654\n                        c32.334,0,58.64,26.306,58.64,58.641v162.654C279.934,579.852,253.628,606.157,221.294,606.157z M58.64,327.224\n                        C26.857,327.224,1,353.081,1,384.864v162.654c0,31.782,25.857,57.639,57.64,57.639h162.654c31.783,0,57.64-25.856,57.64-57.639\n                        V384.864c0-31.783-25.857-57.641-57.64-57.641H58.64z M221.294,563.317H58.64c-8.712,0-15.8-7.087-15.8-15.799V384.864\n                        c0-8.713,7.088-15.801,15.8-15.801h162.654c8.712,0,15.8,7.088,15.8,15.801v162.654\n                        C237.094,556.23,230.006,563.317,221.294,563.317z M58.64,370.063c-8.161,0-14.8,6.64-14.8,14.801v162.654\n                        c0,8.16,6.639,14.799,14.8,14.799h162.654c8.161,0,14.8-6.639,14.8-14.799V384.864c0-8.161-6.639-14.801-14.8-14.801H58.64z"
    }
  })]), _c('g', [_c('path', {
    attrs: {
      "d": "M548.238,326.724H385.584c-32.059,0-58.141,26.082-58.141,58.141v162.654c0,32.059,26.082,58.139,58.141,58.139h162.654\n                        c32.059,0,58.139-26.08,58.139-58.139V384.864C606.377,352.806,580.297,326.724,548.238,326.724z M563.537,547.519\n                        c0,8.449-6.85,15.299-15.299,15.299H385.584c-8.449,0-15.301-6.85-15.301-15.299V384.864c0-8.449,6.852-15.301,15.301-15.301\n                        h162.654c8.449,0,15.299,6.852,15.299,15.301V547.519z"
    }
  }), _c('path', {
    attrs: {
      "d": "M548.238,606.157H385.584c-32.335,0-58.641-26.306-58.641-58.639V384.864c0-32.335,26.306-58.641,58.641-58.641h162.654\n                        c32.333,0,58.639,26.306,58.639,58.641v162.654C606.877,579.852,580.571,606.157,548.238,606.157z M385.584,327.224\n                        c-31.783,0-57.641,25.857-57.641,57.641v162.654c0,31.782,25.857,57.639,57.641,57.639h162.654\n                        c31.782,0,57.639-25.856,57.639-57.639V384.864c0-31.783-25.856-57.641-57.639-57.641H385.584z M548.238,563.317H385.584\n                        c-8.713,0-15.801-7.087-15.801-15.799V384.864c0-8.713,7.088-15.801,15.801-15.801h162.654c8.712,0,15.799,7.088,15.799,15.801\n                        v162.654C564.037,556.23,556.95,563.317,548.238,563.317z M385.584,370.063c-8.161,0-14.801,6.64-14.801,14.801v162.654\n                        c0,8.16,6.64,14.799,14.801,14.799h162.654c8.16,0,14.799-6.639,14.799-14.799V384.864c0-8.161-6.639-14.801-14.799-14.801\n                        H385.584z"
    }
  })])])]), _c('g'), _c('g'), _c('g'), _c('g'), _c('g'), _c('g'), _c('g'), _c('g'), _c('g'), _c('g'), _c('g'), _c('g'), _c('g'), _c('g'), _c('g')]), _c('span', [_vm._v("Home")])]), _c('b-nav-item', {
    attrs: {
      "to": "/view-message"
    }
  }, [_c('svg', {
    attrs: {
      "height": "20px",
      "fill": "currentColor",
      "viewBox": "0 0 512 512.0002",
      "width": "20px",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "m256 0c-141.484375 0-256 114.496094-256 256 0 44.902344 11.710938 88.757812 33.949219 127.4375l-32.984375 102.429688c-2.300782 7.140624-.410156 14.96875 4.894531 20.273437 5.253906 5.253906 13.0625 7.214844 20.273437 4.894531l102.429688-32.984375c38.679688 22.238281 82.535156 33.949219 127.4375 33.949219 141.484375 0 256-114.496094 256-256 0-141.484375-114.496094-256-256-256zm0 472c-40.558594 0-80.09375-11.316406-114.332031-32.726562-4.925781-3.078126-11.042969-3.910157-16.734375-2.078126l-73.941406 23.8125 23.8125-73.941406c1.804687-5.609375 1.042968-11.734375-2.082032-16.734375-21.40625-34.238281-32.722656-73.773437-32.722656-114.332031 0-119.101562 96.898438-216 216-216s216 96.898438 216 216-96.898438 216-216 216zm25-216c0 13.804688-11.191406 25-25 25s-25-11.195312-25-25c0-13.808594 11.191406-25 25-25s25 11.191406 25 25zm100 0c0 13.804688-11.191406 25-25 25s-25-11.195312-25-25c0-13.808594 11.191406-25 25-25s25 11.191406 25 25zm-200 0c0 13.804688-11.191406 25-25 25-13.804688 0-25-11.195312-25-25 0-13.808594 11.195312-25 25-25 13.808594 0 25 11.191406 25 25zm0 0"
    }
  })]), _c('span', [_vm._v("Message")])]), _c('b-nav-item', {
    attrs: {
      "to": "/view-Knowledge-feeds"
    }
  }, [_c('svg', {
    staticClass: "svg-inline--fa fa-layer-group fa-w-16",
    attrs: {
      "aria-hidden": "true",
      "focusable": "false",
      "fill": "currentColor",
      "width": "20px",
      "data-prefix": "fas",
      "data-icon": "layer-group",
      "role": "img",
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 512 512"
    }
  }, [_c('path', {
    attrs: {
      "fill": "currentColor",
      "d": "M12.41 148.02l232.94 105.67c6.8 3.09 14.49 3.09 21.29 0l232.94-105.67c16.55-7.51 16.55-32.52 0-40.03L266.65 2.31a25.607 25.607 0 0 0-21.29 0L12.41 107.98c-16.55 7.51-16.55 32.53 0 40.04zm487.18 88.28l-58.09-26.33-161.64 73.27c-7.56 3.43-15.59 5.17-23.86 5.17s-16.29-1.74-23.86-5.17L70.51 209.97l-58.1 26.33c-16.55 7.5-16.55 32.5 0 40l232.94 105.59c6.8 3.08 14.49 3.08 21.29 0L499.59 276.3c16.55-7.5 16.55-32.5 0-40zm0 127.8l-57.87-26.23-161.86 73.37c-7.56 3.43-15.59 5.17-23.86 5.17s-16.29-1.74-23.86-5.17L70.29 337.87 12.41 364.1c-16.55 7.5-16.55 32.5 0 40l232.94 105.59c6.8 3.08 14.49 3.08 21.29 0L499.59 404.1c16.55-7.5 16.55-32.5 0-40z"
    }
  })]), _c('span', [_vm._v("Knowledge Center")])]), _c('b-nav-item', {
    attrs: {
      "to": "/tickets"
    }
  }, [_c('i', {
    staticClass: "fa fa-ticket-alt"
  }), _c('span', [_vm._v("Tickets")])]), _c('b-nav-item', {
    attrs: {
      "to": "/provider-list"
    }
  }, [_c('i', {
    staticClass: "fas fa-list"
  }), _c('span', [_vm._v("Provider List")])]), _c('b-nav-item', {
    staticClass: "benefit-store-side-menu mt-0",
    attrs: {
      "href": _vm.benefitStoreUrl,
      "target": "_blank"
    }
  }, [_c('img', {
    staticClass: "benefit-store-img blog-icon-sidebar",
    attrs: {
      "src": require("@/assets/images/benefit.svg")
    }
  }), _c('div', {
    staticClass: "ml-1 nuera-blog-align neura-blog-sidebar-title"
  }, [_vm._v("Benefit Store")])]), _c('b-nav-item', {
    staticClass: "mt-0",
    class: [_vm.getRoutePath === '/iha-blogs' ? 'neura-blogs-active' : 'neura-blogs-btn'],
    attrs: {
      "to": "/iha-blogs"
    }
  }, [_c('img', {
    staticClass: "blog-icon blog-icon-sidebar",
    attrs: {
      "src": require("@/assets/images/blog.svg")
    }
  }), _c('div', {
    staticClass: "ml-1 nuera-blog-align"
  }, [_vm._v(_vm._s(_vm.site_name) + " Blogs")])]), _c('b-nav-item', {
    staticClass: "side-menu-logout",
    attrs: {
      "href": "javascript:void(0);"
    },
    on: {
      "click": function ($event) {
        return _vm.logout();
      }
    }
  }, [_c('svg', {
    staticClass: "svg-inline--fa fa-sign-out-alt fa-w-16",
    attrs: {
      "aria-hidden": "true",
      "focusable": "false",
      "height": "20px",
      "fill": "currentColor",
      "data-prefix": "fas",
      "data-icon": "sign-out-alt",
      "role": "img",
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 512 512"
    }
  }, [_c('path', {
    attrs: {
      "fill": "currentColor",
      "d": "M497 273L329 441c-15 15-41 4.5-41-17v-96H152c-13.3 0-24-10.7-24-24v-96c0-13.3 10.7-24 24-24h136V88c0-21.4 25.9-32 41-17l168 168c9.3 9.4 9.3 24.6 0 34zM192 436v-40c0-6.6-5.4-12-12-12H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h84c6.6 0 12-5.4 12-12V76c0-6.6-5.4-12-12-12H96c-53 0-96 43-96 96v192c0 53 43 96 96 96h84c6.6 0 12-5.4 12-12z"
    }
  })]), _c('span', [_vm._v("Logout")])])], 1)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }