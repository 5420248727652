<template>
  <b-modal id="app-download-modal" no-close-on-backdrop v-model="show">
    <div class="logo">
      <a href="javascript:;">
        <img src="@/assets/images/corenroll-logo.png" alt="corenroll-logo"
      /></a>
    </div>
    <div class="title-text">
      <h1>
        <strong>For Better <br />Experience <br />Download App</strong>
      </h1>
    </div>
    <div class="row my-2">
      <div class="col-12">
        <div class="motive">
          <p>
            Experience the ease of managing your all information in one place.
          </p>
        </div>
        <div class="w-100">
          <button
            type="button"
            class="univ-btn mw-100 btn-block download-app-btn"
            @click="redirectToDownload"
          >
            Download App
          </button>
        </div>
        <div class="w-100">
          <button
            type="button"
            class="univ-btn mw-100 web-btn btn-block"
            @click="enableWebVersion()"
          >
            Continue with Web Version
          </button>
        </div>
      </div>
    </div>
  </b-modal>
</template>
<script>
import moment from "moment";
export default {
  props: {
    appUrl: {
      required: false,
    },
  },
  data: () => {
    return {
      show: false,
      variants: ["close-btn"],
    };
  },
  methods: {
    enableWebVersion() {
      localStorage.use_web_status = true;
      localStorage.status_expiry = moment(new Date())
        .add(24, "hours")
        .format("YYYY MM DD hh:mm:ss a");
      this.$bvModal.hide("app-download-modal");
    },
    redirectToDownload() {
      this.enableWebVersion();
      window.open(this.appUrl, "_blank");
      this.$bvModal.hide("app-download-modal");
    },
  },
  watch: {
    show(isOpen) {
      if (!isOpen) {
        this.enableWebVersion();
      }
    },
  },
};
</script>
<style scoped>
.title-text {
  margin-top: 40px;
  color: #eee;
}

.motive {
  color: #fff;
  margin-top: 1rem;
  margin-bottom: 80px;
}
.invoice-list {
  padding-top: 40px;
}

.univ-btn {
  background: var(--login-bg-color);
  border-radius: 6px;
  color: #fff;
  outline: none;
  border: 0;
  width: 100%;
  text-align: center;
  padding: 10px 0;
  margin-top: 10px;
  margin-bottom: 0;
  transition: all ease 0.6s;
}

.download-app-btn {
  background: #fff;
  border: 1px solid #dadce0;
  color: #35495e;
}
#app-download-modal .close {
  background: transparent;
  border: none;
  font-size: 1.75rem;
  color: #fff;
  position: absolute;
  right: 4px;
  top: 2px;
}
.web-btn {
  background: transparent;
  border: 1px solid #fff;
  color: #fff;
}
#app-download-modal .logo {
  display: inline;
}
</style>
<style>
#app-download-modal .close {
  background: transparent;
  border: none;
  font-size: 1.75rem;
  color: #fff;
  position: absolute;
  right: 12px; /*Initially: 4px*/
  top: 12px; /*Initially: 2pxpx*/
}
#app-download-modal .modal-footer {
  display: none;
}
#app-download-modal .modal-header {
  border: none;
}
#app-download-modal .modal-content {
  border: 0px;
  border-radius: 10px;
  background-image: url(https://i.postimg.cc/4xrHyTbc/modal-bg.png);
  /* background-image: url(https://ibb.co/zrBVGRW); */
  /* background: url(https://imgbb.com/upload); */
  background-size: cover;
}

#app-download-modal .modal-header h5 {
  display: none;
  border: none;
}
#app-download-modal .modal-header .close {
  border-radius: 0;
  box-shadow: none;
}
</style>