import Vue from "vue";
import Loading from "vue-loading-overlay";
import loaderImg from "@/assets/images/loader-iha.png";
import "vue-loading-overlay/dist/vue-loading.css";
import "@/assets/css/loader.css";
const customLoader = Vue.extend({
  render() {
    return (
      <div class="row">
        <div class="loader-logo">
          <img class="img-fluid" src={loaderImg} />
        </div>
      </div>
    );
  },
});

Vue.component("custom-loader", customLoader);
Vue.use(
  Loading,
  {
    zIndex: 9999,
    color: "#0cf",
    loader: "spinner",
    width: 40,
    height: 40,
  },
  {
    default: new Vue().$createElement("custom-loader"),
  }
);
