<template>
  <b-modal
    id="changePassword"
    :centered="true"
    title="Change Password"
    :hide-footer="true"
    size="md"
    no-close-on-backdrop
  >
    <div class="modal-body">
      <div class="form form_change">
        <div class="input_form">
          <b-form-group
            label="Current Password"
            label-for="input_changepassword_1"
          >
            <b-form-input
              id="input_changepassword_1"
              type="text"
              v-model="currentpassword"
              required
              placeholder="Current Password"
            ></b-form-input>
            <eye-button
              id="input_changepassword_1"
              hideOnDefault="true"
              type="text"
            ></eye-button>

            <div class="error_message" v-if="formErrorFlag == true">
              {{ formErrors["password"] }}
            </div>
          </b-form-group>

          <b-form-group label="New Password" label-for="input_changepassword_2">
            <b-form-input
              id="input_changepassword_2"
              type="text"
              required
              v-model="newpassword"
              placeholder="Enter your new password"
            ></b-form-input>
            <eye-button
              id="input_changepassword_2"
              hideOnDefault="true"
              type="text"
            ></eye-button>
          </b-form-group>
          <b-form-group
            label="Confirm Password"
            label-for="input_changepassword_3"
          >
            <b-form-input
              id="input_changepassword_3"
              type="text"
              required
              v-model="confirmpassword"
              placeholder="Repeat new password"
            ></b-form-input>
            <eye-button
              id="input_changepassword_3"
              hideOnDefault="true"
              type="text"
            ></eye-button>
          </b-form-group>

          <a class="">
            <button
              type="submit"
              @click="changePassword()"
              :disabled="loginLoader"
            >
              <b-spinner v-if="loginLoader == true" small></b-spinner>
              Submit
            </button>
          </a>
          <div class="error_message" v-if="formErrorFlag == true">
            {{ formErrors["error"] }}
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer"></div>
  </b-modal>
</template>
<script>
/* eslint-disable */
import axios from "axios";
import helper from "@/resource/Helper";
import EyeButton from "@/components/common/EyeButton";
export default {
  components: {
    EyeButton,
  },
  data: () => ({
    confirmpassword: "",
    newpassword: "",
    currentpassword: "",
    formErrorFlag: false,
    loginLoader: false,
    formErrors: [],
  }),
  methods: {
    changePassword() {
      let app = this;
      app.formErrorFlag = false;
      if (app.confirmpassword == "" || app.newpassword == "") {
        app.formErrorFlag = true;
        app.formErrors["error"] = "Please enter password.";
        return;
      }
      if (app.confirmpassword != app.newpassword) {
        app.formErrorFlag = true;
        app.formErrors["error"] = "Password mismatch!";
        return;
      }
      if (helper.validatePassword(this.newpassword) == false) {
        app.formErrorFlag = true;
        app.formErrors["error"] =
          "Password should be 8 characters long and contain at least 1 uppercase,1 lowercase, number & special character.";
        return;
      }

      app.loginLoader = true;
      axios
        .post("/change-password", {
          old_password: app.currentpassword,
          new_password: app.newpassword,
        })
        .then(function (response) {
          if (response.data.status == "success") {
            helper.infoMessage(
              app,
              response.data.status,
              "Password changed successfully, please login with new password."
            );
            helper.logout();
          }
        })
        .catch(function (error) {
          if (error.response.data.status == "error") {
            app.formErrorFlag = true;
            app.formErrors["error"] = error.response.data.message;
          }
          app.loginLoader = false;
        });
    },
  },
};
</script>
<style >
.input_form button {
  margin-top: 10 px;
  padding: 10 px;
  width: 100%;
  font-size: 18px;
  /* background: #00ccff; */
  background-color: var(--secondary-color);
  border-color: var(--secondary-color);
  color: white;
}
</style>