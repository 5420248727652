import { render, staticRenderFns } from "./Tickets.vue?vue&type=template&id=5b8cb9b1&scoped=true"
import script from "./Tickets.vue?vue&type=script&lang=js"
export * from "./Tickets.vue?vue&type=script&lang=js"
import style0 from "@/assets/css/tickets.css?vue&type=style&index=0&prod&lang=css&external"
import style1 from "./Tickets.vue?vue&type=style&index=1&id=5b8cb9b1&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5b8cb9b1",
  null
  
)

export default component.exports