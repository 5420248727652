var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "page-wrapper document-view-details"
  }, [_c('div', {
    staticClass: "container-fluid"
  }, [_c('div', {
    staticClass: "row body-main-row"
  }, [_c('div', {
    staticClass: "col-md-10 member_dashboard",
    staticStyle: {
      "margin": "0px auto"
    }
  }, [_vm.skeletonLoader ? _c('b-skeleton-table', {
    attrs: {
      "rows": 15,
      "columns": 1,
      "table-props": {
        striped: true
      }
    }
  }) : _vm._e(), !_vm.skeletonLoader ? _c('div', {
    staticClass: "document-view-body",
    staticStyle: {
      "margin-top": "9%"
    }
  }, [_vm.isComplete ? _c('div', {
    staticStyle: {
      "padding": "16%"
    }
  }, [_vm._m(0), _c('router-link', {
    attrs: {
      "to": {
        name: 'Login'
      }
    }
  }, [_c('p', [_c('button', {
    staticClass: "btn btn-primary",
    attrs: {
      "type": "button"
    }
  }, [_vm._v(" Go to Dashboard")])])])], 1) : _vm._e(), !_vm.isComplete ? _c('div', [_c('div', {
    staticClass: "directory-block"
  }, [_c('h2', [_vm._v(_vm._s(_vm.data.username) + " ")])]), _vm._m(1), _c('div', {
    staticClass: "new-rate-details"
  }, [_c('ul', [_c('li', [_vm._v("Plan: "), _c('strong', [_vm._v(_vm._s(_vm.data.plan_name))])]), _c('li', [_vm._v("Tier: "), _c('strong', [_vm._v(_vm._s(_vm.data.tier))])]), _c('li', [_vm._v("New Rate: "), _c('strong', [_vm._v("$" + _vm._s(_vm.data.plan_price))])])])]), _c('div', {
    staticClass: "signature"
  }, [_vm._m(2), _c('signature-pad', {
    ref: "signpad",
    on: {
      "sign": _vm.getSign
    }
  }), _vm.isSignRequired ? _c('div', {
    staticClass: "invalid-feedback",
    staticStyle: {
      "display": "block"
    },
    attrs: {
      "tabindex": "-1",
      "role": "alert",
      "aria-live": "assertive",
      "aria-atomic": "true"
    }
  }, [_vm._v(" Sign is required. ")]) : _vm._e(), _c('div'), _vm._m(3), _c('br'), _c('br'), _c('button', {
    staticClass: "btn btn-primary",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.submitSign();
      }
    }
  }, [_vm.signatureLoader == true ? _c('b-spinner', {
    attrs: {
      "small": ""
    }
  }) : _vm._e(), _vm._v(" Submit Signature")], 1)], 1)]) : _vm._e()]) : _vm._e()], 1)])])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('strong', [_vm._v("Signature submitted successfully.")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('strong', [_vm._v("Your medical plan has been updated, and your new updated Medical Plan is:")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "signature_title"
  }, [_c('p', [_c('b', [_vm._v("Application Signature (Required): ")]), _c('span', [_vm._v("(Sign Name using your mouse or touch screen in the box below)")])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', {
    staticClass: "osf"
  }, [_c('em', [_vm._v("With signature we will get your confirmation for the plan changes.")])]);

}]

export { render, staticRenderFns }