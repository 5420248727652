<template>
  <div class="container-fluid">
    <div class="row body-main-row">
      <div class="dashboard-new">
        <div class="col-md-10 member-body-content-wrap pt-3">
          <div class="row">
            <div class="col-xl-2 vertical-center">
              <div class="user-title mb-3">
                <h2>
                  Welcome <strong>{{ user.firstName }}</strong>
                </h2>
                <p>Member Dashboard</p>
              </div>
            </div>
            <div class="col-xl-10 col-md-12 btn-content">
              <div class="text-center">
                <!-- <div v-if="is_major_medical_plan" class="notice text-center notice-notify">
                  <div class="alert-icon">
                    <i class="fas fa-exclamation-triangle"></i>
                  </div>
                  <div class="alert-text">
                    <p class="pt-1 mb-1">New Rate as of January 1, 2024</p>
                    <router-link
                      target="_blank"
                      style="display: block"
                      class="route-link view-link"
                      :to="'/document-view-details'"
                      >Click here to view</router-link
                    >
                  </div>
                </div> -->
              </div>
              <div class="benefitstore-dash d-flex">
                <div class="benefitstore-image">
                  <img src="@/assets/images/b-store.svg" alt="" />
                </div>
                <div class="benefitstore-content">
                  <h2>The Benefit Store</h2>
                  <button @click="performanceHealthUrl" class="dashboard-button">
                    Add New Plans
                  </button>
                </div>
              </div>
<!--              <div class="referal-btn d-flex">
                <img
                  src="@/assets/images/referal-icon.svg"
                  alt=""
                  class="refer-img"
                />
                <div class="ref-btn" @click="memberReferral">
                  <span class="mt-1 mb-0">Referral Reward Program</span>
                </div>
              </div>-->
            </div>
            <!-- <div class="col-xl-2"></div> -->

            <!--        <div class="col-xl-2"></div>-->
            <!--            <div class="col-xl-2 text-center">-->
            <!-- <div v-if="is_major_medical_plan" class="notice "> -->
            <!--  <div class="alert-icon">
               <i class="fas fa-exclamation-triangle"></i>
             </div>
            <div class="alert-text">
              <p class="pt-1 mb-1"> New Rate as of January 1, 2023 </p>
               <router-link
               target="_blank"
                 style="display:block;"
                 class="route-link view-link"
                 :to="'/document-view-details'"
                 >Click here to view</router-link
               >
             </div>-->
            <!-- </div> -->
            <!--            </div>-->

            <dashboard-enrollment-plan
              @onSwitchedPlanGetProducts="getProductDetails"
            />
            <div class="col-xl-8 col-lg-12">
              <div class="dashboard-product-details">
                <product-details
                  ref="productDetail"
                  @setPlanType="isMajorMedical"
                ></product-details>
              </div>

              <div class="row">
                <div class="col-md-6">
                  <message-center></message-center>
                </div>

                <div class="col-md-6">
                  <resource></resource>
                </div>

                <div class="col-md-6">
                  <knowledge-center></knowledge-center>
                </div>

                <div class="col-md-6">
                  <beneficiary></beneficiary>
                </div>

                <div class="col-md-6">
                  <employer></employer>
                </div>

                <div class="col-md-6">
                  <dependent></dependent>
                </div>
              </div>
            </div>
            <div class="col-xl-4 col-lg-12">
              <div class="row">
                <div
                  class="col-xl-12 col-lg-6 col-md-6 col-sm-12 dashboard-personal"
                >
                  <personal-info></personal-info>
                </div>
                <div class="col-xl-12 col-lg-6 col-md-6 col-sm-12">
                  <div class="row">
                    <div class="col-12 dashboard-benefit-store">
                      <benefit-store></benefit-store>
                    </div>

                    <div class="col-12 dashboard-rep-info">
                      <rep-info></rep-info>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          class="col-md-12 member-body-content-wrap member-body-content-wrap-bottom"
        >
          <div class="row">
            <div class="col-lg-12">
              <div>
                <banking-info v-if="employer_type != 'employer'"></banking-info>
              </div>
            </div>
            <div class="col-lg-12">
              <div>
                 <billing-invoice></billing-invoice>
                <billing-invoice2
                  v-if="employer_type != 'employer'"
                ></billing-invoice2>
              </div>

              <!-- <div class="ticketing-wrapper ticketing-dashboard">
                <ticketing-dashboard />
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import helper from "../resource/Helper";

// import WelcomeContent from "../components/DashboardNew/WelcomeContent";
import ProductDetails from "../components/DashboardNew/ProductDetails";
import BenefitStore from "../components/DashboardNew/BenefitStore";
import PersonalInfo from "../components/DashboardNew/PersonalInfo";
import Resource from "../components/DashboardNew/Resource";
import MessageCenter from "../components/DashboardNew/MessageCenter";
import BillingInvoice from "../components/DashboardNew/BillingInvoice";
import Dependent from "../components/DashboardNew/Dependent.vue";
import BankingInfo from "../components/DashboardNew/BankingInfo";
import Employer from "../components/DashboardNew/Employer.vue";
import Beneficiary from "../components/DashboardNew/Beneficiary.vue";
import RepInfo from "../components/DashboardNew/RepInfo";
import BillingInvoice2 from "../components/DashboardNew/BillingInvoice2";
import KnowledgeCenter from "../components/DashboardNew/KnowledgeCenter.vue";
// import TicketingDashboard from '../components/Dashboard/TicketingDashboard.vue';
import DashboardEnrollmentPlan from "@/components/DashboardNew/DashboardEnrollmentPlan";
import { mapGetters } from "vuex";
export default {
  name: "DashboardNew",
  components: {
    // WelcomeContent,
    ProductDetails,
    BenefitStore,
    PersonalInfo,
    Resource,
    MessageCenter,
    BillingInvoice,
    Dependent,
    BankingInfo,
    Employer,
    Beneficiary,
    RepInfo,
    BillingInvoice2,
    KnowledgeCenter,
    // TicketingDashboard,
    DashboardEnrollmentPlan,
  },
  data: function () {
    return {
      employer_type: this.$store.state.groupType,
      employer_info: [
        { name: "Nuera Benefits", phone: "0123456789", status: "Employed" },
        { name: "Nuera Benefits", phone: "0123456789", status: "Employed" },
      ],
      beneficiary_info: [
        { name: "Neura Benefits", relation: "spouse" },
        { name: "Neura Benefits", relation: "spouse" },
      ],
      personal_details: [],
      last_login: "",
      login_time: "",
      is_major_medical_plan: false,
      memberId: "",
    };
  },
  computed: {
    myClass() {
      if (this.employer_info.length == 0 && this.beneficiary_info.length == 0) {
        return "employer_beneficiary";
      } else if (
        this.employer_info.length == 0 &&
        this.beneficiary_info.length != 0
      ) {
        return "employer";
      } else if (
        this.employer_info.length != 0 &&
        this.beneficiary_info.length == 0
      ) {
        return "beneficiary";
      } else {
        return "third_row";
      }
    },
    ...mapGetters({ user: "user" }),
  },
  mounted() {
    //only calling to store in vuex to last_login
    this.$store.dispatch("loadUserDetail");
    this.memberId = this.$store.getters.memberId;
  },
  methods: {
    getProductDetails() {
      this.$refs.productDetail.getProductDetails();
      this.$refs.productDetail.getTermDate();
    },
    isMajorMedical(flag = false) {
      this.is_major_medical_plan = flag;
    },
    logout() {
      helper.logout();
    },
    performanceHealthUrl() {
      const performanceUrl = this.$endpoint.BENEFIT_STORE_URL;
      window.open(
        `${performanceUrl}/member-home?uid=${btoa(this.memberId)}`,
        "_blank"
      );
    },
    memberReferral() {
      let app = this;
      app.$router.push(`/member-referral`);
    },
  },
};
</script>
<style scoped>
@import "../assets/css/dashboard-new.css";
</style>
