var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "id": "footer"
    }
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "about"
  }, [_vm._m(0), _c('p', [_vm._v(" If you have some technical difficulties Go to:"), _c('a', {
    attrs: {
      "target": "_blank",
      "href": _vm.ticketing_url
    }
  }, [_vm._v(" Issues Dashboard")])])])]), _c('div', {
    staticClass: "copyright"
  }, [_c('span', [_vm._v("© Copyright " + _vm._s(new Date().getFullYear()) + ". All Rights Reserved")])])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('a', {
    attrs: {
      "href": "#"
    }
  }, [_c('img', {
    staticClass: "footer_logo",
    attrs: {
      "src": require("../../assets/images/logo-iha.svg"),
      "alt": "logo"
    }
  })]);

}]

export { render, staticRenderFns }