var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "content_wrapper"
  }, [_c('agent-info'), _c('div', {
    ref: "save",
    staticClass: "contract_content"
  }, [_c('contract-form-sidebar'), _c('div', {
    staticClass: "right_container"
  }, [_c('h3', [_vm._v("Personal Information")]), _c('div', {
    staticClass: "form"
  }, [_c('div', [_c('label', [_vm._v("Representative ID")]), _c('br'), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formFields.id.value,
      expression: "formFields.id.value"
    }],
    attrs: {
      "type": "text",
      "name": "id",
      "value": "",
      "readonly": _vm.formFields.id.read_only == true
    },
    domProps: {
      "value": _vm.formFields.id.value
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.formFields.id, "value", $event.target.value);
      }
    }
  }), _vm.formErrorFlag == true ? _c('small', {
    staticClass: "error_message"
  }, [_vm._v(_vm._s(_vm.formErrors['id_message']))]) : _vm._e()]), _c('div', [_c('label', [_vm._v("First Name")]), _c('br'), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formFields.fname.value,
      expression: "formFields.fname.value"
    }],
    attrs: {
      "type": "text",
      "name": "fname",
      "value": "",
      "readonly": _vm.formFields.fname.read_only == true
    },
    domProps: {
      "value": _vm.formFields.fname.value
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.formFields.fname, "value", $event.target.value);
      }
    }
  }), _vm.formErrorFlag == true ? _c('small', {
    staticClass: "error_message"
  }, [_vm._v(_vm._s(_vm.formErrors['fname_message']))]) : _vm._e()]), _c('div', [_c('label', [_vm._v("Middle Initial")]), _c('br'), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formFields.minitial.value,
      expression: "formFields.minitial.value"
    }],
    attrs: {
      "type": "text",
      "name": "minitial",
      "value": "",
      "readonly": _vm.formFields.minitial.read_only == true
    },
    domProps: {
      "value": _vm.formFields.minitial.value
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.formFields.minitial, "value", $event.target.value);
      }
    }
  }), _vm.formErrorFlag == true ? _c('small', {
    staticClass: "error_message"
  }, [_vm._v(_vm._s(_vm.formErrors['minitial_message']))]) : _vm._e()]), _c('div', [_c('label', [_vm._v("Last Name")]), _c('br'), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formFields.lname.value,
      expression: "formFields.lname.value"
    }],
    attrs: {
      "type": "text",
      "name": "lname",
      "value": "",
      "readonly": _vm.formFields.lname.read_only == true
    },
    domProps: {
      "value": _vm.formFields.lname.value
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.formFields.lname, "value", $event.target.value);
      }
    }
  }), _vm.formErrorFlag == true ? _c('small', {
    staticClass: "error_message"
  }, [_vm._v(_vm._s(_vm.formErrors['lname_message']))]) : _vm._e()]), _c('div', [_c('label', [_vm._v("Date of Birth")]), _c('br'), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formFields.birth_date.value,
      expression: "formFields.birth_date.value"
    }],
    attrs: {
      "type": "text",
      "name": "birth_date",
      "value": "",
      "readonly": _vm.formFields.birth_date.read_only == true
    },
    domProps: {
      "value": _vm.formFields.birth_date.value
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.formFields.birth_date, "value", $event.target.value);
      }
    }
  }), _vm.formErrorFlag == true ? _c('small', {
    staticClass: "error_message"
  }, [_vm._v(_vm._s(_vm.formErrors['birth_date_message']))]) : _vm._e()]), _vm.formFields.social_security.value ? _c('div', [_c('label', [_vm._v("Social Security Number")]), _c('br'), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formFields.social_security.value,
      expression: "formFields.social_security.value"
    }],
    attrs: {
      "type": "text",
      "name": "social_security",
      "value": "",
      "readonly": _vm.formFields.social_security.read_only == true
    },
    domProps: {
      "value": _vm.formFields.social_security.value
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.formFields.social_security, "value", $event.target.value);
      }
    }
  }), _vm.formErrorFlag == true ? _c('small', {
    staticClass: "error_message"
  }, [_vm._v(_vm._s(_vm.formErrors['social_security_message']))]) : _vm._e()]) : _vm.formFields.tin_number.value ? _c('div', [_c('label', [_vm._v("Company Tax ID")]), _c('br'), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formFields.tin_number.value,
      expression: "formFields.tin_number.value"
    }],
    attrs: {
      "type": "text",
      "name": "tin_number",
      "value": "",
      "readonly": _vm.formFields.tin_number.read_only == true
    },
    domProps: {
      "value": _vm.formFields.tin_number.value
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.formFields.tin_number, "value", $event.target.value);
      }
    }
  }), _vm.formErrorFlag == true ? _c('small', {
    staticClass: "error_message"
  }, [_vm._v(_vm._s(_vm.formErrors['tin_number_message']))]) : _vm._e()]) : _vm._e()]), _c('div', {
    staticClass: "button_container"
  }, [_c('div', {
    staticClass: "button"
  }, [_c('button', {
    staticClass: "next",
    attrs: {
      "type": "button",
      "name": "button"
    },
    on: {
      "click": _vm.savePersonalInfo
    }
  }, [_vm._v("Next")])])])])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }