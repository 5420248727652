<template>
  <b-form-group :class="formGroupClass">
    <label :for="`label-${label || validationLabel}`">
      <span v-html="computedLabel" />
      <slot name="labelMessage" />
    </label>
    <b-form-file
      v-model="computedInputData"
      :readonly="readonly"
      :accept="computedMimeTypes"
      multiple
      class="file-input-custom"
      plain
    >
    </b-form-file>
    <slot name="uploadedFiles"></slot>
    <template v-if="vObject && vObject.$error">
      <span class="error-text">{{ computedErrorMessage }}</span>
    </template>
  </b-form-group>
</template>
<script>
import ErrorMessage from "./error_message";
export default {
  props: {
    readonly: {
      type: Boolean,
      required: false,
      default: false,
    },
    rules: {
      type: Object,
      required: false,
      default: null,
    },
    label: {
      type: String,
      required: false,
    },
    vmodel: {
      required: true,
      default: null,
    },
    formGroupClass: {
      type: String,
      required: false,
    },
    acceptMimeTypes: {
      type: Array,
      required: false,
      default: () => [],
    },
    validationLabel: {
      required: false,
    },
    vObject: {
      required: false,
    },
  },
  data: () => {
    return {
      file: [],
    };
  },
  computed: {
    computedMimeTypes() {
      return this.acceptMimeTypes.toString();
    },
    computedPlaceholder() {
      if (this.placeholder == "") {
        return `No File Choosen`;
      }
      return this.placeholder;
    },
    computedLabel() {
      if (this.rules && this.rules.required) {
        return this.label + "<span class='input-required'> * </span>";
      }
      return this.label;
    },
    computedInputData: {
      get: function () {
        return this.vmodel;
      },
      set: function (files) {
        if (this.vObject) {
          this.vObject.$touch();
        }
        // let file = this.file;
        this.$emit("update:vmodel", files ? files : []);
      },
    },
    computedErrorMessage() {
      let errorMessage = new ErrorMessage(
        this.vObject,
        this.label || this.validationLabel
      ).generateMessage();
      return errorMessage[0];
    },
  },
  methods: {},
};
</script>
<style>
.file-input-custom {
  z-index: -5 !important;
}
.file-input-custom:before {
  content: "Choose Files";
  display: inline-block;
  /* background: #03c3ec; */
  background: var(--secondary-color);
  border-radius: 3px;
  padding: 8px 30px;
  outline: none;
  white-space: nowrap;
  user-select: none;
  -webkit-user-select: none;
  cursor: pointer;
  font-size: 14px;
  color: #fff;
  z-index: 1 !important;
}
.file-input-custom::-webkit-file-upload-button {
  visibility: hidden;
  margin-left: -80px;
}
.input-required {
  color: rgb(255, 0, 0) !important;
}
.error-text {
  display: block;
  margin-top: 4px;
  color: #dc3545;
  font-size: 14px;
}
</style>

