<template>
  <div id="footer">
    <div class="container">
      <div class="about">
        <a href="#"
          ><img
            class="footer_logo"
            src="../../assets/images/logo-iha.svg"
            alt="logo"
        /></a>
        <p>
          If you have some technical difficulties Go to:<a
            target="_blank"
            :href="ticketing_url"
          >
            Issues Dashboard</a
          >
        </p>
      </div>

      <!-- <div class="download">
        <span>Download The Member App</span>
        <div class="app_download">
          <a
            target="_blank"
            href="https://apps.apple.com/us/app/corenroll-member/id1539841827"
            ><img
              src="../../assets/images/app-store.png"
              alt="apple"
              width="162"
              height="52"
          /></a>

          <a
            target="_blank"
            href="https://play.google.com/store/apps/details?id=com.neura.corenroll_member"
            ><img
              src="../../assets/images/play-store.png"
              alt="apple"
              width="162"
              height="50"
          /></a>
        </div>
        <span>Click the Appropriate App</span>
      </div> -->
    </div>
    <div class="copyright">
      <span
        >&copy; Copyright {{ new Date().getFullYear() }}. All Rights
        Reserved</span
      >
    </div>
  </div>
</template>
<script>
import { ticketing_url } from '../../utlis/constant';
export default{
  data(){
return{
  ticketing_url
}
  }
}
</script>