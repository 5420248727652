<template>
  <div class="ticket-card p-4">
    <div class="ticket-form">
      <div class="mb-3">
        <h3 class="main-text">New Ticket</h3>
        <span class="d-inline-bloc clr-bold-grey font-13"
          >Please fill the form below to submit your ticket.</span
        >
      </div>
      <form @submit.stop.prevent="submit" ref="ticketForm">
        <div class="form-group row">
          <div class="col-lg-12 col-md-12 col-sm-12 mb-3">
            <input-text
              label="Phone"
              label-for="phone"
              :vmodel.sync="form.phone"
              input-class="shadow-none"
            />
          </div>
        </div>
        <div class="form-group row">
          <div class="col-lg-6 col-md-12 col-sm-12 mb-3">
            <input-text
              :rules="{ required: true }"
              label="Subject"
              label-for="subject"
              :vmodel.sync="form.subject"
              input-class="shadow-none"
              :vObject="$v.form.subject"
            />
          </div>
          <div class="col-lg-6 col-md-12 col-sm-12 mb-3">
            <input-single-select
              label="Category"
              :vmodel.sync="form.category_id"
              :options="
                categories.length ? categories.map((item) => item.id) : []
              "
              :custom-label="
                categories.length
                  ? (id) => categories.find((item) => item.id == id).name
                  : () => {}
              "
              :rules="{ required: true }"
              :vObject="$v.form.category_id"
            />
          </div>
        </div>
        <div class="form-group row">
          <input-quill-editor
            :vmodel.sync="form.message"
            label="Message"
            :rules="{ required: true }"
            placeholder="Add your message here ..."
            :vObject="$v.form.message"
          />
        </div>
        <div class="form-group row">
          <div class="col-sm-12 mb-3">
            <input-file-multiple
              label="Upload Document"
              validation-label="document"
              :vmodel.sync="form.issue_document"
              :vObject="$v.form.issue_document"
            >
              <template #uploadedFiles>
                <span class="font-14 d-inline-block py-2 clr-lightgrey"
                  >Allowed file types : jpeg,png,jpg,gif,svg,pdf,xls,
                  xlsx,doc,docx,csv & txt</span
                >
              </template>
            </input-file-multiple>
          </div>
        </div>
        <div class="form-group row justify-content-center">
          <div class="col-lg-3 col-md-4">
            <button
              type="submit"
              class="ticket-submit-btn pull-right btn-no-focus"
            >
              <b-spinner class="custom-spin" v-if="isSubmit" />{{
                isSubmit ? "Submitting ..." : "Submit"
              }}
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import InputFileMultiple from "../../../components/form/InputFileMultiple.vue";
import InputSingleSelect from "../../../components/form/InputSingleSelect.vue";
import InputText from "../../../components/form/InputText.vue";
import axios from "axios";
import InputQuillEditor from "@/components/form/InputQuillEditor";
import { required } from "vuelidate/lib/validators";
import helper from "@/resource/Helper";

const FORM = {
  email: "",
  first_name: "",
  last_name: "",
  phone: "",
  subject: "",
  message: "",
  user_type: "M", //for member dashboard
  category_id: "",
  issue_document: [],
};
export default {
  components: {
    InputQuillEditor,
    InputText,
    InputSingleSelect,
    InputFileMultiple,
  },
  data: () => ({
    form: {
      ...FORM,
    },
    categories: [],
    isSubmit: false,
  }),
  validations: {
    form: {
      subject: {
        required,
      },
      category_id: {
        required,
      },
      message: {
        required,
      },
    },
  },
  methods: {
    getCategories() {
      const url = `${process.env.VUE_APP_TICKETING_API}/api/v1/tickets/category`;
      axios
        .get(url)
        .then((res) => {
          this.categories = res.data.data;
        })
        .catch(() => {
          this.categories = [];
        });
    },
    submit() {
      if (!this.isFormValid()) {
        return;
      }
      let loader = this.$loading.show();
      const url = `${process.env.VUE_APP_TICKETING_API}/api/v1/tickets/send`;
      let formData = new FormData();
      for (const [key, value] of Object.entries(this.form)) {
        formData.append(key, value);
        if (
          key == "issue_document" &&
          this.form.issue_document &&
          this.form.issue_document.length
        ) {
          formData.delete("issue_document"); //reset
          for (let i = 0; i < this.form.issue_document.length; i++) {
            formData.append(`${key}[${i}]`, this.form.issue_document[i]);
          }
        }
      }
      axios
        .post(url, formData)
        .then((res) => {
          helper.infoMessage(this, "success", res.data.message);
          this.$emit("onSuccess");
          this.resetForm();
        })
        .catch((err) => {
          helper.infoMessage(this, "error", err.response.data.message);
        })
        .finally(() => {
          loader.hide();
        });
    },
    resetForm() {
      const resetFields = {
        subject: "",
        message: "",
        category_id: "",
        issue_document: null,
      };
      Object.keys(resetFields).forEach((k) => {
        this.form[k] = resetFields[k];
      });
      this.$nextTick(() => this.$v.$reset());
    },
    getUserDetails() {
      this.form.email = this.$store.getters.memberEmail;
      this.form.first_name = this.$store.getters.user.name;
      this.form.last_name = "";
      this.form.phone = this.validatePhone(this.$store.getters.user.phone);
    },
    validatePhone(chars) {
      return chars.replace(/\D/g, "");
    },
    isFormValid() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return false;
      }
      return true;
    },
  },
  mounted() {
    this.getCategories();
    this.getUserDetails();
  },
};
</script>

<style>
.ticket-form .form-group {
  margin-bottom: 3px !important;
}
#ticket-upload-file-popover {
  border-radius: 8px !important;
  box-shadow: 0 0 8px #dfe8ea !important;
  border: none !important;
  top: -6px !important;
  min-width: 525px !important;
}
#ticket-upload-file-popover .arrow::before,
.bs-popover-auto[x-placement^="top"] > .arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #ddd8d840;
}
.ticket-form div.multiselect {
  width: 100% !important;
  margin-left: 0 !important;
}
.ticket-submit-btn {
  border: 0;
  /* background: #0cf; */
  background: var(--secondary-color);
  color: #fff;
  padding: 10px 13px;
  font-weight: 600;
  border-radius: 6px;
  outline: none;
  transition: all ease 0.6s;
  min-width: 190px;
}
</style>
