import { render, staticRenderFns } from "./AddressInfo.vue?vue&type=template&id=9d0870da&scoped=true"
import script from "./AddressInfo.vue?vue&type=script&lang=js"
export * from "./AddressInfo.vue?vue&type=script&lang=js"
import style0 from "../../assets/css/W9_form/address_info.css?vue&type=style&index=0&id=9d0870da&prod&scoped=true&lang=css&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9d0870da",
  null
  
)

export default component.exports