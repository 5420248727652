var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "billing_invoice_new dashboard-card dashboard-card-lt mt-4"
  }, [_c('h3', [_vm._v("Billing & Invoices")]), _c('div', {
    staticClass: "table_content"
  }, [_c('table', [_vm._m(0), _vm._l(_vm.billing_invoice, function (item, index) {
    return _c('tbody', {
      key: index
    }, [item.payment_status == 'PAID' ? _c('tr', {
      attrs: {
        "classs": "paid"
      }
    }, [_c('td', {
      staticStyle: {
        "font-family": "SF UI Display Medium"
      }
    }, [_vm._v(" " + _vm._s(item.payment_id) + " ")]), _c('td', {
      staticStyle: {
        "font-family": "SF UI Display Medium"
      }
    }, [_vm._v(_vm._s(item.name))]), _c('td', [_vm._v(_vm._s(item.payment_date))]), _c('td', [_vm._v(_vm._s(item.method))]), _c('td', [_vm._v(_vm._s(item.amount))]), _c('td', [_vm._v(_vm._s(item.total))]), _c('td', [_vm._v(_vm._s(item.payment_status))]), _c('td', [_c('button', {
      on: {
        "click": function ($event) {
          return _vm.downloadFile(item.file_link);
        }
      }
    }, [_c('img', {
      attrs: {
        "src": require("../../assets/images/download_icon.png")
      }
    }), _vm._v(" Download ")])])]) : _vm._e(), item.payment_status == 'UNPAID' ? _c('tr', {
      staticClass: "unpaid"
    }, [_c('td', {
      staticStyle: {
        "font-family": "SF UI Display Medium"
      }
    }, [_vm._v(" " + _vm._s(item.payment_id) + " ")]), _c('td', {
      staticStyle: {
        "font-family": "SF UI Display Medium"
      }
    }, [_vm._v(_vm._s(item.name))]), _c('td', [_vm._v(_vm._s(item.payment_date))]), _c('td', [_vm._v(_vm._s(item.method))]), _c('td', [_vm._v(_vm._s(item.amount))]), _c('td', [_vm._v(_vm._s(item.total))]), _c('td', [_vm._v(_vm._s(item.payment_status))]), _c('td', [_c('button', {
      on: {
        "click": function ($event) {
          return _vm.payNow(item.payment_id);
        }
      }
    }, [_c('img', {
      attrs: {
        "src": require("../../assets/images/pay.png")
      }
    }), _vm._v(" Pay Now ")])])]) : _vm._e()]);
  })], 2)]), _c('div', {
    staticClass: "overflow-auto"
  }, [_c('b-pagination', {
    staticStyle: {
      "justify-content": "center",
      "margin-top": "20px"
    },
    attrs: {
      "total-rows": _vm.pagination.total,
      "first-text": "First",
      "prev-text": "Prev",
      "next-text": "Next",
      "last-text": "Last",
      "per-page": _vm.pagination.perPage
    },
    model: {
      value: _vm.pagination.current,
      callback: function ($$v) {
        _vm.$set(_vm.pagination, "current", $$v);
      },
      expression: "pagination.current"
    }
  })], 1)]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('thead', [_c('tr', [_c('td', [_vm._v("Payment ID")]), _c('td', [_vm._v("Name")]), _c('td', [_vm._v("Payment Date")]), _c('td', [_vm._v("Method")]), _c('td', [_vm._v("Amount")]), _c('td', [_vm._v("Totals")]), _c('td', [_vm._v("Status")]), _c('td', [_vm._v("Action")])])]);

}]

export { render, staticRenderFns }