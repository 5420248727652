<template>
  <div class="billing_invoice_new dashboard-card dashboard-card-lt mt-4">
    <h3>Billing & Invoices</h3>
    <div class="table_content">
      <table>
        <thead>
          <tr>
            <td>Payment ID</td>
            <td>Name</td>
            <td>Payment Date</td>
            <td>Method</td>
            <td>Amount</td>
            <td>Totals</td>
            <td>Status</td>
            <td>Action</td>
          </tr>
        </thead>
        <tbody v-for="(item, index) in billing_invoice" :key="index">
          <tr classs="paid" v-if="item.payment_status == 'PAID'">
            <td style="font-family: SF UI Display Medium">
              {{ item.payment_id }}
            </td>
            <td style="font-family: SF UI Display Medium">{{ item.name }}</td>
            <td>{{ item.payment_date }}</td>
            <td>{{ item.method }}</td>
            <td>{{ item.amount }}</td>
            <td>{{ item.total }}</td>
            <td>{{ item.payment_status }}</td>
            <td>
              <button @click="downloadFile(item.file_link)">
                <img src="../../assets/images/download_icon.png" />
                Download
              </button>
            </td>
          </tr>
          <tr class="unpaid" v-if="item.payment_status == 'UNPAID'">
            <td style="font-family: SF UI Display Medium">
              {{ item.payment_id }}
            </td>
            <td style="font-family: SF UI Display Medium">{{ item.name }}</td>
            <td>{{ item.payment_date }}</td>
            <td>{{ item.method }}</td>
            <td>{{ item.amount }}</td>
            <td>{{ item.total }}</td>
            <td>{{ item.payment_status }}</td>
            <td>
              <button @click="payNow(item.payment_id)">
                <img src="../../assets/images/pay.png" /> Pay Now
              </button>
            </td>
          </tr>
        </tbody>
      </table>
   
    </div>
     <div class="overflow-auto">
    <!-- Use text in props -->
    <b-pagination
      v-model="pagination.current"
      :total-rows="pagination.total"
      first-text="First"
      prev-text="Prev"
      next-text="Next"
      last-text="Last"
      :per-page="pagination.perPage"
      style="justify-content:center;margin-top:20px;"
    ></b-pagination>
     </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      billing_invoice: [],
       pagination: {
       current: 0,
       total:0,
       perPage:5
      },
    };
  },
   created() {
    return this.getInvoiceBillingData()
  },
  methods: {
    getInvoiceBillingData() {
      let app = this;
      return axios.get("/get-invoice-details").then((response) => {
        app.billing_invoice = response.data.data;
      });
    },
    downloadFile(fileUrl) {
   
      window.location = fileUrl;
    },
    // payNow(paymentID) {
    //   console.log("paymentID");
    // },
    
    // changePage(pageNumber) {
    //   this.currentPage = pageNumber
    //   this.getInvoiceBillingData()
    // },
    
  },
};
</script>

<style>
.page-item.active .page-link{
  background:#00ccff !important;
}
.page-link{
  padding:10px 15px !important;
}
.page-item button{
width:50px !important;
height:100% !important;
border:none !important;
border-radius: 0 !important;
}
@media only screen and (max-width:760px){
  .page-link{
    padding:5px 10px !important;
  }
  .page-item button{
    width:40px !important;
  }
}
</style>