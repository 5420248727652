var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "container-fluid"
  }, [_c('div', {
    staticClass: "row body-main-row"
  }, [_c('div', {
    staticClass: "member-body-content-wrap member_dashboard"
  }, [_c('div', {
    staticClass: "view-message"
  }, [_c('div', {
    staticClass: "contents"
  }, [_c('div', {
    staticClass: "messages_new"
  }, [_vm._m(0), _c('div', {
    staticClass: "message_table_new"
  }, [_c('div', [_c('b-card', {
    attrs: {
      "no-body": ""
    }
  }, [_vm.message_loader ? _c('b-skeleton-table', {
    attrs: {
      "rows": 5,
      "columns": 2,
      "table-props": {
        bordered: true,
        striped: true
      }
    }
  }) : _vm._e(), !_vm.message_loader ? _c('b-tabs', {
    attrs: {
      "pills": "",
      "card": "",
      "vertical": ""
    }
  }, _vm._l(_vm.messages.records, function (item, index) {
    var _item$sender_details$, _item$sender_details;
    return _c('b-tab', {
      key: index,
      on: {
        "click": function ($event) {
          return _vm.openModal('message_' + item.mid, item.mid);
        }
      },
      scopedSlots: _vm._u([{
        key: "title",
        fn: function () {
          return [_c('div', {
            staticClass: "list-icon"
          }, [_c('img', {
            attrs: {
              "src": require("../assets/images/message-icon.svg"),
              "alt": ""
            }
          })]), _c('div', {
            staticClass: "list-sub"
          }, [_c('h3', [_vm._v(_vm._s(item.subject))]), _c('p', [_vm._v(_vm._s(item.date_created))])])];
        },
        proxy: true
      }], null, true)
    }, [_c('b-card-text', {
      staticClass: "message-details-div",
      attrs: {
        "id": 'message_' + item.mid
      }
    }, [_vm.message_loader ? _c('b-skeleton-table', {
      attrs: {
        "rows": 2,
        "columns": 1,
        "table-props": {
          bordered: true,
          striped: true
        }
      }
    }) : _vm._e(), _c('div', {
      staticClass: "rcv-msgcontent"
    }, [_c('div', {
      staticClass: "rcv-msg-sender"
    }, [_c('div', {
      staticClass: "rcv-snder-icon"
    }, [_c('img', {
      staticClass: "msg-userIcon",
      attrs: {
        "src": (_item$sender_details$ = item.sender_details.image) !== null && _item$sender_details$ !== void 0 ? _item$sender_details$ : _vm.image,
        "alt": ""
      }
    }), _c('div', {
      staticClass: "rcv-sender-details"
    }, [_c('h5', [_vm._v(_vm._s((_item$sender_details = item.sender_details) === null || _item$sender_details === void 0 ? void 0 : _item$sender_details.name) + " - " + _vm._s(item.from))]), _c('p', [_c('i', {
      staticClass: "far fa-calendar-alt"
    }), _vm._v(" " + _vm._s(_vm._f("date_format")(item.date_created, "YYYY-MM-DD", "MM/DD/YYYY", item.date_created)) + " ")])])])]), _c('h5', [_vm._v("Title: " + _vm._s(item.subject))]), _c('hr'), _c('div', {
      staticClass: "ql-snow"
    }, [_c('div', {
      staticClass: "ql-editor"
    }, [_c('p', {
      domProps: {
        "innerHTML": _vm._s(item.message)
      }
    })])]), item.attachments && item.attachments.length > 0 ? _c('div', [_c('h6', [_vm._v("Attachments:")]), _c('ul', {
      staticStyle: {
        "list-style": "none"
      }
    }, _vm._l(item.attachments, function (attachment, attachmentindex) {
      return _c('li', {
        key: attachmentindex
      }, [_c('a', {
        attrs: {
          "href": attachment.file_url,
          "target": "__blank",
          "download": ""
        }
      }, [_c('i', {
        staticClass: "fa fa-file"
      }), _vm._v(" " + _vm._s(attachment.title) + " ")])]);
    }), 0)]) : _vm._e()])], 1)], 1);
  }), 1) : _vm._e()], 1)], 1)])]), _c('b-modal', {
    attrs: {
      "id": 'deleteMessageDetails',
      "title": "Delete Message",
      "ok-title": "Confirm",
      "centered": true,
      "size": "lg"
    },
    on: {
      "ok": _vm.deleteMessage
    }
  }, [_c('p', {
    staticClass: "confirmation"
  }, [_vm._v(" Are you sure you want to delete this message? ")])])], 1)])])])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "message_dropdown"
  }, [_c('h3', {
    staticStyle: {
      "vertical-align": "text-bottom"
    }
  }, [_vm._v("Messages")])]);

}]

export { render, staticRenderFns }