var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm._m(0);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('header', [_c('div', {
    staticClass: "header-logo"
  }, [_c('img', {
    attrs: {
      "src": "https://performance-health-logo.s3.us-east-2.amazonaws.com/logo.png"
    }
  })])]);

}]

export { render, staticRenderFns }