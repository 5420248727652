<template>
    <div class="content_wrapper">
        <agent-info></agent-info>
        <div class="contract_content" ref="save">
            <contract-form-sidebar/>

            <div class="right_container">
                   <h3>Address Information</h3>
                   <div class="form">
                       <div>
                          <label>Street Address1</label><br>
                          <input type="text" name="street_address1" v-model="formFields.street_address1.value" value="" :readonly="formFields.street_address1.read_only == true">
                          <small class="error_message" v-if="formErrorFlag == true">{{formErrors['street_address1_message']}}</small>
                       </div>

                       <div>
                        <label>Street Address2</label><br>
                        <input type="text" name="street_address2" v-model="formFields.street_address2.value" value="" :readonly="formFields.street_address2.read_only == true">
                        <small class="error_message" v-if="formErrorFlag == true">{{formErrors['street_address2_message']}}</small>
                    </div>
                    <div>
                        <label>City</label><br>
                         <input type="text" name="city" v-model="formFields.city.value" value="" :readonly="formFields.city.read_only == true">
                          <small class="error_message" v-if="formErrorFlag == true">{{formErrors['city_message']}}</small>
                    </div>
                       
                    <div>
                        <label>State</label><br>
                         <input type="text" name="state" v-model="formFields.state.value" value="" :readonly="formFields.state.read_only == true">
                            <small class="error_message" v-if="formErrorFlag == true">{{formErrors['state_message']}}</small>
                    </div>

                    <div>
                        <label>Zip</label><br>
                         <input type="text" name="zip" v-model="formFields.zip.value" value="" :readonly="formFields.zip.read_only == true">
                          <small class="error_message" v-if="formErrorFlag == true">{{formErrors['zip_message']}}</small>
                    </div>

                   </div> 

                   <div class="button_container">
                    <button class="back" type="button" name="button" @click="navigatePrevious">Back</button>
                      <div class="button">
                    <!-- <a href="#"><button class="save">Save</button></a> -->

                    <button class="next" type="button" name="button" @click="saveAddressInfo" >Next</button>
                </div>
                </div>
            </div>
            </div>
        </div>
    
</template>



<style src="../../assets/css/contract_form/address_info.css" scoped>

</style>
<script>
    import ContractFormSidebar from '../partial/ContractFormSidebar.vue';
    import axios from 'axios';
    import AgentInfo from "../common/AgentInfo.vue"
    import html2canvas from 'html2canvas';
    export default{
        components:{AgentInfo, ContractFormSidebar},
        data: function(){
            return{
                tempId: '',
                formFields: {
                    street_address1: {
                        value: '',
                        required: false,
                        read_only: false,
                    },
                    street_address2: {
                        value: '',
                        required: false,
                        read_only: false,
                    },
                    city: {
                        value: '',
                        required: false,
                        read_only: false,
                    },
                    state: {
                        value: '',
                        required: false,
                        read_only: false,
                    },
                    zip: {
                        value: '',
                        required: false,
                        read_only: false,
                    },
              },
              formErrorFlag: false,
              formErrors: [],
            }
        },
        mounted(){
            let app = this;
            app.tempId= window.localStorage.getItem('tempId');
            app.getAddressInfo();
        },
        methods:{
            getAddressInfo: function () {
                let loader = this.$loading.show();
                let app = this;
                axios.get(process.env.VUE_APP_CORENROLL_SYSTEM+'/get-form-data-address-info?', {
                params: {
                    tempid: app.tempId,
                }
                })
                .then(function (response) {
                    console.log(response);
                    let fetchedData = [];
                    if (response.data.status == 'success') {
                        for(var i in response.data.data.result) {
                            fetchedData.push(response.data.data.result[i]);
                        }
                        console.log(fetchedData);
                        // app.tempId = response.data.tempid;
                        fetchedData.forEach(function(item) {
                          console.log(item);
                            app.formFields[item.field_name].value = item.value;
                            app.formFields[item.field_name].read_only = item.read_only;
                        });
                    }
                    })
                    .catch(function (error) {
                  if(error.response.data.message == 'Form completed.'){
                      window.location = '/contract-form/contract_message/completed';
                  }else if(error.response.data.message == 'Agent does not exist.'){
                      window.location = '/contract-form/contract_message/not_found';
                  } else {
                    window.location = '/contract-form/contract_message/error';
                  }
                }).finally(() => {
                loader.hide();
                });
            },
            saveAddressInfo: function () {
                let loader = this.$loading.show();
                const el = this.$refs.save;
                (async () => {
                    this.saveScreenShot((await html2canvas(el)).toDataURL('image/png'),'address');
                })();
                let app = this;
                app.formErrorFlag = false;
                app.formErrors    = [];
                let params = {
                  tempid: app.tempId,
                  street_address1:    app.formFields.street_address1.value,
                  street_address2:    app.formFields.street_address2.value,
                  city:               app.formFields.city.value,
                  state:              app.formFields.state.value,
                  zip:                app.formFields.zip.value,
                };
                //discard null values
                for (const key of Object.keys(params)) {
                  if (params[key] === null) {
                      delete params[key];
                  }
                }

                axios.post(process.env.VUE_APP_CORENROLL_SYSTEM+'/save-address-information', params)
                .then(function (response) {
                    if (response.data.status == 'success') {
                      console.log("success");
                      app.$router.push("billing_info");
                      window.localStorage.setItem('address_complete', 'complete');
                    }
                })
                .catch(function (error) {
                    console.log(error.response.data.data);
                    if (error.response.data.status == 'error') {
                      error.response.data.data.forEach(function(item) {
                          app.formErrorFlag = true;
                          app.formErrors[item.target_element+'_message'] = item.error_message;
                      });
                    }
                }).finally(() => {
                loader.hide();
                });
            },
            navigatePrevious() {
                //Update APIs
                //Navigate to next page
                this.$router.push("contact_info");
                window.localStorage.setItem('contact_complete', 'incomplete');
             
            },
            saveScreenShot (image, name) {
             
              let data = {
                  "agent_id" : window.localStorage.getItem('agentId'),
                  "contract_type" : window.localStorage.getItem('contractType'),
                  "image" : image,
                  "name" : name,
                  "tempid" : this.tempId,
              };
              let config = {
                  method: 'post',
                  url: process.env.VUE_APP_CORENROLL_SYSTEM+'/save-contract-screenshot',
                  headers: { },
                  data : data
              };

              axios(config)
                  .then(function (response) {
                      if (response.data.status == 'success') {
                          console.log('screenshot saved');
                      }
                  })
                  .catch(function (error) {
                      console.log('screenshot not saved');
                      console.log(error);
                  });
            },
            scrollTop(){
                window.scrollTo(0,0);
            }      
        }
    };
</script>