import Vue from 'vue'
import App from './App.vue'
import router from './router'
import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'
import VueSignaturePad from 'vue-signature-pad'
import { CollapsePlugin } from 'bootstrap-vue'

import { BootstrapVue } from 'bootstrap-vue'
import { ModalPlugin } from 'bootstrap-vue'
import { FormPlugin } from 'bootstrap-vue'
import { DropdownPlugin } from 'bootstrap-vue'
// import { PopoverPlugin } from 'bootstrap-vue'
// import Notifications from 'vue-notification'
import JwPagination from 'jw-vue-pagination';
import Vuelidate from 'vuelidate'
import VueConfirmDialog from 'vue-confirm-dialog';
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'vue-multiselect/dist/vue-multiselect.min.css'

import UUID from "vue-uuid";
import axios from 'axios';
import VueAxios from 'vue-axios'
import OtpInput from "@bachdgvn/vue-otp-input";
import CKEditor from '@ckeditor/ckeditor5-vue2';
import Pagination from "@/components/Pagination.vue";
import dummyImage from "@/assets/images/user.svg";

import './utlis/component'
import './utlis/filter'
import './utlis/quill'

Vue.use(VueAxios, axios)

Vue.prototype.$axios = axios;
// import VueSignature from "vue-signature-pad";


// import Vuex from 'vuex'
import config from "./config/config"
import store from "./store/store"
import Multiselect from 'vue-multiselect'
import DatePicker from "vue2-datepicker";
import Loading from 'vue-loading-overlay';
import vuescroll from 'vuescroll';

import 'vue-loading-overlay/dist/vue-loading.css';
import "vue2-datepicker/index.css";

import './assets/css/variable.css'

config.config()
Vue.use(BootstrapVue)
Vue.use(ModalPlugin)
Vue.use(FormPlugin)
Vue.use(DropdownPlugin)
Vue.use(VueSignaturePad);
Vue.use(CollapsePlugin)
Vue.use(Vuelidate)
Vue.use(UUID);
Vue.use(CKEditor);
Vue.use(Loading);
Vue.use(vuescroll)
Vue.use(VueConfirmDialog)
Vue.component('vue-confirm-dialog', VueConfirmDialog.default)
Vue.component('jw-pagination', JwPagination);
Vue.component("v-otp-input", OtpInput);
Vue.config.productionTip = false
Vue.component('multiselect', Multiselect);
Vue.component('date-picker', DatePicker);

Vue.component('pagination', Pagination)



Vue.filter('currency', function (value) {
  value = (typeof value == 'string') ? parseFloat(value.replace(",", "")) : parseFloat(value)
  if (value % 1 == 0) {
      value= `$${parseInt(value)}.00`;
  }else{
      value= `$${value.toFixed(2)}`;
  }
  return value
})

import Notifications from 'vue-notification'
Vue.use(Notifications)
import endpoint from "@/resource/endpoint"

Vue.prototype.$endpoint = endpoint
Vue.prototype.$dummyImage = dummyImage

new Vue({
  store: store,
  router,
  // helper: helper,
  render: h => h(App)
}).$mount('#app')
