<template>
  <div class="pdf-pageWrapper">
      <div class="container">
        <div class="row">
          <div class="col-12">
              <vue-html2pdf
              :show-layout="true"
              :float-layout="false"
              :enable-download="false"
              :preview-modal="false"
              filename="test.pdf"
              :pdf-quality="2"
              :manual-pagination="true"
              pdf-format="a4"
              pdf-orientation="portrait"
              pdf-content-width="100%"
              :htmlToPdfOptions="htmlToPdfOptions"
              @beforeDownload="beforeDownload($event)"
              @hasDownloaded="hasDownloaded($event)"
              ref="html2Pdf"
              >
              <section slot="pdf-content" class="pdf-section">
                  <section class="description pdf-item">
                  <section class="title">
                      <!-- <h2>Elevate Wellness Association</h2> -->
                      <b>“Independent Marketer Agreement”</b>
                  </section>
                  <p class="intro">
                    This Independent Marketer Agreement is by and
                        between EWA and
                        <strong>{{ marketing_initial }}</strong>
                        Independent Marketer, located/residing at
                        <strong>{{ marketer_address }}</strong>
                  </p>
                  <br />
                  <ol class="list">
                        <b>
                          <li>EFFECTIVE DATE</li> 
                        </b>
                        <p class="initial">
                            The effective date of this Agreement shall be
                            <strong>{{ aggrement_eff_date }}</strong>
                          </p>
                          <br>
                        <b>
                          <li>TERM</li> </b
                        >
                        <p>
                          This Agreement shall commence on the Effective Date stated in Paragraph I herein above and shall terminate two (2) years from the Effective Date.  
                          This Agreement shall automatically renew, unless terminated in accordance with Paragraph VI herein below.
                        </p>
                        <br>
                        <b>
                          <li>AUTHORIZED SERVICES PROVIDED BY INDEPENDENT MARKETER</li> </b
                        >
                        <ol type="a">
                          <br />
                          <li>
                            EWA authorizes INDEPENDENT MARKETER to solicit, procure, verify and transmit insurance applications 
                            to contracted insurance carriers and to perform such other functions as may reasonably be required.
                          </li>
                          <br />
                          <li>
                            INDEPENDENT MARKETER is not authorized on behalf of EWA or its contracted carriers to: incur any liability; 
                            make, waive, alter, endorse or discharge contracts or any terms thereof; bind any application for, or policy of, insurance; 
                            endorse checks payable; deliver any policy; extend the time for any payment of monthly rate; waive forfeitures; name special rates; 
                            or bind EWA to any contract, in any way.  Unless authorized in writing, INDEPENDENT MARKETER shall not collect any monies due, 
                            or to become due, except for the collection of the initial monthly premium required by the insurance carrier.
                          </li>
                          <br />
                          <li>
                            INDEPENDENT MARKETER is an independent contractor for all purposes and not an employee of EWA.  
                            INDEPENDENT MARKETER has full control of its time and the right to exercise independent judgment as to the time, 
                            place and manner of performing services, except that it shall conform with all Carrier's guidelines, EWA's rules and regulations, 
                            as well as all federal, state and local laws, rules, regulations and ordinances, wherever applicable.
                          </li>
                          <br />
                        </ol>
                        <b>
                          <li>TERRITORY</li> </b
                        >
                        <p>
                          INDEPENDENT MARKETER shall maintain all required licenses in all jurisdictions in which he or she is selling on behalf of EWA and shall comply 
                          with any and all applicable federal, state, or local laws, rules, regulations or ordinances affecting its operations.  All agents and/or sub agents 
                          selling any product on behalf of EWA and compensated by INDEPENDENT MARKETER's entity shall be licensed in their resident state and shall have all 
                          non-resident licenses required by various state laws or shall not sell any product on behalf of the carrier or EWAn any state requiring the same.  
                          INDEPENDENT MARKETER does not, however, have the exclusive right to act on behalf of EWA in said jurisdictions.  INDEPENDENT MARKETER and/or any agent 
                          compensated by INDEPENDENT MARKETER shall not be permitted to market any products on behalf of EWA or any carrier in any state in which the agent is not licensed 
                          and appointed in accordance to federal, state and/or local law.
                        </p>
                        <br>
                        <b>
                          <li>SUB-MARKETERS</li> </b
                        >
                        <ol type="a">
                          <li>
                            INDEPENDENT MARKETER shall have the right to utilize sub-marketers for the purpose of marketing product on behalf of EWA.
                          </li>
                          <li>
                            Any sub-marketer shall be held to the same standards and obligations that INDEPENDENT MARKETER has agreed to under this Agreement.
                          </li>
                          <li>
                            Commissions paid to or due sub-marketer shall be the sole responsibility of INDEPENDENT MARKETER.  If the carrier returns or charges back a premium or 
                            any portion thereof for any reason, the commissions on that premium shall be the sole responsibility of INDEPENDENT MARKETER and its sub-marketer.
                          </li>
                        </ol>
                        <br/>
                        <b>
                          <li>COMPENSATION</li> </b
                        >
                        <ol type="a">
                          <li>
                            INDEPENDENT MARKETER shall be paid commissions in accordance with the schedules set forth by EWA, 
                            attached hereto and incorporated by reference as Schedule A, including any addendums attached and incorporated hereto.
                          </li>
                          <li>
                            The commissions shall be based on the Commission Schedule(s) attached hereto and incorporated by reference 
                            herein to be credited to INDEPENDENT MARKETER on the date the premium is received and credited by the carrier.  
                            EWA reserves the right to change commission schedules without prior notice based upon changes in commissions paid by any carrier to EWA
                            <ol class="sub-list">
                              <li>
                                Commissions on all business paid to by any carrier shall be paid to the INDEPENDENT MARKETER on or before five (5) workings days after EWA receives its commissions.
                              </li>
                              <li>
                                EWA may advance commissions to an INDEPENDENT MARKETER at its sole discretion based upon those commissions advanced to EWA  by the carrier.
                              </li>
                            </ol>
                          </li>
                          <li>
                            If the carrier returns or charges back a premium or any portion thereof for any reason, the commissions on that premium 
                            shall be deducted from INDEPENDENT MARKETER's commissions.  EWA may offset any debt or any percentage of such debt owed by 
                            INDEPENDENT MARKETER to EWA against any amount owed by EWA to the INDEPENDENT MARKETER.  The principals of the corporation 
                            shall personally guarantee repayment to EWA of any premium charged back by the carrier.
                          </li>
                          <li>
                            If EWA, in its discretion, finds it necessary to respond to any litigation or any dispute with any governmental 
                            Agency that arises based on the conduct of the INDEPENDENT MARKETER or any person acting on behalf of the INDEPENDENT 
                            MARKETER then INDEPENDENT MARKETER shall be responsible for one hundred percent (100%) of all attorneys fees and costs, 
                            as well as any fines assessed against EWA.  INDEPENDENT MARKETER shall be further responsible for one hundred percent (100%) 
                            of all costs and attorney fees relating to any appeal taken from assessment of said fines.  Said fines arising from such litigation 
                            or dispute with any governmental INDEPENDENT MARKETER may be offset by present commissions earned.  If said present commissions do not 
                            totally offset fines assessed against EWA due to INDEPENDENT MARKETER's conduct or the conduct of any person acting on behalf of INDEPENDENT MARKETER, 
                            then future commissions may be applied until such time as all applicable fines for which the INDEPENDENT MARKETER is responsible are paid in full.
                          </li>
                        </ol>                      
                        <br>
                        <b>
                          <li>TERMINATION</li> </b
                        >
                        <ol type="a">
                          <li>
                            This Agreement may be terminated at will by either party herein, by written notice of the election to terminate, delivered personally, 
                            via facsimile or mailed by first-class mail, postage pre-paid, to the other party at the last known address as stated herein above. 
                             Said termination shall be effective immediately on the date on which such termination notice is sent or mailed to the other party.
                          </li>
                          <li>
                            EWA may terminate this Agreement, for cause, at any time and without notice, if INDEPENDENT MARKETER commits acts including, but not limited to:
                            <ol>
                              <li>
                                Failing to comply with any of the provisions of this Agreement;
                              </li>
                              <li>
                                Acts contrary to the law, or INDEPENDENT MARKETER commits a felony, act of fraud or willful misconduct in any matter related to this agreement;
                              </li>
                              <li>
                                Inducing or attempting to induce any employees, independent marketers, licensed agents, or any representatives of EWA to discontinue their association with EWA;
                              </li>
                              <li>
                                Inducing or attempting to induce any party to relinquish a policy or contract with EWA.
                              </li>
                              <li>
                                Generating regulatory complaints or investigations resulting in business interruption, cease and desist orders, legal action or otherwise, in any way, damaging the or goodwill of EWA or the reputation of EWA products;
                              </li>
                              <li>
                                Failing to comply with any of the Carriers guidelines, EWA’s rules, regulations or company policies or federal, state or local laws governing the industry or applicable to the terms of this Agreement;
                              </li>
                              <li>
                                Any violation relating to the replication of EWA’s website, proprietary software or systems;
                              </li>
                            </ol>
                          </li>
                          <li>
                           This Agreement shall automatically terminate, immediately and without prior notice, upon one or more of the following: (i) the dissolution of the partnership, if the INDEPENDENT MARKETER is a partnership, (ii) the dissolution of the corporation, if the INDEPENDENT MARKETER is a corporation, (iii) the INDEPENDENT MARKETER's license or the license of INDEPENDENT MARKETERS' principal agent is suspended or revoked by any regulatory authority, or (iv) the INDEPENDENT MARKETER invokes any form of federal bankruptcy jurisdiction or state jurisdiction for receivership, liquidation, or conservatorship.
                          </li>
                          <li>
                            Upon termination of this Agreement, the INDEPENDENT MARKETER shall immediately pay all sums due to EWAand shall deliver to EWA all rate books; letters, records, supplies and any such related items connected with EWA’s business.
                          </li>
                          <li>
                            If this Agreement is terminated for any reason, any monies that are due to the INDEPENDENT MARKETER shall be held by EWA,for up to 90 days.  During that period, EWA shall perform a final accounting and reconciliation, including chargebacks, and issue commissions due to INDEPENDENT MARKETER following such review.
                          </li>
                          <li>
                            Upon termination of employment by EWA with cause or by Contractor with or without cause, EWA will review licensure and appointment payments made on behalf of Contractor.  The Contractor agrees that EWA shall recoup all monies paid by EWA towards licensure and appointment of the Contractor in any state during the last twelve (12) months of Contractor’s period of employment.  Said monies may be recouped by withholding funds from monies paid to the Contractor in his or her last paycheck above minimum wage.  Further funds may be withheld from any future payment made to the Contractor for any purpose, until such time as all monies paid by EWA under This Agreement have been repaid.  The Contractor shall be responsible for repayment of the entire amount, even if said amount exceeds Contractor’s final paycheck above minimum wage and/or other payment made to the Contractor for any purpose.
                          </li>
                          <li>
                            EWA may, in its sole discretion, suspend or terminate this Agreement, temporarily or permanently revoking the INDEPENDENT MARKETER's authority to represent it, during any reasonable period of investigation, to determine whether conditions exist to warrant termination for cause.  The INDEPENDENT MARKETER shall reasonably cooperate with EWA in any such investigation, to include producing necessary documents and providing any other information requested.
                          </li>
                          <li>
                            EWA may, in its sole discretion, suspend or terminate this Agreement for violation by the INDEPENDENT MARKETER of any provision set forth in Paragraph Vl.B.1-6 as set forth herein above.
                          </li>
                        </ol>
                        <br>
                        <b>
                          <li>SUSPENSION</li>
                        </b>
                        <p>
                          In the event INDEPENDENT MARKETER becomes the subject of a regulatory or other legal investigation or allegation, EWA has the right to suspend INDEPENDENT MARKETER until such time as the issues are resolved or a final determination rendered.  Suspension can include suspension of INDEPENDENT MARKETER’S right to sell in one or more states during the suspension period.
                        </p>
                        <br />
                        <b>
                          <li>ASSIGNMENT</li>
                        </b>
                        <p>
                          The INDEPENDENT MARKETER may not assign this Agreement without the prior written consent of EWA, which may not be unreasonably withheld.  EWA may assign this Agreement at will, without the consent of the INDEPENDENT MARKETER.
                        </p>
                        <br />
                        <b>
                          <li>INDEBTEDNESS</li> 
                        </b>
                        <p>
                          Any debt the INDEPENDENT MARKETER owes EWA shall be payable at EWA’s home office.  EWA may offset against any claim by the INDEPENDENT MARKETER for compensation hereunder any debt owed to EWA by INDEPENDENT MARKETER then due or that may thereafter become due from the INDEPENDENT MARKETER, whether arising hereunder or otherwise, and such debt shall be a first lien on any such compensation.  EWA shall have the right, at its discretion, to charge interest on any indebtedness due it by the INDEPENDENT MARKETER.  Interest shall be calculated from the time said indebtedness was incurred, at per annum rate equal to the prime commercial rate as charged by Bank of America (or its successor) from time to time; provided, however, that such rate shall not exceed the rate permitted to be charged by law.  EWA shall have the right to determine to which indebtedness any payment made by the INDEPENDENT MARKETER, whether offset or otherwise, shall be applied.  The INDEPENDENT MARKETER shall pay all costs and expenses, including collection fees and/or reasonable attorney’s fees incurred by EWA in collection of indebtedness hereunder.
                        </p>
                        <br />
                        <b>
                          <li>GENERAL</li> 
                        </b>
                        <ol type="a">
                          <li>
                            The Parties shall use their best efforts to keep in full force and affect all policies issued.  The Parties shall so conduct all actions as not to adversely affect the business, good standing, goodwill or reputation of EWA and INDEPENDENT MARKETER.
                          </li>
                          <li>
                            The Parties agree to maintain all records and books as required by carrier guidelines, EWA policies and procedures, federal, state or local law or regulation.
                          </li>
                          <li>
                            The Parties agree to comply with all carrier guidelines, EWA policies and procedures, federal, state and/or local laws and/or regulations, and with all published bulletins, field letters, or any other written communications from EWAnow in force and such as may be hereafter accepted.
                          </li>
                          <li>
                            The INDEPENDENT MARKETER shall have no power or authority other than herein expressly granted and no other or greater powers shall be implied from the grant or denial of powers expressly mentioned herein.
                          </li>
                          <li>
                            The INDEPENDENT MARKETER shall have no claim and/or compensation for having introduced or brought to the attention of EWA any business opportunity, except under separate written agreement with EWA to pay such compensation, signed on behalf of by any principal and/or member of the board of directors of EWA.
                          </li>
                          <li>
                            The forbearance or neglect of EWA to insist upon strict compliance by the INDEPENDENT MARKETER with any of the provisions of this Agreement whether continuing or not, shall not be construed as a waiver of any of EWAs rights or privileges hereunder.  No waiver of any right or privilege arising from any default or failure of performance by the INDEPENDENT MARKETERT shall affect EWA's rights or privileges in the event of a further default or failure of performance.
                          </li>
                          <li>
                            The Parties shall not reproduce any software or other material provided by either party to the other.  All such material shall be used exclusively in the performance of this Agreement and may not be used or distributed for any other purpose.
                          </li>
                          <li>
                            INDEPENDENT MARKETER shall notify EWA immediately in writing if the any licensed agent, employee, associate, officer, director, principal or other representative of its business has been convicted of any felony, misdemeanor or any other lesser crime involving moral turpitude, including, but not limited to: theft, forgery, fraud, or any other crime involving dishonesty.
                          </li>
                          <li>
                            The INDEPENDENT MARKETER agrees to submit to any and all training determined to be necessary by EWA, including, but not limited to, initial training upon execution of any INDEPENDENT MARKETER AGREEMENT and re-training upon any determination by EWA that said INDEPENDENT MARKETER is in any way misrepresenting any of product sold on behalf of EWA or is selling any product sold by EWA in such a way as to damage EWA's gEra 's discretion.  EWA reserves the right to audit at any time and without prior notice, all sales tactics, all scripts and /or marketing materials, including but not limited to: marketing procedures and verification procedures utilized by INDEPENDENT MARKETER or any agent, representative, employee or any other person acting on behalf of the INDEPENDENT MARKETER and on behalf of any product offered by EWA.
                          </li>
                        </ol>
                        <br />
                        
                        <b>
                          <li>INDEMNIFICATION</li> </b
                        ><br />
                        <ol type="a">
                          <li>
                            INDEPENDENT MARKETER shall indemnify, defend, and hold harmless EWA, its affiliates, officers, directors, members, shareholders, insurers, employees and parent corporations, from and against any and all third-party claims, suits, liabilities, losses, and damages of whatever nature, (including all reasonable costs, expenses and attorneys' fees) arising from or in connection with (a) any intentional or unintentional misrepresentation by INDEPENDENT MARKETER regarding an EWA product/service that is the cause of such claim, (b) unauthorized transaction or (c) INDEPENDENT MARKETER's breach of the terms and conditions of this executed INDEPENDENT MARKETER Agreement, including prohibited marketing activities.
                          </li>
                        </ol>
                        <br />
                        <b>
                          <li>CONFIDENTIAL INFORMATION</li> </b
                        ><br />
                        <ol type="a">
                          <li>
                            Confidential Information includes all individually identifiable health information and other information about a person that:
                            <ol class="sub-list">
                              <li>
                                a person provides to obtain coverage; or
                              </li>
                              <li>
                                results from a client transaction; or
                              </li>
                              <li>
                                is otherwise obtained in connection with providing coverage, such as: identities, names, addresses and ages of policyholders; types of policies; amounts of rates, policy renewals dates; client listings, claim information; any policyholder information subject to any privacy law; and information identified as confidential.
                              </li>
                            </ol>
                          </li>
                          <li>
                            Confidential Information also includes the following:
                            <ol class="sub-list">
                              <li>
                                any business plan or business plans and/or process or processes utilized by EWA;
                              </li>
                              <li>
                                any customer and/or INDEPENDENT MARKETER lists utilized by EWA;
                              </li>
                              <li>
                                any product or products developed and/or sold by EWA;
                              </li>
                              <li>
                                any intellectual property utilized by EWA, including but not limited to copyrightable material, computer software, trademark related materials such as names, logos, color schemes, trade dress related materials, or patentable processes or materials.
                              </li>
                            </ol>
                          </li>
                          <li>
                            The INDEPENDENT MARKETER agrees that it shall:
                            <ol class="sub-list">
                              <li>
                                use EWA's Confidential Information only for the purpose for which it was disclosed and only to carry out the provisions of this Agreement;
                              </li>
                              <li>
                                not disclose EWA's Confidential Information to third parties unless necessary to meet its obligations under this Agreement, and then only to a third party similarly bound by the same privacy standards and agreements;
                              </li>
                              <li>
                                continue to treat EWA's Confidential Information in this manner even after termination of this Agreement; and
                              </li>
                              <li>
                                comply with all applicable privacy laws and regulations; and
                              </li>
                              <li>
                                comply with all insurance carriers' and the Party's policies and procedures regarding Confidential Information.
                              </li>
                            </ol>
                          </li>
                          <li>
                            INDEPENDENT MARKETER further agrees that EWA has furnished and is furnishing certain Confidential Information and Trade Secret Information to the INDEPENDENT MARKETER.  The term "Confidential Information" shall mean all information that EWA has furnished to the INDEPENDENT MARKETER.  The term "Trade Secret Information" shall mean all information owned or claimed to be owned by EWA, including but not limited to customer information, marketing information, formula, pattern, compilation, program, device, method, technique, or process that:
                            <ol class="sub-list">
                              <li>
                                derives independent economic value, actual or potential, from not being generally known to, and not being readily ascertainable by proper means by, other persons who can obtain economic value from its disclosure or use; and
                              </li>
                              <li>
                                is the subject of efforts that are reasonable under the circumstances to maintain its secrecy.  EWA’s Confidential Information and Trade Secret Information includes a description of the unique manner in which EWA performs its business operations and may also consist of and is not limited to, computer software, customer lists and agreements, provider lists and agreements, INDEPENDENT MARKETER and/or agency lists, employee lists, marketing strategies, access and referral agreements, internal payment processing and administrative procedures and demographic and other data related to the business of EWA.
                              </li>
                            </ol>
                          </li>
                          <br /><br />
                          <li>
                            INDEPENDENT MARKETER agrees that it will keep the Confidential Information and Trade Secret Information private and that the Confidential Information and Trade Secret Information will not, without the prior written consent of EWA, be disclosed by INDEPENDENT MARKETER or by its officers, directors, shareholders, partners, employees, affiliates, agents, investors, consultants, contractors or representatives (collectively "Representatives") in any manner whatsoever, in whole or in part, and shall not be used by the Parties or by their Representatives.  Moreover, INDEPENDENT MARKETER agrees to transmit the Confidential Information and Trade Secret Information only to such of its representatives who in writing, agree to be bound by the terms hereof as if a party hereto.  In any event, the INDEPENDENT MARKETER shall be fully liable for any breach of this Agreement by it or its Representatives and agrees, at its sole expense, to take all reasonable measures (including, but not limited to, court proceedings) to restrain its representatives from prohibited or unauthorized disclosure or use of the Confidential Information and Trade Secret Information originating from EWA
                          </li>
                          <li>
                            The INDEPENDENT MARKETER agrees that neither it nor its Representatives shall use the Confidential Information and Trade Secret Information provided by EWA to develop any product or service, which is the same as or similar to any product or service sold on behalf of EWA.  The INDEPENDENT MARKETER understands and agrees that any payment mechanism, any version of the computer network and/or any lead management system utilized by EWA is unique and agrees that it will not develop for its own use, nor divulge in any manner whatsoever the methods EWA utilizes to anyone other than the INDEPENDENT MARKETER.  INDEPENDENT MARKETER understands and agrees that the relationships between EWA, its customers, agencies and/or INDEPENDENT MARKETERs are unique, and agrees to not contact those customers, agencies and/or INDEPENDENT MARKETERs for commercial purposes other than on behalf of EWA for a period of twenty-four months upon termination of the this Agreement between the parties.  INDEPENDENT MARKETER further agrees that if INDEPENDENT MARKETER violates this Paragraph, then EWA has no remedy at law and may pursue its equitable remedies including specific performance, injunctions and restraining orders in any court of competent jurisdiction.
                          </li>
                        </ol>
                        <br />
                        <b>
                          <li>NON-SOLICITATION</li> </b
                        >
                        <ol type="a">
                          <li>
                            INDEPENDENT MARKETER shall not induce or attempt to induce any customers, employees, licensed INDEPENDENT MARKETERs, representatives, agencies of record and/or any INDEPENDENT MARKETERs of record to discontinue their association with EWA.
                          </li>
                          <li>
                            EWA shall not induce or attempt to induce any customers, employees, INDEPENDENT MARKETERs and/or representatives, agencies of record and/or INDEPENDENT MARKETERS of record to discontinue their association with INDEPENDENT MARKETER.
                          </li>
                        </ol>
                        <br />
                        <b>
                        <li>APPROVAL OF SOLICITATIONS</li>
                        </b>
                        <p>
                          The INDEPENDENT MARKETER shall not publish or disseminate any type of solicitations, scripts, forms, advertisements or material in any media concerning EWA or any of its contracted companies without the prior written consent of both the Carrier and EWA  INDEPENDENT MARKETER shall in no way replicate EWA's website without EWA's express permission
                        </p>
                        <br />
                        <b>
                          <li>JURISDICTION AND VENUE.</li>
                        </b>
                        <ol type="a">
                          <li>
                            This Agreement and the relationship of the parties shall be governed by the laws of the State of Florida applicable to agreements executed and performed within the State of Florida and without giving effect to any statutes or rules relating to the conflict of laws.  The Parties agree and acknowledge that in the negotiating and executing of this agreement and in the performance of this Agreement, they are purposefully availing themselves of the benefits and laws of the State of Florida as to any dispute arising out of or related to the inception or performance of this Agreement.  The Parties hereby waive their right to contest the exercise of personal jurisdiction over them in the State of Florida  The Parties further agree that exclusive venue shall lie in Florida County, Florida. EWA may pursue its equitable remedies, including specific performance, injunctions and restraining orders in any court of competent jurisdiction.
                          </li>
                          <li>
                            Both parties shall use best efforts to resolve disputes in an amicable manner for a period of ten (10) days.  The Parties agree that any dispute arising out of or related in any way to the solicitation, negotiation, inception or performance of this Agreement (whether the dispute is couched in terms of contractual, statutory, or common law grounds) shall be exclusively resolved and construed in accordance with Commercial Arbitration Rules of the American Arbitration Association pursuant to the laws of the State of Florida governing arbitration.  For any disputes not resolved amicably, venue shall be in Broward County, Florida and any judgment upon the award rendered by the arbitrator(s) may be entered in any court having competent jurisdiction thereof.
                          </li>
                        </ol>
                        <br />
                        <b>
                        <li>WAIVER / MODIFICATION</li>
                        </b>
                        <p>
                          No waiver or modification of this Agreement shall be effective unless in writing, expressing by its terms an intention to modify this Agreement, and signed by duly authorized representatives of all parties hereto.
                        </p>
                        <br />
                        <b>
                        <li>SUPERVISION / RESPONSIBILITY </li>
                        </b>
                        <p>
                          The Parties shall be held harmless from any losses or expenses on the account of the acts or omissions of the other party or any employee, associate, officer, director, principal or any other representative.
                        </p>
                        <br />
  
                        <b>
                        <li>COMPLAINTS</li>
                        </b>
                        <p>
                          The INDEPENDENT MARKETER shall document and report within twenty four (24) hours of occurrence regulatory complaints relating to EWA and shall provide any response thereto.  The Parties agree to fully cooperate with each other in the investigation and resolution of such complaints.
                        </p>
                        <br />
                        <b>
                        <li>SEVERABILITY</li>
                        </b>
                        <p>
                          In the event that any paragraph, subparagraph or provision of this Agreement shall be determined to be contrary to governing law or otherwise unenforceable, all remaining portions of this Agreement shall be enforced to the maximum extent permitted by law.
                        </p>
                        <br /><br />
                        <b>
                        <li>ENTlRE AGREEMENT</li>
                        </b>
                        <p>
                          This Agreement and the Schedules, Attachments or Addendums thereof, represents the complete agreement of the parties concerning the subject matter hereof, and all prior or contemporaneous negotiations, promises or agreements are merged herewith.
                        </p>
                        <br />
  
                        <b>
                        <li>BINDING EFFECT</li>
                        </b>
                        <p>
                          This Agreement shall inure to the benefit of and be binding upon the parties hereto, their successors and assigns.
                        </p>
                        <br />
  
                        <b>
                        <li>RELATIONSHIP OF PARTIES</li>
                        </b>
                        <p>
                          Nothing contained herein shall be construed to create the relationship of employer and employee between the parties.  The INDEPENDENT MARKETER is an independent contractor.
                        </p>
                        <br />
  
                        <b>
                        <li>CAPTIONS</li>
                        </b>
                        <p>
                          The captions and headings of paragraphs and subparagraphs are inserted for convenience of reference only and are not a part hereof and shall not affect the construction or interpretation of any provision herein this Agreement.
                        </p>
                        <br />
  
                        <b>
                        <li>COUNTERPARTS</li>
                        </b>
                        <p>
                          This Agreement may be executed simultaneously in several counterparts, each of which shall be original, and all of which shall constitute but one and the same instrument.  A facsimile signature shall be considered the same as an original signature.
                        </p>
                        <br />
  
                        <b>
                        <li>NUMBER AND GENDER</li>
                        </b>
                        <p>
                          Whenever required for proper interpretation of this Agreement, the singular number shall include the plural, the plural the singular, and the use of any gender shall include all genders.
                        </p>
                        <br />
  
                        <b>
                        <li>CONSTRUANCE</li>
                        </b>
                        <p>
                          This Agreement has been negotiated at arm's length by the parties and shall not be construed against the drafting party.
                        </p>
                        <br />
                        <p class="initial">
                          NOW, THEREFORE, IN WITNESS WHEREOF, the parties hereto have executed this agreement this 
                          <strong>{{ formattedDate.day }}</strong>
                          day of <strong>{{ formattedDate.month }}</strong>
                          , <strong>{{ formattedDate.year }}</strong>
                        </p>
                      </ol>
                  <br />
                  <p class="approve-heading"><b>EWA Membership</b></p>
                  <br /><br /><br />
                  <p class="approval"></p>
                  <span class="sign_container">
                      <span class="signature_block">
                      <p class="left">
                          <span class="sign">
                          <!-- <canvas id="signature_pad" class="signature_pad" width="336" height="151"></canvas> -->
                          <img width="336" height="151" :src="agreement_signature" />
                          <br />Signature of Independent Marketer</span
                          ><br /><br />
                          <span
                          ><input
                              type="text"
                              name="rep-name"
                              v-model="agreement_name"
                              required
                          /><br />Print Name</span
                          >
                      </p>
                      <p class="right">
                          <span class="sign">
                          <img width="336" height="151" :src="rep_signature" />
                          <!-- <canvas id="signature_pad1" class="signature_pad1" width="336" height="151"></canvas> -->
                          <br />Association Officer Signature</span
                          ><br /><br />
                          <span
                          ><input
                              type="text"
                              name="officer-name"
                              value="Elizabeth L. Deluca, President"
                              readonly
                              required
                          /><br />Print Name and Title</span
                          >
                      </p>
                      <div class="html2pdf__page-break" />
                      </span>
                  </span>
                  </section>
                  
                  <section class="pdf-item">
                  <div class="ewa-form" style="width: 100%">
                      <h2>EWA Membership</h2>
                      <p><b>Addendum to Contract</b></p>
                      <form class="contract-form" id="contract-form">
                      <ul>
                          <li><strong>Representative ID #:</strong>{{ agentid }}</li>
                          <li>
                          <strong>First Name:</strong>{{ first_name }}
                          <strong>MI:</strong>{{ middle }}<strong>Last Name:</strong
                          >{{ last_name }}
                          </li>
                          <li>
                          <span v-if="date_of_birth"><strong >Date of Birth:</strong>{{ date_of_birth }}</span>
                          <span v-if="tin_number"><strong >Company Tax ID #:</strong>{{ tin_number}}</span>
                          <span v-else-if="social_security"><strong >Social Security #:</strong>{{ social_security}}</span>
                          </li>
                          <li><strong>Street Address:</strong>{{ street_address1 }}</li>
                          <li>
                          <strong>City:</strong>{{ city }} <strong>State:</strong
                          >{{ state }} <strong>Zip Code:</strong>{{ zip }}
                          </li>
                          <li>
                          <strong>Telephone#:</strong>{{ telephone }}
                          <strong>fax#:</strong>{{ fax }} <strong>Cellphone#:</strong
                          >{{ cell_phone }}
                          </li>
                          <li>
                          <strong>Business or Corporate Name:</strong
                          >{{ business_name }}
                          </li>
                          <li>
                          <pre>
                                  Pay to (check one):  Individual:      <input type="radio" name="radio" :checked="pay_to == 'individual' ? true :false"
                                                                              value="individual">  Business:   <input
                                  type="radio" name="radio" :checked="pay_to == 'business' ? true : false" value="business">
                              </pre>
                          </li>
                          <li><strong>Paid by:</strong>ACH Online</li>
                          <li>
                          <strong>ACH Information (if applicable) Bank Name:</strong
                          >{{ bank_name }}
                          </li>
                          <li>
                          <pre>
                                  <strong>Routing #:</strong>{{routing_number}}
                                  <strong>Account #:</strong>{{account_number}}
                              </pre>
                          </li>
                          <li>
                          <pre>
                                  <strong>Account Name:</strong>{{account_name}}
                                  <strong>Email Address:</strong>{{email}}
                              </pre>
                          </li>
                      </ul>
                      </form>
                  </div>
                  <!-- <div class="fee-schedule">
                    <table id="fee-schedule" style="width: 100%;">
                      <tr v-if="!computeContractType">
                          <th>Association Membership Fees </th>
                          <th>
                            <b>
                              <p>Monthly Commission Level*</p>
                            </b>
                            <p>(Paid Monthly and in arrears)
                            </p>
                          </th>                                                 
                      </tr>
                      <tr v-for="(value, index) in items" :key="index">
                          <td>
                            <strong>{{ value.plan_name || capitalizeFirstLetter(value.configuration_name) }}</strong>
                          </td>
                          <td v-if="value.configuration && value.configuration.hasOwnProperty('Lifeline Bronze') && value.configuration.hasOwnProperty('Lifeline Silver, Gold & Platinum')">
                        <table style="width: 100%;">
                          <thead>
                            <tr v-for="(configValue, configKey) in value.configuration" :key="'header-' + configKey">
                              <th >
                                <strong>{{ configKey }}</strong>
                              </th>
                              <th >
                                <table style="width: 100%;">
                                  <thead>
                                    <tr v-for="(configVal, configKey , index) in configValue" :key="'tier-header-' + configKey" >
                                      <template v-if="index === 0">
                                      <th>
                                        <strong>Tier</strong>
                                      </th>
                                      <th v-for="(tierVall, tierrKey) in configVal" :key="'tier-header-' + tierrKey">
                                        <strong >
                                                {{ tierrKey }}
                                              </strong>
                                      </th>
                                    </template>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr v-for="(configVal, configKey) in configValue" :key="'data-' + configKey">
                                      <td > 
                                        <strong >{{ configKey }}</strong>
                                      </td>
                                      <td v-for="(conval, conKey) in configVal" :key="'data-' + conKey">
                                        <strong >{{ conval }}</strong>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </th>
                            </tr>
                          </thead>
                        </table>
                      </td>
                      <td v-else-if="value.configuration ">
                        <table style="width: 100%;">
                          <thead>
                            <tr>
                              <th v-for="(configValue, configKey) in value.configuration" :key="'header-' + configKey">
                                <strong>{{ configKey }}</strong>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td v-for="(configValue, configKey) in value.configuration" :key="'data-' + configKey">
                                <strong v-if="configValue == '0.0%'">{{'Paid By Up-Line'}}</strong>  
                                <strong v-else>{{ configValue }}</strong>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                          <td v-else>
                            <strong v-if="value.commission == '0.00'">{{'Paid By Up-Line'}}</strong>                          
                            <strong v-else-if="value.commission == '' || value.commission == null">{{'N/A'}}</strong>                          
                            <strong v-else>{{value.commission}}</strong>                          
                          </td>
                      </tr>
                  </table>
                  </div> -->
                  <div class="authorization">
                      <div class="representative">
                      <ul>
                          <li>
                          Representative Independent Marketer<br />
                          <span class="sign_two">
                              <img width="100%" height="151" :src="agreement_signature" />
                              <!-- <canvas id="signature_pad2" class="signature_pad2" width="336" height="151"></canvas> -->
                          </span>
                          </li>
  
                          <li>
                          <label class="label">Print Name</label>
                          <br />
                          <input
                              type="text"
                              name="representative-name"
                              v-model="agreement_name"
                          />
                          </li>
  
                          <li>
                          <label class="label">Date Signed</label>
                          <br /><input
                              type="text"
                              name="rep-signed-date"
                              v-model="aggrement_eff_date"
                          />
                          </li>
                      </ul>
                      </div>
                      <div class="member">
                      <ul>
                          <li class="EWA-member">
                          EWA Membership<br />
                          <span class="sign_two"> </span>
                          <img width="100%" height="151" :src="rep_signature1" />
                          <!-- <canvas id="signature_pad3" class="signature_pad3" width="336" height="151"></canvas> -->
                          </li>
                          <li>
                          <label class="label">Print Name</label>
                          <br />
                          <input
                              type="text"
                              name="member-name"
                              value="Elizabeth L. Deluca, President"
                              readonly
                              required
                          />
                          </li>
  
                          <li>
                          <label class="label">Date Signed</label>
                          <br /><input
                              type="text"
                              name="mem-signed-date"
                              v-model="aggrement_eff_date"
                              required
                          />
                          </li>
                      </ul>
                      </div>
                      <br/><br/>
                      <!-- <div class="html2pdf__page-break" /> -->
                  </div>
                <div class="html2pdf__page-break" />
                  <br/><br/>
                  <div class="master_commission_container">
                    <br/><br/>
                      <h3>MASTER COMMISSION SHEET</h3>
                    <div class="master-commission table-responsive" v-if="comItems.length > 0">
                      <table id="commission-sheet" style="width: 100%;">
                        <tr>
                            <th>Supplemental and Lifestyle Plans </th>
                            <th>TIER</th>
                            <th v-if="1 <= level">Level 1</th>                         
                            <th v-if="2 <= level">Level 2</th>                         
                            <th v-if="3 <= level">Level 3</th>                         
                            <th v-if="4 <= level">Level 4</th>                         
                            <th v-if="5 <= level">Level 5</th>                         
                            <th v-if="6 <= level">Level 6</th>                         
                            <th v-if="7 <= level">Level 7</th>                         
                            <th v-if="8 <= level">Level 8</th>                         
                            <th v-if="9 <= level">Level 9</th>                     
                        </tr>
                        <!-- <tr> -->
                        <tr v-for="(item,index) in comItems" :key="index">
                            <td>{{ item.plan_name }}</td>
                            <td>{{ item.tier_name }}</td>
                            <td v-if="item.l1">{{ item.l1 }}</td>
                            <td v-if="item.l2">{{ item.l2 }}</td>
                            <td v-if="item.l3">{{ item.l3 }}</td>
                            <td v-if="item.l4">{{ item.l4 }}</td>
                            <td v-if="item.l5">{{ item.l5 }}</td>
                            <td v-if="item.l6">{{ item.l6 }}</td>
                            <td v-if="item.l7">{{ item.l7 }}</td>
                            <td v-if="item.l8">{{ item.l8 }}</td>
                            <td v-if="item.l9">{{ item.l9 }}</td>
                        </tr>
                    </table>
                </div>
                <div v-else class="master_commission_container">
              <table  id="commission-sheet" style="width: 100%">
                  <tr v-for="(value, index) in responseContract" :key="index">
                      <td>
                        <strong>{{ value.plan_name || capitalizeFirstLetter(value.contract_item_display_name) }}</strong>
                      </td>
                      <td v-if="value.configuration && value.configuration.hasOwnProperty('Lifeline Bronze') && value.configuration.hasOwnProperty('Lifeline Silver, Gold & Platinum')">
                        <table style="width: 100%;">
                          <thead>
                            <tr v-for="(configValue, configKey) in value.configuration" :key="'header-' + configKey">
                              <th >
                                <strong>{{ configKey }}</strong>
                              </th>
                              <th >
                                <table style="width: 100%;">
                                  <thead>
                                    <tr v-for="(configVal, configKey , index) in configValue" :key="'tier-header-' + configKey" >
                                      <template v-if="index === 0">
                                      <th>
                                        <strong>Tier</strong>
                                      </th>
                                      <th v-for="(tierVall, tierrKey) in configVal" :key="'tier-header-' + tierrKey">
                                        <strong >
                                                {{ tierrKey }}
                                              </strong>
                                      </th>
                                    </template>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr v-for="(configVal, configKey) in configValue" :key="'data-' + configKey">
                                      <td > 
                                        <strong >{{ configKey }}</strong>
                                      </td>
                                      <td v-for="(conval, conKey) in configVal" :key="'data-' + conKey">
                                        <strong >{{ conval }}</strong>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </th>
                            </tr>
                          </thead>
                        </table>
                      </td>
                      <td v-else-if="value.configuration ">
                        <table style="width: 100%;">
                          <thead>
                            <tr>
                              <th v-for="(configValue, configKey) in value.configuration" :key="'header-' + configKey">
                                <strong>{{ configKey }}</strong>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td v-for="(configValue, configKey) in value.configuration" :key="'data-' + configKey">
                                <strong v-if="configValue == '0.0%'">{{'Paid By Up-Line'}}</strong>  
                                <strong v-else>{{ configValue }}</strong>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                      <td v-else>
                        <strong v-if="value.commission == '0.00'">{{'Paid By Up-Line'}}</strong>                          
                        <strong v-else-if="value.commission == '' || value.commission == null">{{'N/A'}}</strong>                          
                        <strong v-else>{{value.commission}}</strong>                          
                      </td>
                  </tr>
              </table>
            </div>
                  </div>
                  

  
                  </section>
              </section>
  
              </vue-html2pdf>
          </div>
        </div>
      </div>
  </div>
  </template>
  <script>
  import axios from "axios";
  import VueHtml2pdf from "vue-html2pdf";
  import moment from 'moment';
  import * as  Helper from '../../utlis/helper.js';
  export default {
    name: "TestPdf",
    components: { VueHtml2pdf },
    data() {
      return {
        tempId: "",
        type: "",
        agentid: "",
        first_name: "",
        middle: "",
        last_name: "",
        date_of_birth: "",
        social_security: "",
        tin_number: "",
        business_name: "",
        email: "",
        telephone: "",
        cell_phone: "",
        fax: "",
        street_address1: "",
        street_address2: "",
        city: "",
        state: "",
        zip: "",
        pay_to: "",
        paid_by: "",
        routing_number: "",
        account_number: "",
        bank_name: "",
        account_name: "",
        account_type: "",
        contract_name: "",
        marketing_initial: "",
        agreement_check1: "",
        agreement_check2: "",
        agreement_signature: "",
        agreement_name: "",
        fee_level: "",
        fee_signature: "",
        rep_signature: "",
        rep_signature1: "",
        fee_name: "",
        fee_date: "",
        is_complete: "",
        complete_upto: "",
        status: "",
        screenshots: "",
        items: [],
        comItems: [],
        level: "",
        isGenerating: false,
        htmlToPdfOptions: {
          margin: 0.5,
          filename: "",
          enableLinks: true,
          image: {
            type: "jpeg",
            quality: 0.98,
          },
          html2canvas: {
            scale: 2,
            scrollX: 0,
            scrollY: 0,
            width: 1280,
            useCORS: true,
          },
          jsPDF: {
            unit: "in",
            format: "a4",
            orientation: "portrait",
          },
        },
        /**New Agreement Fields */
        marketer_address:'',
        aggrement_eff_date:'',
        agreement_day:'',
        agreement_month:'',
        contract_id:'',
        responseContract:[],
        contractType:['medical','dental','vision','dentalSolstice','visionSolstice','termLife','bundled','limitedMedical','accident','critical','hospital','lifeStyle','pet','guaranteedIssue','teleMedicine']
      };
    },
    mounted() {
      let app = this;
      app.tempId = app.$route.params.temp_id;
      app.type = app.$route.params.contract_type;
      app.contract_id = app.$route.params.contract_id;
      app.getContractData();
    },
    methods: {
      async beforeDownload({ html2pdf, options, pdfContent }) {
        let loader = this.$loading.show();
        var tempId = this.tempId;
        var agentId = this.agentid;
        var type = this.type;
        var level = this.fee_level;
        html2pdf()
          .set(options)
          .from(pdfContent)
          .toPdf()
          .output("datauristring")
          .then(function (pdfAsString) {
            console.log(tempId);
            console.log(agentId);
            console.log(type);
            console.log(level);
            
            let data = new FormData();
            data.append("pdf", pdfAsString);
            data.append("id", tempId);
            data.append("agent_id", agentId);
            data.append("type", type);
            axios
              .post(
                process.env.VUE_APP_CORENROLL_SYSTEM + "/save-contract-pdf",
                data
              )
              // axios.post('http://local.coreapi/api/v1/save-contract-pdf', data)
              .then(function (response) {
                if (response.data.status == "success") {
                  console.log("success");
                }
                window.location = "/contract-form/contract_message/success";
              })
              .catch(function (error) {
                console.log(error.response.data.data);
                if (error.response.data.status == "error") {
                  error.response.data.data.forEach(function (item) {});
                }
                window.location = '/contract-form/contract_message/error';
              })
              .finally(() => {
                loader.hide();
              });
          });
          
      },
      hasDownloaded(blob) {
        this.isGenerating = false;
      },
      generate() {
        let loader = this.$loading.show();
        // let app = this;
        this.isGenerating = true;
        this.htmlToPdfOptions.filename = `anclevel2.pdf`;
        this.$refs.html2Pdf.generatePdf();
        loader.hide();
      },
      getCommissionSheet() {
        let app = this;
        let loader = this.$loading.show();
        let url = process.env.VUE_APP_PURENROLL_SYSTEM + `/masterSheetForCategoryLevel/${btoa(app.contract_id)}`;
        axios
          .get(url)
                .then(function (response) {
                    console.log(response.data.data);

                    let newData = [];
                    let responseData = response.data;
                    if(responseData.contract_type){
                        app.responseContract = responseData.data
                    }
                    else{
                      if (responseData.data.length > 0 && responseData.data[0].plan_tier.length > 0) {
                            const firstConfig = responseData.data[0].plan_tier[0].configuartion;
                            app.level = Object.keys(firstConfig).length;
                        }
                        for (const [key, value] of Object.entries(responseData.data)) {
                        console.log(key);
                        if (value.is_same == '1') {
                            var obj = {
                                plan_name:value.plan_name,
                                tier_name:'All',
                            }
                            let config = value.plan_tier[0].configuartion;
                            Object.keys(config).forEach(key => {
                                if(config[key] == '0.00') {
                                    config[key] = 'Paid By Up-Line';
                                }
                                if(config[key] == '' || config[key] == null) {
                                    config[key] = 'N/A';
                                }
                                
                                obj[key] = config[key];
                            });
                            newData.push(obj);
                        } else {
                           let allTierOrder = {'IO':'','IS':'','IC':'','IF':''}
                            for (const [keyTier, valueTier] of Object.entries(value.plan_tier)) {
                                // debugger;
                                console.log(keyTier);                         
                                if(valueTier.tier == 'IO') {
                                     obj = {
                                        tier_name:valueTier.tier,
                                        plan_name:value.plan_name
                                    }
                                } else {
                                     obj = {
                                        tier_name:valueTier.tier,
                                        plan_name:''
                                    }
                                }
                                let config = valueTier.configuartion;

                                Object.keys(config).forEach(key => {
                                    if(config[key] == '0.00') {
                                        config[key] = 'Paid By Up-Line';
                                    }
                                    if(config[key] == '' || config[key] == null) {
                                        config[key] = 'N/A';
                                    }
                                    obj[key] = config[key];
                                });
                                allTierOrder[valueTier.tier] = obj
                               
                                console.log(newData);
                            }
                            for (const [keyOrder, valueOrder] of Object.entries(allTierOrder)) {
                                console.log(keyOrder);
                                 newData.push(valueOrder);
                            }
                        }
                    }
                    app.comItems = newData;
                    }
                    app.generate();
                })
                .catch(function (error) {
                  if(error.response.data.message == 'Form completed.'){
                      window.location = '/contract-form/contract_message/completed';
                  }else if(error.response.data.message == 'Agent does not exist.'){
                      window.location = '/contract-form/contract_message/not_found';
                  } else {
                    window.location = '/contract-form/contract_message/error';
                  }
                }) 
                .finally(() => {
          loader.hide();
        });
        },
      getFeeStructure() {
        const app = this;
        let url = '';
        let typeContract = '';
        let urlType = '';
        if (app.$route.params.contract_type === 'categoryL7') {
          typeContract = 'L7';
          urlType = 'Category';
        }
        else if(app.$route.params.contract_type === 'categoryL9'){
          typeContract = 'L9';
          urlType = 'Category';
        }
        else if(app.$route.params.contract_type === 'carrier'){
          urlType = 'Carrier'
        }
        if (app.$route.params.contract_type === 'categoryL7' || app.$route.params.contract_type === 'categoryL9' || app.$route.params.contract_type === 'carrier') {
          url = `${process.env.VUE_APP_PURENROLL_SYSTEM}/commissionConfigurationBy${urlType}/${app.fee_level}`;
          if(typeContract){
            url += `?level=${typeContract}`;
          }
          axios
            .get(url, {
              params: {
                type: app.type,
              },
            })
            .then((response) => {
              app.items = response.data.data;
            })
            .catch((error) => {
              const errorMsg = error.response?.data?.message || error.message;
              this.handleErrorRedirect(errorMsg);
            });
        } else {
          let typeSelect = '';
          if (app.type === 'patriotAncillary') {
          typeSelect = 'patriot';
        }
        else if(app.type === 'patriotPremier' || app.type === 'fegliPremier'){
          typeSelect = 'premier';
        } else if (app.type === 'fegliAncillary') {
          typeSelect = 'fegli';
        }

        let url = `${process.env.VUE_APP_COMM_PURENROLL_SYSTEM}/feeScheduleByLevel/${app.fee_level}`;
        if (typeSelect) {
          url += `?type=${typeSelect}`;
        }
        else{
            url +=`/${app.agentid}`;
        }
          axios
            .get(url)
            .then((response) => {
              app.items = response.data.data;
            })
            .catch((error) => {
              const errorMsg = error.response?.data?.message || error.message;
              this.handleErrorRedirect(errorMsg);
            });
        }
      },
      handleErrorRedirect(errorMsg) {
        if (errorMsg === 'Form Completed.') {
          window.location = '/contract-form/contract_message/completed';
        } else if (errorMsg === 'Agent does not exist.') {
          window.location = '/contract-form/contract_message/not_found';
        } else {
          console.error('An error occurred:', errorMsg);
        }
      },
      capitalizeFirstLetter(str) {
        return Helper.capitalizeFirstLetter(str);
      },
      getContractData() {
        let app = this;
        let loader = this.$loading.show();
        
        axios
          .get(process.env.VUE_APP_CORENROLL_SYSTEM + "/get-contract-data?", {
            params: {
              tempid: app.tempId,
            },
          })
          .then(function (response) {
            app.agentid = response.data.data.result.agentid;
            app.first_name = response.data.data.result.first_name;
            app.middle = response.data.data.result.middle;
            app.last_name = response.data.data.result.last_name;
            app.date_of_birth = response.data.data.result.date_of_birth;
            app.social_security = response.data.data.result.social_security;
            app.tin_number = response.data.data.result.tin_number;
            app.business_or_corporate_name =
              response.data.data.result.business_or_corporate_name;
            app.email = response.data.data.result.email;
            app.telephone = response.data.data.result.telephone;
            app.cell_phone = response.data.data.result.cell_phone;
            app.fax = response.data.data.result.fax;
            app.street_address1 = response.data.data.result.street_address1;
            app.street_address2 = response.data.data.result.street_address2;
            app.city = response.data.data.result.city;
            app.state = response.data.data.result.state;
            app.zip_code = response.data.data.result.zip_code;
            app.pay_to = response.data.data.result.pay_to;
            app.paid_by = response.data.data.result.paid_by;
            app.routing_number = response.data.data.result.routing_number;
            app.account_number = response.data.data.result.account_number;
            app.bank_name = response.data.data.result.bank_name;
            app.account_name = response.data.data.result.account_name;
            app.account_type = response.data.data.result.account_type;
            app.contract_name = response.data.data.result.contract_name;
            app.marketing_initial = response.data.data.result.marketing_initial;
            app.agreement_check1 = response.data.data.result.agreement_check1;
            app.agreement_check2 = response.data.data.result.agreement_check2;
            app.agreement_signature =
              response.data.data.result.agreement_signature;
            app.agreement_name = response.data.data.result.agreement_name;
            app.fee_level = response.data.data.result.fee_level;
            app.fee_signature = response.data.data.result.fee_signature;
            app.fee_name = response.data.data.result.fee_name;
            app.fee_date = response.data.data.result.fee_date;
            app.is_complete = response.data.data.result.is_complete;
            app.complete_upto = response.data.data.result.complete_upto;
            app.status = response.data.data.result.status;
            app.screenshots = response.data.data.result.screenshots;
            app.rep_signature = response.data.data.result.rep_signature;
            app.rep_signature1 = response.data.data.result.rep_signature;
            /*
              data for updated contract content
              marketing_initial , marketer_address ,aggrement_eff_date,
              aggrement_eff_date , agreement_day , agreement_month, contract_name
            */
           app.marketer_address = response.data.data.result.marketer_address;
           app.aggrement_eff_date = response.data.data.result.aggrement_eff_date;
           app.agreement_day = response.data.data.result.agreement_day;
           app.agreement_month = response.data.data.result.agreement_month;
            app.getCommissionSheet();

          })
          .catch(function (error) {
            console.log(error);
            console.log("failed to generate pdf");
          })
          .finally(() => {
          loader.hide();
        });
      },
    },
    computed:{
      formattedDate(){
        const year = new Date().getUTCFullYear();
        if(this.agreement_day){
          const agreementDate = `${year}-${this.agreement_month}-${this.agreement_day}`;
          const day =  moment(agreementDate).format('Do');
          const month = moment(agreementDate).format('MMM');
          return {day , month , year};
        }
        return {year};
      },
      computeContractType(){
        let app = this;
        return app.$route.params.contract_type === 'categoryL7' || app.$route.params.contract_type === 'categoryL9' || app.$route.params.contract_type === 'carrier';
      }
    }
  };
  </script>
  
  <style
          src="../../assets/css/contract_template/contractStyle.css"
          scoped
  ></style>