import { render, staticRenderFns } from "./PersonalInfo.vue?vue&type=template&id=31495df4&scoped=true"
import script from "./PersonalInfo.vue?vue&type=script&lang=js"
export * from "./PersonalInfo.vue?vue&type=script&lang=js"
import style0 from "../../assets/css/W9_form/personal_info.css?vue&type=style&index=0&id=31495df4&prod&scoped=true&lang=css&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "31495df4",
  null
  
)

export default component.exports