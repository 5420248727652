<template>
  <div>
    <button class="btn" @click="openModal">Payment History</button>
    <payment-history-modal></payment-history-modal>
  </div>
</template>

<script>
import PaymentHistoryModal from "./PaymentHistoryModal.vue";
export default {
  name: "PaymentHistoryDownload",
  components: {
    PaymentHistoryModal,
  },
  methods: {
    openModal() {
      this.$bvModal.show("payment-history-modal");
    },
  },
};
</script>

<style scoped>
.btn {
  background-color: var(--secondary-color);
  color: #fff;
  font-weight: 700;
  padding: 8px 15px !important;
  font-size: 12px;
  border-radius: 3px;
  border: 1px solid var(--secondary-color);
}

.btn:hover {
  color: #fff;
}
</style>
