var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "content_section contact_info"
  }, [_c('div', {
    staticClass: "rep_info"
  }, [_c('h2', [_vm._v("ContactInformation")]), _c('form', {
    attrs: {
      "action": "index.html",
      "method": "post"
    }
  }, [_vm._m(0), _vm._m(1), _vm._m(2), _vm._m(3), _vm._m(4), _c('div', {
    staticClass: "buttonbox"
  }, [_c('button', {
    staticClass: "previous",
    attrs: {
      "type": "button",
      "name": "button"
    },
    on: {
      "click": _vm.navigatePrevious
    }
  }, [_vm._v("Previous")]), _c('button', {
    staticClass: "save",
    attrs: {
      "type": "button",
      "name": "button"
    }
  }, [_vm._v("Save")]), _c('button', {
    staticClass: "next",
    attrs: {
      "type": "button",
      "name": "button"
    },
    on: {
      "click": _vm.saveAndContinue
    }
  }, [_vm._v("Next")])])])])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "input_box"
  }, [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v("Mobile Phone Number"), _c('span', {
    staticClass: "red"
  }, [_vm._v("*")])]), _c('div', {
    staticClass: "inputs"
  }, [_c('input', {
    attrs: {
      "type": "text",
      "name": "",
      "value": ""
    }
  }), _c('p', {
    staticClass: "error_message"
  }, [_vm._v("This is a required field")])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "input_box"
  }, [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v("Select Time Zone "), _c('span', {
    staticClass: "red"
  }, [_vm._v("*")])]), _c('div', {
    staticClass: "inputs"
  }, [_c('input', {
    attrs: {
      "type": "text",
      "name": "",
      "value": ""
    }
  }), _c('p', {
    staticClass: "error_message"
  }, [_vm._v("This is a required field")])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "input_box"
  }, [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v("Email "), _c('span', {
    staticClass: "red"
  }, [_vm._v("*")])]), _c('div', {
    staticClass: "inputs"
  }, [_c('input', {
    attrs: {
      "type": "text",
      "name": "",
      "value": ""
    }
  }), _c('p', {
    staticClass: "error_message"
  }, [_vm._v("This is a required field")])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "input_box"
  }, [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v("Phone No")]), _c('div', {
    staticClass: "inputs"
  }, [_c('input', {
    attrs: {
      "type": "text",
      "name": "",
      "value": ""
    }
  }), _c('p', {
    staticClass: "error_message"
  }, [_vm._v("This is a required field")])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "input_box"
  }, [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v("Fax")]), _c('div', {
    staticClass: "inputs"
  }, [_c('input', {
    attrs: {
      "type": "text",
      "name": "",
      "value": ""
    }
  }), _c('p', {
    staticClass: "error_message"
  }, [_vm._v("This is a required field")])])]);

}]

export { render, staticRenderFns }