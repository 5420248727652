<template>
  <div class="initial_page">
    <div class="main_page">
      <div class="welcome">
        <h1>Welcome</h1>
        <h2>To</h2>
        <h2>Member Dashboard</h2>
        <p>Experience the ease of access in one place.</p>
        <span class="d-block text-white mt-3">Having Problems ? <router-link to="/report-an-issue" class="text-white">Submit An Issue</router-link></span>
      </div>

      <div class="form">
        <div class="top-button">
          <button class="login_active">Log In</button>
          <router-link :to="{ name: 'SignUp' }"
            ><button class="sign_up_inactive">Register</button></router-link
          >
        </div>
        <div class="login_form">
          <h3>Log In</h3>
          <b-form-group label="Username" label-for="input-1">
            <b-form-input
              id="input-1"
              type="text"
              v-model="input.username"
              required
              placeholder="Enter your email"
            ></b-form-input>
            <div class="error_message" v-if="formErrorFlag == true">
              {{ formErrors["email"] }}
            </div>
          </b-form-group>

          <b-form-group id="input-group-2" label="Password" label-for="input-2">
            <b-form-input
              id="input-2"
              type="text"
              v-model="input.password"
              required
              placeholder="Enter your password"
            ></b-form-input>
            <eye-button
              id="input-2"
              hideOnDefault="true"
              type="text"
            ></eye-button>
            <div class="error_message" v-if="formErrorFlag == true">
              {{ formErrors["password"] }}
            </div>
          </b-form-group>
          <a class="">
            <button type="submit" @click="login()" :disabled="loginLoader">
              <b-spinner v-if="loginLoader == true" small></b-spinner>
              Log In
            </button>
          </a>
          <div class="error_message" v-if="formErrorFlag == true">
            {{ formErrors["error"] }}
          </div>
          <router-link :to="{ name: 'ForgotPassword' }">
            <p class="float-left">Forgot Password?</p>
          </router-link>
          <router-link :to="{ name: 'ForgotEmail' }">
            <p class="float-right">Forgot Email?</p>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import helper from "../resource/Helper";
import EyeButton from "../components/common/EyeButton";
import store from "../store/store";
import { uuid } from "vue-uuid";
export default {
  name: "Login",
  components: {
    EyeButton,
  },
  data() {
    return {
      input: {
        username: "",
        password: "",
      },
      loginLoader: false,
      formErrorFlag: false,
      formErrors: [],
      deviceID: "",
    };
  },
  mounted() {
    let uniqueDeviceId = store.state.deviceId
      ? store.state.deviceId
      : uuid.v1();
    this.deviceID = uniqueDeviceId;
    this.$store.commit("setDeviceId", uniqueDeviceId);

    //reset vuex data
    helper.resetRegisterMemberAuth();
    this.input.username = this.$route.params.tempEmail
      ? this.$route.params.tempEmail
      : "";
  },
  methods: {
    login() {
      let app = this;
      this.formErrors = [];
      let inputError = false;
       this.$store.commit("setAuthentication", false);
      if (
        this.input.username == "" ||
        helper.validateEmail(this.input.username) == false
      ) {
        this.formErrors["email"] = "Please enter valid email address.";
        this.formErrorFlag = true;
        inputError = true;
      }
      if (this.input.password == "") {
        this.formErrors["password"] = "Please enter password.";
        this.formErrorFlag = true;
        inputError = true;
      }
      if (inputError) return;
      this.loginLoader = true;
      app.$store.commit("setMemberEmail", this.input.username);
      axios
        .post("/auth/login", {
          email: this.input.username,
          password: this.input.password,
          device_id: this.deviceID,
        })
        .then(function (response) {
          if (response.data.status == "success") {
            store.commit("setAuthentication", true);
            helper.setLoginDetails(response.data);
          }
        })
        .catch(function (error) {
          if (error.response.data.status == "error") {
            if (error.response.data.message == "Your device is not verified") {
              helper.infoMessage(
                app,
                error.response.data.status,
                error.response.data.message
              );
              app.sendOtpCode(app.input.username);
            } else {
              app.formErrorFlag = true;
              app.formErrors["error"] = error.response.data.message;
              app.loginLoader = false;
            }
          }
        });
    },

    sendOtpCode() {
      let app = this;
      axios
        .post("/auth/authenticate", {
          email: this.input.username,
          data: "both",
          device_id: app.deviceID,
        })
        .then(function (response) {
          if (response.data.status == "success") {
            helper.infoMessage(
              app,
              response.data.status,
              response.data.message
            );
            helper.delayRedirect("VerifyOTP", 0);
          }
        })
        .catch(function (error) {
          if (error.response.data.status == "error") {
            app.formErrorFlag = true;
            app.formErrors["error"] = error.response.data.message;
          }
          app.loginLoader = false;
        });
    },
  },
};
</script>
<style scoped>
@import "../assets/css/login.css";
.toggleSSN {
  position: absolute;
  /* left:70%; */
  margin-top: -36px;
}


.welcome h2 {
  color: #ffffff;
  font-weight: bold;
}
</style>
