import { render, staticRenderFns } from "./NueraMessageList.vue?vue&type=template&id=6657b3d6&scoped=true"
import script from "./NueraMessageList.vue?vue&type=script&lang=js"
export * from "./NueraMessageList.vue?vue&type=script&lang=js"
import style0 from "./NueraMessageList.vue?vue&type=style&index=0&id=6657b3d6&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6657b3d6",
  null
  
)

export default component.exports