<template>
  <div class="container-fluid">
    <div class="row body-main-row">
      <div
        class="member-body-content-wrap member_dashboard view-knowledge-center-page"
      >
        <div class="view-message knowledge-view">
          <div class="contents">
            <h3 style="vertical-align: text-bottom">Knowledge Center</h3>

            <b-tabs card v-model="tabIndex">
              <b-tab title="From Admin" @click="tabIndex = 0">
                <b-card-text>
                  <ViewKnowledgeAdminVue></ViewKnowledgeAdminVue>
                </b-card-text>
              </b-tab>

              <b-tab title="From Rep" @click="tabIndex = 1">
                <b-card-text>
                  <ViewKnowledgeRepVue></ViewKnowledgeRepVue>
                </b-card-text>
              </b-tab>
            </b-tabs>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ViewKnowledgeAdminVue from "./ViewKnowledgeAdmin.vue";
import ViewKnowledgeRepVue from "./ViewKnowledgeRep.vue";
export default {
  components: {
    ViewKnowledgeAdminVue,
    ViewKnowledgeRepVue,
  },
  data() {
    return {
      tabIndex: 0,
    };
  },
};
</script>
<style>
@import "../../assets/css/view_messages.css";
.message_body >>> h2 {
  font-size: 20px !important;
}
.message_body >>> h2 strong {
  font-size: 20px !important;
}
.message_body >>> h3 strong {
  font-size: 20px !important;
}
.attchement_details .icon_item {
  width: 100%;
}
.attchement_details .icon_item .resource_item {
  width: 100%;
  margin-top: 1%;
  margin-left: 2%;
}
.attchement_details {
  margin-top: 4%;
}
</style>
<style scoped>
.member-body-content-wrap.member_dashboard {
  min-width: calc(100% - 200px);
}
@media (max-width: 992px) {
  .member-body-content-wrap.member_dashboard {
    min-width: calc(100% - 45px);
  }
}
.attchement_details .row {
  background: #f1efef !important;
}
.contents {
  padding: 0 !important;
  min-height: calc(100vh - 305px);
}
.dashboard-card {
  background-color: #fff;
  border-radius: 20px;
  padding: 30px 25px;
  border: none;
  box-shadow: 0px 2px 6px #21252914;
}
.message_dropdown h3 {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 20px;
}
.view-knowledge-center-page .tabs.row.no-gutters .tab-content .card-body {
  padding: 25px;
}
.view-knowledge-center-page .tabs.row.no-gutters .tab-content .card-body {
  padding: 25px;
}
</style>
