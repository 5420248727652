<template>
  <header>
    <div class="header-logo">
      <!-- <img src="../../assets/images/review-images/icons/corenroll-logo.png"> -->
      <img
        src="https://performance-health-logo.s3.us-east-2.amazonaws.com/logo.png"
      />
    </div>
  </header>
</template>
<script>
export default {
  name: "reviewHeader",
};
</script>
<style scoped>
header {
  padding: 15px 0;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 99;
  display: flex;
  justify-content: center;
  background: #fff;
  /* background: rgba(0,0,0,.3); */
}
.header-logo {
  /* width: 160px; */
  /* height: 60px; */
  /* margin: auto; */
 
}
.header-logo img {
  max-width: 350px;
  /* width: 100%; */
}
</style>
