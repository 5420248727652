<template>
    <footer>
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="footer-logo">
                        <img src="../../assets/images/corenroll-logo.png" alt="">
                    </div>
                    <p>If you have some technical difficulties Go to: <a :href="ticketing_url" target="_blank">Issues Dashboard</a></p>
                </div>
            </div>
        </div>
        <div class="copyright">
            <div class="container">
                <p>&copy; Copyright 2021. All Rights Reserved.</p>
            </div>
        </div>
    </footer>
</template>
<script>
import { ticketing_url } from '../../utlis/constant';
export default {
    name: "reviewFooter",
    data(){
return{
  ticketing_url
}
}}

</script>

<style src="../../assets/css/review_rating/review_content.css" scoped>

</style>
