var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "product_detail-new dashboard-card"
  }, [_c('h3', [_vm._v("Product Details")]), _c('div', {
    staticClass: "product_table-new"
  }, [_c('div', {
    staticClass: "table_content-new"
  }, [_c('div', {
    staticClass: "new-table-div"
  }, [_c('div', {
    staticClass: "new-table"
  }, [_vm._m(0), _c('div', {
    staticClass: "table-body-div"
  }, _vm._l(_vm.product_detail, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "product-list"
    }, [_c('div', {
      staticClass: "plan"
    }, _vm._l(item.plans, function (plan, planindex) {
      return _c('p', {
        key: planindex
      }, [_c('span', {
        class: plan.status != 'ACTIVE' ? 'plan_inactive_red' : 'plan_active'
      }, [_vm._v(" " + _vm._s(plan.plan_name) + " ")])]);
    }), 0), _c('div', {
      staticClass: "status"
    }, _vm._l(item.plans, function (plan, planindex) {
      return _c('p', {
        key: planindex,
        staticClass: "d-flex align-items-center"
      }, [_c('span', {
        class: plan.status != 'ACTIVE' ? 'plan_inactive_red' : 'plan_active'
      }, [_vm._v(" " + _vm._s(plan.status) + " "), _vm.canActivate(plan.status, plan.plan_term_date ? plan.plan_term_date : null, plan.is_assoc, item.is_plan_available) ? _c('button', {
        directives: [{
          name: "b-tooltip",
          rawName: "v-b-tooltip.hover",
          modifiers: {
            "hover": true
          }
        }],
        staticClass: "btn-activate",
        attrs: {
          "title": "Re-Activate"
        },
        on: {
          "click": function ($event) {
            return _vm.showActivateFormModel(plan, item.policy_id);
          }
        }
      }, [_c('img', {
        staticClass: "reactive",
        attrs: {
          "src": require("../../assets/img/re-activate.svg"),
          "alt": ""
        }
      })]) : _vm._e()])]);
    }), 0), _c('div', {
      staticClass: "effective-date"
    }, [_vm._l(item.plans, function (plan, planindex) {
      return _c('p', {
        key: planindex
      }, [_c('span', {
        class: plan.status != 'ACTIVE' ? 'plan_inactive_red' : 'plan_active'
      }, [_vm._v(" " + _vm._s(plan.plan_effective_date) + " ")])]);
    }), _c('p', [_vm._v("(Bill Date:" + _vm._s(item.bill_date) + ") ")])], 2), _c('div', {
      staticClass: "effective-date"
    }, _vm._l(item.plans, function (plan, planindex) {
      return _c('p', {
        key: planindex
      }, [_c('span', {
        class: plan.status != 'ACTIVE' ? 'plan_inactive_red' : 'plan_active'
      }, [_vm._v(" " + _vm._s(plan.plan_term_date ? plan.plan_term_date : "--") + " ")])]);
    }), 0), _c('div', {
      staticClass: "tire"
    }, [_vm._v(_vm._s(item.tier))]), _c('div', {
      staticClass: "monthly-premium"
    }, [_vm._v(" " + _vm._s(`$${item.monthly_premium}`) + " ")]), _c('div', {
      staticClass: "action"
    }, [_c('router-link', {
      attrs: {
        "to": {
          name: 'PlanDetail',
          params: {
            policyID: item.policy_id
          }
        }
      }
    }, [_c('button', {
      staticClass: "view_detail"
    }, [_vm._v(" View Details ")])]), item.show_terminate ? _c('button', {
      staticClass: "btn-terminate",
      on: {
        "click": function ($event) {
          return _vm.showTerminateFormModel(item.policy_id);
        }
      }
    }, [_vm._v(" Terminate ")]) : _vm._e()], 1)]);
  }), 0)])]), _vm.skeletonLoader ? _c('b-skeleton-table', {
    attrs: {
      "rows": 2,
      "columns": 6,
      "table-props": {
        bordered: _vm.none,
        striped: true
      }
    }
  }) : _vm._e()], 1)]), _c('b-modal', {
    attrs: {
      "ok-disabled": _vm.formSubmitted,
      "id": "terminate_form",
      "ok-title": "Submit",
      "centered": true,
      "title": "Terminate Plan",
      "no-close-on-backdrop": ""
    },
    on: {
      "hidden": _vm.resetFormFields,
      "ok": _vm.terminatePlanPolicy
    }
  }, [_c('p', {
    staticClass: "disclaimer-text"
  }, [_vm._v("You can only select a termination date within the current month. If you wish to select a date in another month, please make your request during that month or contact our support team for assistance.")]), _c('hr'), _c('b-form-group', {
    staticClass: "required",
    attrs: {
      "label": "Term Effective Date",
      "label-for": "terminate_input_2",
      "invalid-feedback": "Term date is required."
    }
  }, [_c('b-form-select', {
    attrs: {
      "state": _vm.validateState('term_date'),
      "id": "terminate_input_2",
      "options": _vm.term_dates,
      "required": ""
    },
    scopedSlots: _vm._u([{
      key: "first",
      fn: function () {
        return [_c('b-form-select-option', {
          attrs: {
            "value": ""
          }
        }, [_vm._v("Select Date")])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.$v.form_policy_update.term_date.$model,
      callback: function ($$v) {
        _vm.$set(_vm.$v.form_policy_update.term_date, "$model", $$v);
      },
      expression: "$v.form_policy_update.term_date.$model"
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Select Plan(s)",
      "label-for": "plan_checkboxes"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        var ariaDescribedby = _ref.ariaDescribedby;
        return _vm._l(_vm.infoOne, function (option) {
          return _c('b-form-checkbox', {
            key: option.plan_id,
            attrs: {
              "v-if": 1 === 2,
              "state": _vm.validateState('plan_id'),
              "value": option.plan_id,
              "aria-describedby": ariaDescribedby,
              "name": "flavour-3a"
            },
            model: {
              value: _vm.$v.form_policy_update.plan_id.$model,
              callback: function ($$v) {
                _vm.$set(_vm.$v.form_policy_update.plan_id, "$model", $$v);
              },
              expression: "$v.form_policy_update.plan_id.$model"
            }
          }, [_vm._v(" " + _vm._s(option.plan_name) + " ")]);
        });
      }
    }])
  }), _c('b-form-group', {
    staticClass: "required",
    attrs: {
      "label": "Reasons",
      "label-for": "terminate_input_3",
      "invalid-feedback": "Reason is required."
    }
  }, [_c('b-form-select', {
    attrs: {
      "state": _vm.validateState('term_reason'),
      "id": "terminate_input_3",
      "options": _vm.reasons,
      "required": ""
    },
    model: {
      value: _vm.$v.form_policy_update.term_reason.$model,
      callback: function ($$v) {
        _vm.$set(_vm.$v.form_policy_update.term_reason, "$model", $$v);
      },
      expression: "$v.form_policy_update.term_reason.$model"
    }
  }), _vm.form_policy_update.term_reason == 'Others' ? _c('b-form-group', {
    staticClass: "required",
    attrs: {
      "label": "",
      "label-for": "other_reason",
      "invalid-feedback": "Reason is required."
    }
  }, [_c('b-form-textarea', {
    staticStyle: {
      "margin-top": "4%"
    },
    attrs: {
      "id": "other_reason",
      "state": _vm.validateState('other_reason'),
      "placeholder": "Enter reason",
      "rows": "3",
      "max-rows": "6"
    },
    model: {
      value: _vm.$v.form_policy_update.other_reason.$model,
      callback: function ($$v) {
        _vm.$set(_vm.$v.form_policy_update.other_reason, "$model", $$v);
      },
      expression: "$v.form_policy_update.other_reason.$model"
    }
  })], 1) : _vm._e()], 1), _c('div', {
    staticClass: "signature"
  }, [_c('div', {
    staticClass: "signature_title"
  }, [_c('p', [_vm._v(" Application Signature (Required) "), _c('span', [_vm._v("(Sign Name using your mouse or touch screen in the box below)")])])]), _c('signature-pad', {
    on: {
      "sign": _vm.getSign
    }
  }), !_vm.$v.form_policy_update.sign.required && _vm.$v.form_policy_update.sign.$error ? _c('div', {
    staticClass: "invalid-feedback",
    staticStyle: {
      "display": "block"
    },
    attrs: {
      "tabindex": "-1",
      "role": "alert",
      "aria-live": "assertive",
      "aria-atomic": "true"
    }
  }, [_vm._v(" Sign is required. ")]) : _vm._e(), _c('div', [_c('p', {
    staticClass: "terminate_noticed"
  }, [_vm._v(" Note : By sending this termination request you are aware that a scheduled pending payment or payment in progress cannot be altered or will not be stopped by submitting this request. ")])])], 1)], 1), _c('PlanReactivationModal', {
    attrs: {
      "current_plan": _vm.current_plan,
      "current_policy_id": _vm.current_policy_id
    },
    on: {
      "update:current_plan": function ($event) {
        _vm.current_plan = $event;
      },
      "update:current_policy_id": function ($event) {
        _vm.current_policy_id = $event;
      }
    }
  })], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "table-head-div"
  }, [_c('div', {
    staticClass: "plan"
  }, [_vm._v("Plan")]), _c('div', {
    staticClass: "status"
  }, [_vm._v("Status")]), _c('div', {
    staticClass: "effective-date"
  }, [_vm._v("Effective Date")]), _c('div', {
    staticClass: "effective-date"
  }, [_vm._v("Termination Date")]), _c('div', {
    staticClass: "tire"
  }, [_vm._v("Tier")]), _c('div', {
    staticClass: "monthly-premium"
  }, [_vm._v("Monthly Premium")]), _c('div', {
    staticClass: "action"
  }, [_vm._v("Action")])]);

}]

export { render, staticRenderFns }