<template>
    <div class="content_wrapper">
        <agent-info></agent-info>
        <div class="contract_content" ref="save">
            <contract-form-sidebar/>

            <div class="right_container">
                   <h3>Personal Information</h3>
                   <div class="form">
                       <div>
                          <label>Representative ID</label><br>
                          <input type="text" name="id" v-model="formFields.id.value" value="" :readonly="formFields.id.read_only == true">
                          <small class="error_message" v-if="formErrorFlag == true">{{formErrors['id_message']}}</small>
                       </div>

                       <div>
                        <label>First Name</label><br>
                        <input type="text" name="fname" v-model="formFields.fname.value" value="" :readonly="formFields.fname.read_only == true">
                        <small class="error_message" v-if="formErrorFlag == true">{{formErrors['fname_message']}}</small>
                    </div>
                       
                    <div>
                        <label>Middle Initial</label><br>
                        <input type="text" name="minitial" v-model="formFields.minitial.value" value="" :readonly="formFields.minitial.read_only == true">
                        <small class="error_message" v-if="formErrorFlag == true">{{formErrors['minitial_message']}}</small>
                    </div>

                    <div>
                        <label>Last Name</label><br>
                        <input type="text" name="lname" v-model="formFields.lname.value" value="" :readonly="formFields.lname.read_only == true">
                        <small class="error_message" v-if="formErrorFlag == true">{{formErrors['lname_message']}}</small>
                        
                    </div>

                    <div>
                        <label>Date of Birth</label><br>
                        <input type="text" name="birth_date" v-model="formFields.birth_date.value" value="" :readonly="formFields.birth_date.read_only == true">
                        <small class="error_message" v-if="formErrorFlag == true">{{formErrors['birth_date_message']}}</small>
                    </div>

                    <div v-if="formFields.social_security.value">
                        <label>Social Security Number</label><br>
                        <input type="text" name="social_security" v-model="formFields.social_security.value" value="" :readonly="formFields.social_security.read_only == true">
                        <small class="error_message" v-if="formErrorFlag == true">{{formErrors['social_security_message']}}</small>
                    </div>
                    <div v-else-if="formFields.tin_number.value">
                        <label>Company Tax ID</label><br>
                        <input type="text" name="tin_number" v-model="formFields.tin_number.value" value="" :readonly="formFields.tin_number.read_only == true">
                        <small class="error_message" v-if="formErrorFlag == true">{{formErrors['tin_number_message']}}</small>
                    </div>

                   
                   </div> 
                   <div class="button_container">
                    <div class="button">
                    <!-- <router-link :to="{name:'ContractContactInfo'}"> --><button class="next" type="button" name="button" @click="savePersonalInfo">Next</button>
                    <!-- <router-link :to="{name:'ContractContactInfo'}"><button class="next" @click="savePersonalInfo();">Next</button></router-link> -->
                </div>
                </div>

            </div>
        </div>
    </div>     
</template>

<style src="../../assets/css/contract_form/personal_info.css" scoped>     
</style>

<script>

    import ContractFormSidebar from '../partial/ContractFormSidebar.vue';
    import AgentInfo from "../common/AgentInfo.vue"
    import axios from 'axios';
    import html2canvas from 'html2canvas';
    export default{
        components:{ AgentInfo, ContractFormSidebar },
        data: function(){
            return{
                agentId: '',
                contractType: '',
                level: '',
                tempId: '',
                contractId:'',
                formFields: {
                    id: {
                        value: '',
                        required: false,
                        read_only: false,
                    },
                    fname: {
                        value: '',
                        required: false,
                        read_only: false,
                    },
                    minitial: {
                        value: '',
                        required: false,
                        read_only: false,
                    },
                    lname: {
                        value: '',
                        required: false,
                        read_only: false,
                    },
                    birth_date: {
                        value: '',
                        required: false,
                        read_only: false,
                    },
                    social_security: {
                        value: '',
                        required: false,
                        read_only: false,
                    },
                    tin_number: {
                        value: '',
                        required: false,
                        read_only: false,
                    },
              },
              formErrorFlag: false,
              formErrors: [],
            }
        },
        mounted(){
            let app = this;
            let urlParams = new URLSearchParams(window.location.search);
            if (!urlParams.has('progress')) {
                window.localStorage.clear();
             }
            if(urlParams.has('contract_id')){
              let urlContractId = urlParams.get('contract_id');
              app.contractId = atob(urlContractId);
              window.localStorage.setItem('contract_id', app.contractId);
            }
            app.tempId = window.localStorage.getItem('tempId');
            app.contractType= window.localStorage.getItem('contractType');
            app.agentId = window.localStorage.getItem('agentId')
            // if (urlParams.has('id')) {
            //   let urlagentId = urlParams.get('id');
            //   app.agentId = atob(urlagentId);
            //   window.localStorage.setItem('agentId', app.agentId);
            // }
            // if (urlParams.has('type')) {
            //   app.contractType = urlParams.get('type') === 'smart-Ancillary'? 'ancillary':(urlParams.get('type') === 'eWAContract(UnionOnly)'? 'premier':(urlParams.get('type') === 'gPBP(FEGLI)'? 'fegliAncillary':urlParams.get('type')));
            //   window.localStorage.setItem('headerType',urlParams.get('type'));
            //   window.localStorage.setItem('type', app.contractType === 'smart-Ancillary'? 'ancillary':(app.contractType === 'eWAContract(UnionOnly)'? 'premier':(app.contractType === 'gPBP(FEGLI)'? 'fegliAncillary':app.contractType)));
            // }
            // if (urlParams.has('level')) {
            //   app.level = urlParams.get('level');
            //   window.localStorage.setItem('level', app.level);
            // }
            // if (urlParams.has('contract_type')) {
            //   window.localStorage.setItem('contract_type', urlParams.get('contract_type'));
            // }
            // app.agentId= app.$route.params.id;
            // app.contractType= this.$route.params.type;
            // app.level= this.$route.params.level;
            if(window.localStorage.getItem('agentId') && window.localStorage.getItem('contractType')){
                app.getPersonalInfo();
            }
            else{
                app.getContractInfo()
            }
            
        },
        methods:{

            getContractInfo(){
                let loader = this.$loading.show();
                let app = this;
                axios.get(process.env.VUE_APP_CORENROLL_SYSTEM+'/get-contract-info', {
                params: {
                    contract_id: app.contractId
                }
              })
              .then(function (response) {
                let result = response.data.data.result
                window.localStorage.setItem('agentId', result.agent_id);
                window.localStorage.setItem('headerType', result.contract_display_name === 'Non-Category Contract' ? 'Standard Contracting' : (result.contract_display_name === 'Category Patriot-Ancillary Contract' ? 'Patriot Medical - Multi Level Contract':result.contract_display_name) );
                window.localStorage.setItem('contractType', result.contract_type);
                window.localStorage.setItem('tempId', result.temp_id);
                window.localStorage.setItem('personal_complete', 'complete');
                window.localStorage.setItem('personal_complete', 'complete');
                window.localStorage.setItem('contact_complete', 'complete');
                window.localStorage.setItem('address_complete', 'complete');
                window.localStorage.setItem('billing_complete', 'complete');
                app.$router.push("commission_sheet")

            })
              .catch(function (error) {
                  if(error.response.data.message == 'Form completed.'){
                      window.location = '/contract-form/contract_message/completed';
                  }else if(error.response.data.message == 'Contract does not exist.'){
                      window.location = '/contract-form/contract_message/contract_not_found';
                  } else {
                    window.location = '/contract-form/contract_message/error';
                  }
              }).finally(() => {
                loader.hide();
                });
            },
            getPersonalInfo: function () {
              let loader = this.$loading.show();
              let app = this;
              console.log(app.agentId);
              axios.get(process.env.VUE_APP_CORENROLL_SYSTEM+'/get-form-data-personal-info?', {
                params: {
                    tempId:app.tempId,
                    agentid: app.agentId,
                    contract_type: app.contractType,
                }
              })
              .then(function (response) {
                let fetchedData = [];
                if (response.data.status == 'success') {
                  app.tempId = response.data.data.tempid;
                  window.localStorage.setItem('tempId', app.tempId);
                  for(var i in response.data.data.result) {
                      fetchedData.push(response.data.data.result[i]);
                  }
                  // app.tempId = response.data.tempid;
                  fetchedData.forEach(function(item) {
                    console.log(item);
                      // let key = item[0];
                      // let val = item[0]['value'];
                      app.formFields[item.field_name].value = item.value;
                      app.formFields[item.field_name].read_only = item.read_only;
                  });
                }
              })
              .catch(function (error) {
                  if(error.response.data.message == 'Form completed.'){
                      window.location = '/contract-form/contract_message/completed';
                  }else if(error.response.data.message == 'Agent does not exist.'){
                      window.location = '/contract-form/contract_message/not_found';
                  } else {
                    window.location = '/contract-form/contract_message/error';
                  }
              }).finally(() => {
                loader.hide();
                });
            },
            savePersonalInfo: function () {
              let loader = this.$loading.show();
              let app = this;
              app.formErrorFlag = false;
              app.formErrors    = [];
              let params = {
                  agentid: app.agentId,
                  contract_type: app.contractType,
                  first_name:           app.formFields.fname.value,
                  middle:               app.formFields.minitial.value,
                  last_name:            app.formFields.lname.value,
                  date_of_birth:        app.formFields.birth_date.value,
                  social_security:      app.formFields.social_security.value,
                  tin_number:           app.formFields.tin_number.value,
                  tempid:               app.tempId,
              };
              //discard null values
              for (const key of Object.keys(params)) {
                  if (params[key] === null) {
                      delete params[key];
                  }
              }

              axios.post(process.env.VUE_APP_CORENROLL_SYSTEM+'/save-personal-information', params)
              .then(function (response) {
                  if (response.data.status == 'success') {
                      console.log("success");
                      app.tempId = response.data.data.tempid;
                      app.takeScreenshot();
                      window.localStorage.setItem('tempId', response.data.data.tempid);
                      app.$router.push("contact_info");
                      window.localStorage.setItem('personal_complete', 'complete');
                  }
              })
              .catch(function (error) {
                  if (error.response.data.status == 'error') {
                      error.response.data.data.forEach(function(item) {
                          app.formErrorFlag = true;
                          app.formErrors[item.target_element+'_message'] = item.error_message;
                      });
                  }
              }).finally(() => {
                loader.hide();
                });
            },
            takeScreenshot(){
                const el = this.$refs.save;
                (async () => {
                    this.saveScreenShot((await html2canvas(el)).toDataURL('image/png'), 'personal');
                })();
                setTimeout(() => { }, 100);
            },
            saveScreenShot (image,name) {
                let data = {
                    "agent_id" : window.localStorage.getItem('agentId'),
                    "contract_type" : window.localStorage.getItem('contractType'),
                    "image" : image,
                    "name" : name,
                    "tempid" : this.tempId,
                };
                let config = {
                    method: 'post',
                    url: process.env.VUE_APP_CORENROLL_SYSTEM+'/save-contract-screenshot',
                    headers: { },
                    data : data
                };

                axios(config)
                    .then(function (response) {
                        if (response.data.status == 'success') {
                            console.log('screenshot saved');
                        }
                    })
                    .catch(function (error) {
                        console.log('screenshot not saved');
                        console.log(error);
                    });
            },
            scrollTop(){
                window.scrollTo(0,0);
            }
        }
    };
</script>