var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    staticClass: "container-wrapper review-container"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-8 offset-md-2"
  }, [_c('div', {
    staticClass: "review-form-wrap white-wrap"
  }, [_c('form', [_c('div', {
    staticClass: "form-group"
  }, [_vm._m(0), _c('div', {
    staticClass: "s-col"
  }, [_c('star-rating', {
    attrs: {
      "increment": "0.5",
      "star-size": "32",
      "padding": "1",
      "active-color": "#29c4ee"
    },
    model: {
      value: _vm.formFields.stars.value,
      callback: function ($$v) {
        _vm.$set(_vm.formFields.stars, "value", $$v);
      },
      expression: "formFields.stars.value"
    }
  }), _vm.formErrorFlag == true ? _c('small', {
    staticClass: "error_message"
  }, [_vm._v(_vm._s(_vm.formErrors['stars_message']))]) : _vm._e()], 1)]), _c('div', {
    staticClass: "form-group"
  }, [_vm._m(1), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formFields.title.value,
      expression: "formFields.title.value"
    }],
    attrs: {
      "type": "text",
      "name": "title",
      "placeholder": "Give your review title here",
      "required": ""
    },
    domProps: {
      "value": _vm.formFields.title.value
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.formFields.title, "value", $event.target.value);
      }
    }
  }), _vm.formErrorFlag == true ? _c('small', {
    staticClass: "error_message"
  }, [_vm._v(_vm._s(_vm.formErrors['title_message']))]) : _vm._e()]), _c('div', {
    staticClass: "form-group"
  }, [_vm._m(2), _c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formFields.message.value,
      expression: "formFields.message.value"
    }],
    attrs: {
      "rows": "4",
      "name": "message",
      "placeholder": "Write your review to help others learn about this business",
      "required": ""
    },
    domProps: {
      "value": _vm.formFields.message.value
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.formFields.message, "value", $event.target.value);
      }
    }
  }), _vm.formErrorFlag == true ? _c('small', {
    staticClass: "error_message"
  }, [_vm._v(_vm._s(_vm.formErrors['message_message']))]) : _vm._e()]), _c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    staticClass: "switch",
    attrs: {
      "for": "checkbox"
    }
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formFields.anonymous.value,
      expression: "formFields.anonymous.value"
    }],
    attrs: {
      "type": "checkbox",
      "id": "checkbox"
    },
    domProps: {
      "checked": Array.isArray(_vm.formFields.anonymous.value) ? _vm._i(_vm.formFields.anonymous.value, null) > -1 : _vm.formFields.anonymous.value
    },
    on: {
      "change": function ($event) {
        var $$a = _vm.formFields.anonymous.value,
          $$el = $event.target,
          $$c = $$el.checked ? true : false;
        if (Array.isArray($$a)) {
          var $$v = null,
            $$i = _vm._i($$a, $$v);
          if ($$el.checked) {
            $$i < 0 && _vm.$set(_vm.formFields.anonymous, "value", $$a.concat([$$v]));
          } else {
            $$i > -1 && _vm.$set(_vm.formFields.anonymous, "value", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.$set(_vm.formFields.anonymous, "value", $$c);
        }
      }
    }
  }), _c('div', {
    staticClass: "slider round"
  })]), _c('span', {
    staticClass: "anoymous-text"
  }, [_vm._v("Submit feedback as anonymous user")])]), !_vm.formFields.anonymous.value ? _c('div', {
    staticClass: "form-group row"
  }, [_c('div', {
    staticClass: "col-md-4 mb-20"
  }, [_vm._m(3), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formFields.name.value,
      expression: "formFields.name.value"
    }],
    attrs: {
      "type": "text",
      "name": "name",
      "placeholder": "Full Name"
    },
    domProps: {
      "value": _vm.formFields.name.value
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.formFields.name, "value", $event.target.value);
      }
    }
  }), _vm.formErrorFlag == true ? _c('small', {
    staticClass: "error_message"
  }, [_vm._v(_vm._s(_vm.formErrors['name_message']))]) : _vm._e()]), _c('div', {
    staticClass: "col-md-4 mb-20"
  }, [_vm._m(4), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formFields.email.value,
      expression: "formFields.email.value"
    }],
    attrs: {
      "type": "email",
      "name": "email",
      "placeholder": "Email Address"
    },
    domProps: {
      "value": _vm.formFields.email.value
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.formFields.email, "value", $event.target.value);
      }
    }
  }), _vm.formErrorFlag == true ? _c('small', {
    staticClass: "error_message"
  }, [_vm._v(_vm._s(_vm.formErrors['email_message']))]) : _vm._e()]), _c('div', {
    staticClass: "col-md-4"
  }, [_vm._m(5), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formFields.phone.value,
      expression: "formFields.phone.value"
    }],
    attrs: {
      "type": "text",
      "name": "phone",
      "placeholder": "Phone Number"
    },
    domProps: {
      "value": _vm.formFields.phone.value
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.formFields.phone, "value", $event.target.value);
      }
    }
  }), _vm.formErrorFlag == true ? _c('small', {
    staticClass: "error_message"
  }, [_vm._v(_vm._s(_vm.formErrors['phone_message']))]) : _vm._e()])]) : _vm._e(), _c('div', {
    staticClass: "form-group alignR"
  }, [_c('b-button', {
    staticClass: "submit-btn",
    attrs: {
      "disabled": _vm.isProcessing
    },
    on: {
      "click": _vm.checkForm
    }
  }, [_vm.isProcessing ? _c('img', {
    attrs: {
      "src": require("../../assets/images/loader.svg"),
      "alt": "",
      "width": "20"
    }
  }) : _vm._e(), _vm._v(" Submit Review ")]), _c('b-button', {
    staticClass: "cancel-btn",
    on: {
      "click": function ($event) {
        return _vm.$router.push({
          path: 'review-option'
        });
      }
    }
  }, [_vm._v("Cancel")])], 1)])])])])])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('h3', [_vm._v("Overall Rating "), _c('span', {
    staticClass: "required-alert"
  }, [_vm._v("*")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('h3', [_vm._v("Title of your review "), _c('span', {
    staticClass: "required-alert"
  }, [_vm._v("*")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('h3', [_vm._v("Your Review Message "), _c('span', {
    staticClass: "required-alert"
  }, [_vm._v("*")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('h3', [_vm._v("Your Name "), _c('span', {
    staticClass: "optional-text"
  }, [_vm._v("(optional)")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('h3', [_vm._v("Email Address "), _c('span', {
    staticClass: "optional-text"
  }, [_vm._v("(optional)")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('h3', [_vm._v("Phone Number "), _c('span', {
    staticClass: "optional-text"
  }, [_vm._v("(optional)")])]);

}]

export { render, staticRenderFns }