var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "message_center_new dashboard-card mt-4"
  }, [_c('div', {
    staticClass: "message_head"
  }, [_c('h3', [_vm._v("Message Center")]), _vm.messages.total_records > 2 ? _c('router-link', {
    attrs: {
      "to": {
        name: 'ViewMessage'
      }
    }
  }, [_c('small', [_vm._v("View All")])]) : _vm._e()], 1), _vm.skeletonLoader ? _c('b-skeleton-table', {
    attrs: {
      "rows": 5,
      "columns": 2,
      "table-props": {
        bordered: true,
        striped: true
      }
    }
  }) : _vm._e(), !_vm.skeletonLoader ? _c('div', {
    staticClass: "dashboard-msg-body"
  }, _vm._l(_vm.messages.records, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "msg-list"
    }, [_c('div', {
      staticClass: "message"
    }, [_c('p', [_vm._v(_vm._s(item.subject))]), _c('small', [_vm._v(_vm._s(_vm._f("date_format")(item.date_created, "YYYY-MM-DD", "MM/DD/YYYY", item.date_created)))])]), _c('button', {
      staticClass: "read",
      on: {
        "click": function ($event) {
          return _vm.openModal('message_' + item.mid, item.mid);
        }
      }
    }, [_vm._v(" Read More ")])]);
  }), 0) : _vm._e(), _vm._l(_vm.messages.records, function (item, index) {
    var _item$sender_details$, _item$sender_details, _item$sender_details2;
    return _c('b-modal', {
      key: index,
      staticClass: "message_modal",
      attrs: {
        "title": item.subject,
        "id": 'message_' + item.mid,
        "centered": true,
        "hide-footer": true,
        "size": "lg"
      }
    }, [_c('div', {
      staticClass: "modal-body"
    }, [_c('div', {
      staticClass: "rcv-msgcontent"
    }, [_c('div', {
      staticClass: "rcv-msg-sender"
    }, [_c('div', {
      staticClass: "rcv-snder-icon"
    }, [_c('img', {
      staticClass: "msg-userIcon",
      attrs: {
        "src": (_item$sender_details$ = (_item$sender_details = item.sender_details) === null || _item$sender_details === void 0 ? void 0 : _item$sender_details.image) !== null && _item$sender_details$ !== void 0 ? _item$sender_details$ : _vm.$dummyImage,
        "alt": ""
      }
    }), _c('div', {
      staticClass: "rcv-sender-details"
    }, [_c('h6', [_vm._v(_vm._s((_item$sender_details2 = item.sender_details) === null || _item$sender_details2 === void 0 ? void 0 : _item$sender_details2.name))]), _c('p', [_c('i', {
      staticClass: "far fa-calendar-alt"
    }), _vm._v(" " + _vm._s(_vm._f("date_format")(item.date_created, "YYYY-MM-DD", "MM/DD/YYYY", item.date_created)) + " ")])])])]), _c('h5', [_vm._v("Title: " + _vm._s(item.subject))]), _c('hr'), _c('div', {
      staticClass: "ql-snow"
    }, [_c('div', {
      staticClass: "ql-editor"
    }, [_c('p', {
      domProps: {
        "innerHTML": _vm._s(item.message)
      }
    })])]), item.attachments && item.attachments.length > 0 ? _c('div', [_c('h6', [_vm._v("Attachments:")]), _c('ul', {
      staticStyle: {
        "list-style": "none"
      }
    }, _vm._l(item.attachments, function (attachment, attachmentindex) {
      return _c('li', {
        key: attachmentindex
      }, [_c('a', {
        attrs: {
          "href": attachment.file_url,
          "target": "__blank",
          "download": ""
        }
      }, [_c('i', {
        staticClass: "fa fa-file"
      }), _vm._v(" " + _vm._s(attachment.title) + " ")])]);
    }), 0)]) : _vm._e()])]), _c('div', {
      staticClass: "modal-footer"
    })]);
  })], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }