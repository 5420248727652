import { render, staticRenderFns } from "./menuNav.vue?vue&type=template&id=2a818d97"
import script from "./menuNav.vue?vue&type=script&lang=js"
export * from "./menuNav.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports