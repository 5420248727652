<template>
  <div class="product_detail-new dashboard-card">
    <h3>Product Details</h3>
    <div class="product_table-new">
      <div class="table_content-new">
        <div class="new-table-div">
          <div class="new-table">
            <div class="table-head-div">
              <div class="plan">Plan</div>
              <div class="status">Status</div>
              <div class="effective-date">Effective Date</div>
              <div class="effective-date">Termination Date</div>
              <div class="tire">Tier</div>
              <div class="monthly-premium">Monthly Premium</div>
              <div class="action">Action</div>
            </div>

            <div class="table-body-div">
              <div
                class="product-list"
                v-for="(item, index) in product_detail"
                :key="index"
              >
                <div class="plan">
                  <p v-for="(plan, planindex) in item.plans" :key="planindex">
                    <span
                      :class="
                        plan.status != 'ACTIVE'
                          ? 'plan_inactive_red'
                          : 'plan_active'
                      "
                    >
                      {{ plan.plan_name }}
                    </span>
                  </p>
                </div>
                <div class="status">
                  <p
                    v-for="(plan, planindex) in item.plans"
                    :key="planindex"
                    class="d-flex align-items-center"
                  >
                    <span
                      :class="
                        plan.status != 'ACTIVE'
                          ? 'plan_inactive_red'
                          : 'plan_active'
                      "
                    >
                      {{ plan.status }}
                   
                    
                    <button
                      v-if="canActivate(plan.status,plan.plan_term_date ? plan.plan_term_date :null, plan.is_assoc, item.is_plan_available)"
                      @click="showActivateFormModel(plan, item.policy_id)"
                      class="btn-activate"
                      v-b-tooltip.hover title="Re-Activate"
                    >
                     <img src="../../assets/img/re-activate.svg" alt="" class="reactive">
                      <!-- <span class="reactive-txt">Re-activate</span> -->
                    </button>
                  </span>
                  </p>
                </div>
                
                <div class="effective-date">
                  <p v-for="(plan, planindex) in item.plans" :key="planindex">
                    <span
                      :class="
                        plan.status != 'ACTIVE'
                          ? 'plan_inactive_red'
                          : 'plan_active'
                      "
                    >
                      {{ plan.plan_effective_date }}
                    </span>
                  </p>
                  <p>(Bill Date:{{ item.bill_date }}) </p>
                </div>
                <div class="effective-date">
                  <p v-for="(plan, planindex) in item.plans" :key="planindex">
                    <span
                      :class="
                        plan.status != 'ACTIVE'
                          ? 'plan_inactive_red'
                          : 'plan_active'
                      "
                    >
                      {{ plan.plan_term_date ? plan.plan_term_date : "--" }}
                    </span>
                  </p>
                </div>

                <div class="tire">{{ item.tier }}</div>
                <div class="monthly-premium">
                  {{ `$${item.monthly_premium}` }}
                </div>
                <div class="action">
                  <router-link
                    :to="{
                      name: 'PlanDetail',
                      params: { policyID: item.policy_id },
                    }"
                    ><button class="view_detail">
                      View Details
                    </button></router-link
                  >
                  <button
                    @click="showTerminateFormModel(item.policy_id)"
                    v-if="item.show_terminate"
                    class="btn-terminate"
                  >
                    Terminate
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <b-skeleton-table
          v-if="skeletonLoader"
          :rows="2"
          :columns="6"
          :table-props="{ bordered: none, striped: true }"
        ></b-skeleton-table>
      </div>
    </div>

    <b-modal
      :ok-disabled="formSubmitted"
      @hidden="resetFormFields"
      id="terminate_form"
      ok-title="Submit"
      :centered="true"
      @ok="terminatePlanPolicy"
      title="Terminate Plan"
      no-close-on-backdrop
    >
    <p class="disclaimer-text">You can only select a termination date within the current month. If you wish to select a date in another month, please make your request during that month or contact our support team for assistance.</p>
    <hr/>
      <b-form-group
        label="Term Effective Date"
        label-for="terminate_input_2"
        invalid-feedback="Term date is required."
        class="required"
      >
        <b-form-select
          :state="validateState('term_date')"
          id="terminate_input_2"
          v-model="$v.form_policy_update.term_date.$model"
          :options="term_dates"
          required
        >
          <template #first>
            <b-form-select-option value="">Select Date</b-form-select-option>
          </template>
        </b-form-select>
      </b-form-group>

      <b-form-group
        label="Select Plan(s)"
        label-for="plan_checkboxes"
        v-slot="{ ariaDescribedby }"
      >
        <b-form-checkbox
          :v-if="1 === 2"
          :state="validateState('plan_id')"
          v-model="$v.form_policy_update.plan_id.$model"
          v-for="option in infoOne"
          :key="option.plan_id"
          :value="option.plan_id"
          :aria-describedby="ariaDescribedby"
          name="flavour-3a"
        >
          {{ option.plan_name }}
        </b-form-checkbox>
      </b-form-group>

      <b-form-group
        label="Reasons"
        label-for="terminate_input_3"
        invalid-feedback="Reason is required."
        class="required"
      >
        <b-form-select
          :state="validateState('term_reason')"
          id="terminate_input_3"
          v-model="$v.form_policy_update.term_reason.$model"
          :options="reasons"
          required
        >
        </b-form-select>
        <b-form-group
          v-if="form_policy_update.term_reason == 'Others'"
          label=""
          label-for="other_reason"
          invalid-feedback="Reason is required."
          class="required"
        >
          <b-form-textarea
            style="margin-top: 4%"
            id="other_reason"
            :state="validateState('other_reason')"
            v-model="$v.form_policy_update.other_reason.$model"
            placeholder="Enter reason"
            rows="3"
            max-rows="6"
          ></b-form-textarea>
        </b-form-group>
      </b-form-group>

      <div class="signature">
        <div class="signature_title">
          <p>
            Application Signature (Required)
            <span
              >(Sign Name using your mouse or touch screen in the box
              below)</span
            >
          </p>
        </div>
        <signature-pad @sign="getSign"></signature-pad>
        <div
          tabindex="-1"
          v-if="
            !$v.form_policy_update.sign.required &&
            $v.form_policy_update.sign.$error
          "
          role="alert"
          aria-live="assertive"
          aria-atomic="true"
          style="display: block"
          class="invalid-feedback"
        >
          Sign is required.
        </div>
        <div>
          <p class="terminate_noticed">
            Note : By sending this termination request you are aware that a
            scheduled pending payment or payment in progress cannot be altered
            or will not be stopped by submitting this request.
          </p>
        </div>
      </div>
    </b-modal>
    <PlanReactivationModal
      :current_plan.sync="current_plan"
      :current_policy_id.sync="current_policy_id"
    />
  </div>
</template>

<script>
/* eslint-disable */
import axios from "axios";
import { required, requiredIf } from "vuelidate/lib/validators";
import SignaturePad from "../../components/SignaturePad";
import helper from "../../resource/Helper";
import PlanReactivationModal from "./partials/PlanReactivationModal.vue";
import moment from "moment";
function resetForm() {
  return {
    policy_id: "",
    term_reason: "",
    other_reason: "",
    term_date: "",
    sign: "",
    plan_id: [],
  };
}

export default {
  components: {
    SignaturePad,
    PlanReactivationModal,
  },
  data() {
    return {
      product_detail: [],
      skeletonLoader: true,
      policy_details_individual: [],
      term_dates: [],
      selected: [],
      reasons: [
        { text: "Select Reason", value: "" },
        { text: "Left Employers", value: "Left Employers" },
        { text: "Switched Plan", value: "Switched Plan" },
        { text: "Others", value: "Others" },
      ],
      form_policy_update: resetForm(),
      formSubmitted: false,
      current_plan: null,
      current_policy_id: null,
    };
  },
  computed: {
    infoOne: function () {
      return this.policy_details_individual.plans.filter(
        (data) =>
          !data.is_assoc &&
          data.status == "ACTIVE" &&
          !data.termination_request_sent
      );
    },
  },
  mounted() {
    this.getProductDetails();
  },
  methods: {

    canActivate(status,date,isAssoc, is_plan_available){
      let activeStatus = false;
      if(status == 'WITHDRAWN'){
        activeStatus =  true
      }else if(status != 'ACTIVE' && date){
        let todayDate=moment(new Date()).format('MM/DD/YYYY');
        activeStatus = moment(date).isBefore(todayDate)
      }else{
        activeStatus =  false;
      }
      return activeStatus && (!isAssoc) && is_plan_available

    },
    getProductDetails() {
      let app = this;
      return axios.get("/get-policy-list").then((response) => {
        app.product_detail = response.data.data.policy_details;
        let status = false;
        app.product_detail?.map((policy) => {
          if (policy.status == "ACTIVE") {
            policy.plans?.map((plan) => {
              if (plan.status == "ACTIVE" && plan.plan_type == "MM" && !(plan.cid == 77)) {
                status = true;
              }
            });
          }
        });
        this.$emit("setPlanType", status);
        app.skeletonLoader = false;
        this.setActivePlanId();
        this.setActivePloicyId();
      });
    },
    getTermDate(policy_id) {
      let app = this;
      return axios
        .get("/get-term-request-details?policy_id=" + policy_id)
        .then((response) => {
          app.term_dates = response.data.data.term_request_date;
        });
    },
    showTerminateFormModel(policy_id) {
      this.getTermDate(policy_id);
      let policyData = this.product_detail.find(
        (data) => data.policy_id == policy_id
      );
      this.form_policy_update.policy_id = policy_id;
      this.policy_details_individual = { ...policyData };
      this.$bvModal.show("terminate_form");
    },
    showActivateFormModel(plan, policy_id) {
      this.current_plan = plan;
      this.current_policy_id = policy_id;
      this.$bvModal.show("activate_form");
    },
    terminatePlanPolicy(bvModalEvt) {
      let app = this;
      bvModalEvt.preventDefault();
      let formData = this.form_policy_update;
      bvModalEvt.preventDefault();
      this.$v.form_policy_update.$touch();
      if (this.$v.form_policy_update.$anyError) {
        return;
      }
      this.formSubmitted = true;
      this.skeletonLoader = true;
      if (formData.term_reason == "Others") {
        formData.term_reason = formData.other_reason;
      }
      axios
        .post("/send-termination-request", formData)
        .then((response) => {
          if (response.data.status == "success") {
            helper.infoMessage(
              app,
              response.data.status,
              response.data.message
            );
            this.getProductDetails();
            this.resetFormFields();
          }
        })
        .catch((error) => {
          if (error.response.data.status == "error") {
            helper.infoMessage(app, "error", error.response.data.message);
          }
        })
        .finally(() => {
          this.formSubmitted = false;
          this.skeletonLoader = false;
        });
    },
    resetFormFields() {
      this.$bvModal.hide("terminate_form");
      this.$v.form_policy_update.$reset();
      this.form_policy_update = resetForm();
    },
    getSign(sign = "") {
      this.form_policy_update.sign = sign;
    },
    validateState(name, form) {
      const { $dirty, $error } = this.$v.form_policy_update[name];
      return $dirty ? !$error : null;
    },
    setActivePlanId() {
      let userActivePlanId = [];
      if (this.product_detail.length) {
        this.product_detail.forEach((policy) => {
          if (policy.status.toLowerCase() == "active" && policy.plans.length) {
            policy.plans.forEach((plan) => {
              if (plan.status.toLowerCase() == "active") {
                userActivePlanId.push(plan.plan_id);
              }
            });
          }
        });
      }
      this.$store.commit("setActivePlanId", userActivePlanId);
    },
    setActivePloicyId() {
      let userActivePolicyId = [];
      if (this.product_detail.length) {
        this.product_detail.forEach((policy) => {
          if (policy.status.toLowerCase() == "active") {
            userActivePolicyId.push(policy.policy_id);
          }
        });
      }
      this.$store.commit('setActivePolicyId',userActivePolicyId)
    }
  },
  validations: {
    form_policy_update: {
      term_reason: {
        required,
      },
      term_date: {
        required,
      },
      plan_id: {
        required,
      },
      sign: {
        required,
      },
      other_reason: {
        required: requiredIf(function () {
          return this.form_policy_update.term_reason == "Others";
        }),
      },
    },
  },
};
</script>

<style>
.btn-activate {
  background-color: var(--secondary-color);
  color: #ffffff;
  border: none;
  min-width: 37px;
  padding: 6px 5px;
  font-weight: 700;
  padding-bottom: 5px;
  font-size: 11px;
  border-radius: 3px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  margin-left: 5px;
  margin-right: 5px;
}
.btn-activate:focus{
 outline: none;
}
.btn-activate:hover {
  opacity: 0.8;
}
.new-table-div .new-table .table-head-div div.status {
  min-width: 10% !important;
  text-align: left;
}
.new-table-div .new-table .table-head-div div.effective-date,
.new-table-div .new-table .product-list div.action,
.new-table-div .new-table .table-head-div div.action,
.tire,
.monthly-premium,
.effective-date p
{
  text-align: left;
}
.status .plan_active {
  text-align: start;
  margin-left: 22px;
}
.effective-date p,
.plan p {
  margin-bottom: 16px;
  padding-top: 7.5px;
}
.tire,
.monthly-premium {
  padding-top: 4px;
}
.new-table-div .new-table .product-list div.status {
  min-width: 12% !important;
}
.status .plan_active {
  padding-top: 4px;
}
/* .reactive{
  padding: 0px;
}
.reactive-txt{
  padding-right: 5px;
} */
.plan_inactive_red {
  /*text-align: start;*/
}
.disclaimer-text{
   font-size: 12px;
    background-color: #ffa5002e;
    color: #a56e0b;
    padding: 0.5rem 0.8rem;
    border-radius: 6px;
    border: 1px solid orange;
    margin-top: -23px;
}
</style>
