import { render, staticRenderFns } from "./reviewFooter.vue?vue&type=template&id=cebba818&scoped=true"
import script from "./reviewFooter.vue?vue&type=script&lang=js"
export * from "./reviewFooter.vue?vue&type=script&lang=js"
import style0 from "../../assets/css/review_rating/review_content.css?vue&type=style&index=0&id=cebba818&prod&scoped=true&lang=css&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cebba818",
  null
  
)

export default component.exports