<template>
    <div class="page-wrapper document-view-details">
        <div class="container-fluid">
            <!--<div class="page-title">
                <h1>Document View Details</h1>
            </div> -->
            <div class="row body-main-row">
                <div class="member-body-content-wrap member_dashboard pageWrap mt-0">
          <b-skeleton-table
          v-if="skeletonLoader"
          :rows="15"
          :columns="1"
          :table-props="{ bordered: none, striped: true }">
          </b-skeleton-table>
                    <div class="document-view-body" v-if="!skeletonLoader">
                        <p><img class="elevate-logo" src="@/assets/images/darknuera.png"></p>
                        <div class="directory-block">
                            <h2>{{ data.user_info.first_name }}  {{data.user_info.last_name }}</h2>
                            <ul>
                                <li>{{ data.user_info.address1 }}</li>
                                <li>{{ data.user_info.city }}, {{ data.user_info.state }} {{ data.user_info.zip }}</li>
                            </ul>
                        </div>
                        <p v-if="data.is_nawu" style="text-align:right">December 1st , 2023</p>
                        <p v-else style="text-align:right">November 30th, 2023</p>

                        <p><strong>Dear Valued Member,</strong></p>
                        <div v-if="data.is_nawu">
                        <p>As of January 1st 2024 the <strong>{{ data.old_plan_name }}</strong> will renew. Your rate as of January 1st 2024 is listed below. These rates include Association fees, Administrative fees and union dues.
                        <br>
                        The increase for 2024 is 18%. Although the increase is larger than other years the benefits remain the same for 2024, which considering the current health climate is rare as most general plans are removing benefits.
                        <br>
                        Please note the renewal is automatic and no additional paperwork is required.
                        <br>
                        Billing will be adjusted accordingly on your upcoming invoice or Electronic Payment (EFT)
                        </p>

                        </div>
                        <div v-else>
                        <p v-if="data.is_northwell">As of January 1st 2024 the <strong>{{ data.old_plan_name }}</strong> will be renewing.
                            We are happy to say that your plan is receiving a huge network upgrade from the Existing Emblem network to a Dual network of Northwell Health & Anthem BCBS. 
                            You can freely choose which network you utilize at your discretion at the time of service. The plan does lose it out of network benefits as it now an EPO (no referrals for in network providers). However, the Network upgrade ensures you have access to a nationwide network of Anthem BCBS providers as well as Northwell health providers in the tri state area.
                            <br/>
                            The increase is 5% depending on plan and tier. However, the ACA has increased their tax on private plans 2% and this is unavoidable. This raises the increase to 7% respectively. 
                            Your new rate is listed below.</p>

                        <p v-else>As of January 1st 2024 the <strong>{{ data.plan_name }}</strong> will be renewing. We are happy to say all benefits remain the same for 2024 with no benefit changes or network changes, your new rate is listed below.
                            <br/>
                        The increase is 10% depending on plan and tier. However, the ACA has increased their tax on private plans 2% and this is unavoidable. This raises the increase to 12% respectively. 
                      </p>
                        <!-- <p style="font-style:italic;font-weight:500; margin-top:2%;margin-bottom:2%">
                            *Please note in addition to this year’s increase for 2023, a Federally mandated Hospital Price Transparency platform has been added.  Hospital transparency helps Americans know the cost of a hospital item or service before receiving coverage.  Starting January 1, each hospital in the United States will be required to provide clear, accessible pricing information online about the items and services they provide.  This information will make it easier for consumers to shop and compare prices as well as estimate the out-of-pocket cost of care before going in the hospital.  
                        </p> -->
                        
                        <p>There is nothing to do on your end as renewal is automatic; this is just formal notification of the rate change for 2024.</p>
                        <!-- <p>The plan benefits and PPO network will remain identical to 2020. We were able to keep the renewal under 7% which was below the industry average. The Rx plan will change vendors to EMPIRx and you will receive new ID cards to reflect the change.</p> -->
                        <p>Billing will be adjusted accordingly on your upcoming Statement or Electronic Payment (EFT).</p>
                        <br>
                        <p><strong><u>Your new rate for your medical benefits plan as of January 1st, 2024 will be as follows:</u></strong></p>
                        </div>
                        <div class="new-rate-details">
                            <ul>
                                <li>Plan: <strong>{{ data.plan_name }}</strong></li>
                                <li>Tier: <strong>{{ data.tier_info }}</strong></li>
                                <li>New Rate: <strong>${{data.new_rate}}</strong></li>
                            </ul>
                        </div>
                        <div v-if="data.is_nawu">
                        <p>This rate will be good until next renewal.
                            <br>
                            If you have any questions or want to discuss other plan options, please contact your broker.
                        </p>
                        </div>
                        <div v-else>
                        <p class="osf"><em>This new rate will be good until next renewal on <strong>December 31st, 2024.</strong></em></p>
                        <p class="osf"><em>If you have any questions or want to change your plan design and possibly decreasing your rate, you may contact your broker.</em></p>
                        <br>
                        </div>
                       <p> <span>Broker: <b>{{ data.agent_name }}</b></span>    <span style="margin-left: 14%;">Phone : <b>{{ data.agent_phone }}</b></span> </p>
                        <br>
                        <div class="doc-footer">
                            <p><strong>Sincerely,</strong></p>
                            <!--<p class="osf">Elevate to Wellness</p> -->
                            <p class="osf">NEB Billing Services</p>
                        </div>
                
                    </div>
                </div>
                
            </div>
           
        </div>
    </div>
</template>
<script>
import axios from 'axios';
/*eslint-disable*/
export default {
    name: 'DocumentViewDetails',
   data: () => ({
        data: [],
        skeletonLoader: true,
    }),
     methods: {
        async getData(){
            //let loader = this.$loading.show();
            await axios
            .get('/get-new-rate-details')
            .then((res) => {
                this.data = res.data.data;
            })
            .finally(() => {
                this.skeletonLoader = false
               // loader.hide();
            });
        },
    },
    created() {
    this.getData();
  },
}
</script>

<style>
.document-view-body {
    background: #fff;
    box-shadow: 0 2px 15px rgb(216 227 235 / 33%);
    padding: 30px;
    padding-top: 20px;
    margin-top: 20px;
    border-radius: 15px;
}
.document-view-body p {
    font-size: 14px;
    margin-bottom: 10px;
}
.new-rate-details {
    padding: 30px;
    max-width: 800px;
    margin-bottom: 13px;
    background: #f3f7fa;
    border-radius: 10px;
    margin-top: 15px;
}
.document-view-body .osf {
    opacity: 0.7;
    margin-bottom: 5px;
}
.new-rate-details ul {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: space-between;
}
.new-rate-details ul li {
    display: inline-block;
}
.new-rate-details strong {
    color: #03c3ec;
}
.doc-footer p {
    margin-bottom: 4px;
}
.doc-footer p:first-child {
    margin-top: 2%;
}
.doc-footer .osf {
    margin-bottom: 2px;
}
.elevate-logo {
    width: 10%;
    display: block;
    margin: 0px auto;
    margin-top:2%
}
.directory-block {margin-bottom:3%}
</style>