<template>
  <div class="dashboard-new">
    <div class="header-new">
      <div class="container-fluid">
        <div class="row justify-content-between">
          <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-7 main-logo">
            <router-link :to="{ name: 'DashboardNew' }">
              <img src="../assets/images/logo-iha.svg" alt="" />
            </router-link>
          </div>

          <div class="col-xl-7 col-lg-6 col-md-5 col-sm-12 login-status">
            <ul>
              <li>
                <p><span>IP Address: </span>{{ user.ip }}</p>
              </li>
              <li>
                <p>
                  Last login: <span>{{ user.last_login }}</span>
                </p>
              </li>
            </ul>
          </div>

          <div class="col-xl-2 col-lg-3 col-md-3 col-sm-6 col-5 top-user">
            <ul>
              <!-- <li class="top-notification"><a href="javascript:void(0);"><i class="far fa-bell"></i></a><span></span></li> -->
              <li class="top-user-dropdown">
                <img
                  :src="user.image || '/img/default_profile.52292c62.jpg'"
                  alt="profile"
                />
                <span class="header-user-name"
                  >&nbsp;&nbsp;{{ user.name }}</span
                >
                <b-dropdown right>
                  <b-dropdown-item
                    v-b-modal.changePassword
                    href="javascript:void(0);"
                    >Change Password</b-dropdown-item
                  >
                  <b-dropdown-item
                    v-b-modal.changeEmail
                    href="javascript:void(0);"
                    >Change Login Email</b-dropdown-item
                  >
                  <b-dropdown-divider></b-dropdown-divider>
                  <b-dropdown-item href="javascript:void(0);" @click="logout()"
                    >Logout</b-dropdown-item
                  >
                  <b-dropdown-divider
                    class="responsive-view-only"
                  ></b-dropdown-divider>
                  <b-dropdown-item
                    class="responsive-view-only"
                    href="javascript:void(0);"
                    ><p>
                      <span>IP Address: </span>{{ user.ip }}
                    </p></b-dropdown-item
                  >
                  <b-dropdown-item
                    class="responsive-view-only"
                    href="javascript:void(0);"
                    ><p>
                      Last login: <span>{{ user.last_login }}</span>
                    </p></b-dropdown-item
                  >
                </b-dropdown>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!-- change email modal starts -->
    <change-email-modal />
    <!-- change password ends-->

    <!-- change password starts -->
    <change-password-modal />
    <!-- change password ends -->
    <SideNav />
  </div>
</template>
<script>
/* eslint-disable */
import axios from "axios";
import helper from "../resource/Helper";
import store from "../store/store";
import SideNav from "@/components/SideNav.vue";
import { mapGetters } from "vuex";
import ChangeEmailModal from "./partial/ChangeEmailModal.vue";
import ChangePasswordModal from "./partial/ChangePasswordModal.vue";
export default {
  components: {
    SideNav,
    ChangeEmailModal,
    ChangePasswordModal,
  },
  data() {
    return {
      notification: [],
      formErrorFlag: false,
      loginLoader: false,
      formErrors: [],
      profilePic: store.state.profilePic,
    };
  },
  computed: {
    ...mapGetters({ user: "user" }),
  },
  methods: {
    logout() {
      helper.logout();
    },
    getNotificationList() {
      let app = this;
      let param = "?page=1&page_size=3&search=";
      return axios.get(`/read-unread-notification${param}`).then((response) => {
        app.notification = response.data.records;
      });
    },
  },
  watch: {
    "$store.state.accessToken": {
      handler: function (accessToken) {
        if (accessToken != "") {
          this.getNotificationList();
        }
      },
      immediate: true, // provides initial (not changed yet) state
    },
  },
};
</script>
<style scoped>
.notify_group {
  display: flex;
  margin: 15px 0;
}
.notify_icon {
  border-radius: 50%;
  background: transparent
    linear-gradient(180deg, #00ccff 0%, #1597b8 49%, #0567bc 100%) 0% 0%
    no-repeat padding-box;
  width: 75px;
  height: 40px;
  margin-top: 6px;
  margin-right: 10px;
}
.notify_icon {
  width: 25px;
  height: 25px;
  margin-top: -5px;
}
.notify_detail h6 {
  font-size: 14px;
  font-family: "SF UI display Bold";
  color: #555555;
  margin-bottom: 0;
}
.notify_detail p {
  font-size: 12px;
  color: #909090;
  margin-top: 0;
}
#popover_1 .popover_submit {
  background: #00ccff;
  border-radius: 8px;
  padding: 3px 25px;
  float: right;
  margin-bottom: 10px;
  border: none;
}
#popover_1 .popover_submit:focus {
  outline: none;
}
.form_change {
  width: 100%;
}
.modal-body .form-group {
  width: 100%;
  padding: 0 10px;
}
.login_form {
  padding: 0px;
}
.toggleSSN {
  position: absolute;
  left: 87%;
  margin-top: -7.5%;
}

.input_form button {
  margin-top: 10 px;
  padding: 10 px;
  width: 100%;
  font-size: 18px;
  background: #00ccff;
  color: white;
}
</style>
<style scoped>
@import "../assets/css/dashboard-new.css";
</style>
