<template>
  <div
    class="billing_invoice_new dashboard-card dashboard-card-lt mt-4"
    v-if="show_hide.billing_details"
  >
    <div class="billing_invoice_new-title d-flex">
      <div>
        <h3>Billing & Invoices</h3>
        <div class="policy_id_selector">
          <b-form-select
            @change="getByPolicyID"
            v-model="selected"
            :options="options"
          >
            <template #first>
              <b-form-select-option :value="null"
                >Select System ID</b-form-select-option
              >
            </template>
          </b-form-select>
        </div>
      </div>
      <payment-history-download />
    </div>

    <div class="billing_invoice_new_table_wrap">
      <div class="billing_invoice_new_table_inner">
        <div class="table_content">
          <b-skeleton-table
            v-if="skeletonLoader"
            :rows="4"
            :columns="4"
            :table-props="{ bordered: true, striped: true }"
          ></b-skeleton-table>
          <table v-if="skeletonLoader == false">
            <thead>
              <tr>
                <td>Invoice</td>
                <td>Date/Term</td>
                <td>Totals</td>
                <td>Status</td>
                <td>Payments</td>
                <td>Action</td>
              </tr>
            </thead>
            <tbody v-for="(item, index) in billing_invoice" :key="index">
              <!-- <tr class="paid" v-if="item.invoice_details.invoice_status == 'PAID'"> -->
              <tr
                :class="
                  item.invoice_details.invoice_status == 'UNPAID'
                    ? 'unpaid'
                    : 'paid'
                "
              >
                <td>
                  <span> {{ item.invoice_details.invoice_id }} </span>
                  <span>
                    {{ "System ID: " + item.invoice_details.invoice_policy_id }}
                  </span>
                  <span>
                    {{
                      item.invoice_details.term_status
                        ? item.invoice_details.term_status +
                          " [" +
                          item.invoice_details.term_date +
                          "]"
                        : ""
                    }}
                  </span>
                </td>
                <td>
                  <span>
                    {{
                      "Invoice Date: " + item.invoice_details.invoice_date
                    }}</span
                  >
                  <span>
                    {{
                      "Invoice Due Date: " +
                      item.invoice_details.invoice_due_date
                    }}</span
                  >
                  <span> Service Date: </span>
                  <span>{{
                    item.invoice_details.invoice_start_date +
                    " - " +
                    item.invoice_details.invoice_end_date
                  }}</span>
                </td>
                <td>
                  <span v-if="item.invoice_details.efee != '$0.00'">
                    {{ "Efees Amount: " + item.invoice_details.efee }}</span
                  >
                  <span v-if="item.invoice_details.late_fee != '$0.00'">
                    {{
                      "Late Fee Amount: " + item.invoice_details.late_fee
                    }}</span
                  >
                  <span v-if="item.invoice_details.bounce_fee != '$0.00'">
                    {{
                      "Bounce Fee Amount: " + item.invoice_details.bounce_fee
                    }}</span
                  >
                  <span v-if="item.invoice_details.statement_fee != '$0.00'">
                    {{
                      "Statement Fee Amount: " +
                      item.invoice_details.statement_fee
                    }}</span
                  >
                  <span v-if="item.invoice_details.policy_amount != '$0.00'">
                    {{
                      "Policy Amount: " + item.invoice_details.policy_amount
                    }}</span
                  >
                  <span v-if="item.invoice_details.previous_due != '$0.00'">
                    {{
                      "Previous Due Amount: " +
                      item.invoice_details.previous_due
                    }}</span
                  >
                  <span v-if="item.invoice_details.overall_amount != '$0.00'">
                    {{
                      "Overall Amount: " + item.invoice_details.overall_amount
                    }}</span
                  >
                  <span v-if="item.invoice_details.paid_amount != '$0.00'">
                    {{
                      "Paid Amount: " + item.invoice_details.paid_amount
                    }}</span
                  >
                  <span
                    v-if="item.invoice_details.processing_amount != '$0.00'"
                  >
                    {{
                      "Processing Amount: " +
                      item.invoice_details.processing_amount
                    }}</span
                  >
                  <span style="border-top: 2px dotted grey; width: 80%"></span>
                  <span
                    >{{
                      "Overall Due Amount :" +
                      item.invoice_details.overall_due_amount
                    }}
                  </span>
                </td>
                <td>
                  <strong>{{ item.invoice_details.invoice_status }}</strong>
                </td>
                <td>
                  <div
                    v-for="(billingData, billingIndex) in item.billing_details"
                    :key="billingIndex"
                  >
                    <span
                      >{{ "Payment Date: " + billingData.payment_date }}
                    </span>
                    <span>{{ "Payment Method: " + billingData.method }} </span>
                    <span
                      >Payment Status:<strong>
                        {{ billingData.payment_status }}</strong
                      >
                    </span>
                    <span
                      >{{ "Payment Type: " + billingData.payment_type }}
                    </span>
                    <span
                      v-for="(
                        additionalData, additionalIndex
                      ) in billingData.additional_data"
                      :key="additionalIndex"
                    >
                      {{ additionalData.key + ": " }} {{ additionalData.value }}
                    </span>
                    <span
                      v-if="billingIndex + 1 != item.billing_details.length"
                      style="border-top: 2px dotted black; width: 76%"
                    ></span>
                  </div>
                </td>
                <td class="pay">
                  <span class="pay-btn"
                    ><button
                      v-if="item.invoice_details.pay_now"
                      v-b-modal.paymentDailog
                      @click="payNow(item.invoice_details.invoice_id)"
                    >
                      Pay Now
                    </button>
                  </span>
                  <span class="download-btn"
                    ><button
                      v-if="item.invoice_details.file_link != ''"
                      @click="downloadFile(item.invoice_details.file_link)"
                    >
                      <svg
                        version="1.1"
                        id="Capa_1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        x="0px"
                        y="0px"
                        viewBox="0 0 482.14 482.14"
                        style="enable-background: new 0 0 482.14 482.14"
                        xml:space="preserve"
                      >
                        <g>
                          <path
                            d="M142.024,310.194c0-8.007-5.556-12.782-15.359-12.782c-4.003,0-6.714,0.395-8.132,0.773v25.69
                          c1.679,0.378,3.743,0.504,6.588,0.504C135.57,324.379,142.024,319.1,142.024,310.194z"
                          />
                          <path
                            d="M202.709,297.681c-4.39,0-7.227,0.379-8.905,0.772v56.896c1.679,0.394,4.39,0.394,6.841,0.394
                          c17.809,0.126,29.424-9.677,29.424-30.449C230.195,307.231,219.611,297.681,202.709,297.681z"
                          />
                          <path
                            d="M315.458,0H121.811c-28.29,0-51.315,23.041-51.315,51.315v189.754h-5.012c-11.418,0-20.678,9.251-20.678,20.679v125.404
                          c0,11.427,9.259,20.677,20.678,20.677h5.012v22.995c0,28.305,23.025,51.315,51.315,51.315h264.223
                          c28.272,0,51.3-23.011,51.3-51.315V121.449L315.458,0z M99.053,284.379c6.06-1.024,14.578-1.796,26.579-1.796
                          c12.128,0,20.772,2.315,26.58,6.965c5.548,4.382,9.292,11.615,9.292,20.127c0,8.51-2.837,15.745-7.999,20.646
                          c-6.714,6.32-16.643,9.157-28.258,9.157c-2.585,0-4.902-0.128-6.714-0.379v31.096H99.053V284.379z M386.034,450.713H121.811
                          c-10.954,0-19.874-8.92-19.874-19.889v-22.995h246.31c11.42,0,20.679-9.25,20.679-20.677V261.748
                          c0-11.428-9.259-20.679-20.679-20.679h-246.31V51.315c0-10.938,8.921-19.858,19.874-19.858l181.89-0.19v67.233
                          c0,19.638,15.934,35.587,35.587,35.587l65.862-0.189l0.741,296.925C405.891,441.793,396.987,450.713,386.034,450.713z
                          M174.065,369.801v-85.422c7.225-1.15,16.642-1.796,26.58-1.796c16.516,0,27.226,2.963,35.618,9.282
                          c9.031,6.714,14.704,17.416,14.704,32.781c0,16.643-6.06,28.133-14.453,35.224c-9.157,7.612-23.096,11.222-40.125,11.222
                          C186.191,371.092,178.966,370.446,174.065,369.801z M314.892,319.226v15.996h-31.23v34.973h-19.74v-86.966h53.16v16.122h-33.42
                          v19.875H314.892z"
                          />
                        </g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                      </svg>
                      Download
                    </button>
                  </span>
                  <span
                    v-if="
                      item.invoice_details.invoice_status == 'PAID' &&
                      item.invoice_details.paystand_id
                    "
                    class="pay-receipt-btn"
                  >
                    <button
                      @click="
                        routeToPaymentDownload(item.invoice_details.paystand_id)
                      "
                    >
                      Payment Receipt
                    </button>
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="overflow-auto">
      <!-- Use text in props -->
      <b-pagination
        v-model="pagination.current"
        :total-rows="pagination.total"
        first-text="First"
        prev-text="Prev"
        next-text="Next"
        last-text="Last"
        :per-page="pagination.perPage"
        style="justify-content: center; margin-top: 20px"
        :page-click="changePage"
        :bvEvent="changePage"
        @change="changePage"
      ></b-pagination>
    </div>

    <b-modal
      id="paymentDailog"
      :centered="true"
      title="Payment"
      :hide-footer="true"
      @hidden="paymentMade()"
      size="md"
      no-close-on-backdrop
    >
      <div class="modal-body">
        <div class="embed-responsive embed-responsive-16by9">
          <iframe
            class="embed-responsive-item"
            :src="paymentUrl"
            allowfullscreen
          ></iframe>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";
import PaymentHistoryDownload from "./partials/PaymentHistoryDownload.vue";
/* eslint-disable */
export default {
  data() {
    return {
      paymentUrl: "",
      billing_invoice: [],
      billing_invoice_all: [],
      skeletonLoader: true,
      pagination: {
        current: 1,
        total: 0,
        perPage: 5,
      },
      selected: null,
      options: [],
      show_hide: {
        billing_details: true,
      },
      paymentReceipttUrl: process.env.VUE_APP_PAYMENT_RECEIPT_URL,
    };
  },
  components: {
    PaymentHistoryDownload,
  },
  async mounted() {
    this.getInvoiceBillingData();
    this.getPolicyWithInvoiceDates();
  },
  methods: {
    async getInvoiceBillingData(policyID = "") {
      let app = this;
      app.skeletonLoader = true;
      policyID = policyID == null ? "" : "?policy_id=" + policyID;
      return axios
        .get("/get-invoice-details-all" + policyID)
        .then((response) => {
          this.show_hide.billing_details = response.data.data.length;
          app.billing_invoice_all = response.data.data;

          /*let dates = [];
          app.billing_invoice_all.map(data => {
            if(!dates.includes(data.invoice_details.invoice_policy_id)) { dates.push(data.invoice_details.invoice_policy_id)}
          })
          this.options = dates; */
          app.billing_invoice = app.billing_invoice_all.slice(
            0,
            app.pagination.perPage
          );
          app.pagination.total = response.data.data.length;
          app.skeletonLoader = false;
        })
        .catch((err) => {
          this.show_hide.billing_details = false;
        });
    },
    getPolicyWithInvoiceDates() {
      let app = this;
      return axios.get("/get-policy-latest-inovice-date").then((response) => {
        response.data.data.map((dat) => {
          app.options.push({ value: dat.policy_id, text: dat.policy_id });
        });
      });
    },
    downloadFile(fileUrl) {
      window.open(fileUrl, "_blank");
    },
    routeToPaymentDownload(paymentId) {
      let app = this;
      window.open(`${app.paymentReceipttUrl}${paymentId}`, "_blank");
    },
    payNow(invoiceId) {
      //this.paymentUrl = process.env.VUE_APP_PAYMENT_URL + "N" + invoiceId;
      this.paymentUrl = process.env.VUE_APP_PAYMENT_URL + btoa(invoiceId);
      //console.log(this.paymentUrl);
    },
    paymentMade() {
      this.getInvoiceBillingData();
    },
    changePage(pageNumber) {
      pageNumber =
        pageNumber === 1 ? 0 : (pageNumber - 1) * this.pagination.perPage;
      this.billing_invoice = this.billing_invoice_all.slice(
        pageNumber,
        this.pagination.perPage + pageNumber
      );
    },
    getByPolicyID(policyID) {
      this.getInvoiceBillingData(policyID);
    },
  },
};
</script>

<style>
.page-item.active .page-link {
  background: #00ccff !important;
}
.page-link {
  padding: 10px 15px !important;
}
.page-item button {
  width: 50px !important;
  height: 100% !important;
  border: none !important;
  border-radius: 0 !important;
}

.policy_id_selector .custom-select:focus {
  box-shadow: none;
  border-color: var(--secondary-color);
}

.policy_id_selector .custom-select option {
  background: var(--secondary-color);
}

@media only screen and (max-width: 760px) {
  .page-link {
    padding: 5px 10px !important;
  }
  .page-item button {
    width: 40px !important;
  }
}
</style>

<style scoped>
tr {
  vertical-align: baseline;
}
td span {
  display: block;
}
.pay {
  vertical-align: middle;
}
.pay span {
  padding-bottom: 10px;
}
.modal-dialog-centered {
  height: 150%;
}
.embed-responsive {
  min-height: 500px;
}

#paymentDailog___BV_modal_body_ {
  padding: 0px;
}
.policy_id_selector {
  width: 20%;
}
.policy_id_selector .mb-2 {
  font-size: 16px;
}
.billing_invoice .active {
  width: 1% !important;
}
@media only screen and (max-width: 1435px) {
  .policy_id_selector .mb-2 {
    font-size: 13px;
  }
}
.d-flex {
  justify-content: space-between;
}
</style>
