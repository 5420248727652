var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('footer', [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12"
  }, [_vm._m(0), _c('p', [_vm._v("If you have some technical difficulties Go to: "), _c('a', {
    attrs: {
      "href": _vm.ticketing_url,
      "target": "_blank"
    }
  }, [_vm._v("Issues Dashboard")])])])])]), _vm._m(1)]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "footer-logo"
  }, [_c('img', {
    attrs: {
      "src": require("../../assets/images/corenroll-logo.png"),
      "alt": ""
    }
  })]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "copyright"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('p', [_vm._v("© Copyright 2021. All Rights Reserved.")])])]);

}]

export { render, staticRenderFns }