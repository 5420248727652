<template>
  <div class="container-fluid">
    <div class="body-main-row">
      <div class="member-body-content-wrap add-request">
        <div class="pageWrap">
          <div class="">
            <button class="btn-new-request">
              <a v-b-modal.new_request>Add New Request
              <i class="fas fa-plus-circle ml-1"></i></a>
            </button>
          </div>
          <div style="width: 100%;">
           
            <AddRequestModal :policyIds="policyIds" :getAll="getAll" />
            <AddRequestTable :rows.sync="rows" />
          </div>
          <div class="overflow-auto">
        <pagination :rows.sync="rows" :filters="[]"/>
      </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import AddRequestModal from "./AddRequestModal.vue";
import Helper from "@/resource/Helper";
import AddRequestTable from "./AddRequestTable.vue";

export default {
  components: {
    AddRequestModal,
    AddRequestTable
  },
  data() {
    return {
      members: [],
      policyIds: {},
      records: [],
      rows:[],
    };
  },
  methods: {
    async getMember() {
      let url = "get-policy-list";
      await axios
        .get(url)
        .then((response) => {
          let members = [];
          members = response.data.data.policy_details;
          members.forEach((policy) => {
            this.policyIds[policy.policy_id] = policy.policy_id;
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getAll(filters = null) {
      let url;
      let loader = this.$loading.show();
      if (filters) {
        let filterUrl = Helper.objToUrlParams(filters);
        url = `request/list?${filterUrl}`;
      } else {
        url = `request/list`;
      }

      axios
        .get(url)
        .then((res) => {
          this.rows=res.data;
          this.records = res.data.records;
        })
        .catch(() => {
          this.rows = [];
        })
        .finally(() => {
          loader.hide();
        });
    },
  },
  created() {
    this.getMember();
    this.getAll();
  },
};
</script>

<style>
.btn-new-request {
  background: var(--secondary-color);
  border-radius: 6px;
  color: #fff;
  padding: 0.5rem 16px;
  border: none;
}
</style>
