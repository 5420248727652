<template>
    <div class="content_wrapper">
         <agent-info></agent-info>
        <div class="contract_content" ref="save">
            <contract-form-sidebar/>

           <div class="right_container">
                   <h3>Contact Information</h3>
                   <div class="form">
                    <div>
                        <label>Business or Corporate Name</label><br>
                        <input type="text" name="business_name" v-model="formFields.business_name.value" value="" :readonly="formFields.business_name.read_only == true">
                        <small class="error_message" v-if="formErrorFlag == true">{{formErrors['business_name_message']}}</small>
                    </div>

                    <div>
                        <label>Email</label><br>
                        <input type="text" name="email" v-model="formFields.email.value" value="" :readonly="formFields.email.read_only == true">
                        <small class="error_message" v-if="formErrorFlag == true">{{formErrors['email_message']}}</small>
                    </div>

                    <div>
                        <label>Telephone Number</label><br>
                        <input type="text" name="telephone" v-model="formFields.telephone.value" value="" :readonly="formFields.telephone.read_only == true">
                        <small class="error_message" v-if="formErrorFlag == true">{{formErrors['telephone_message']}}</small>
                    </div>

                    <div>
                        <label>Cell Phone Number</label><br>
                        <input type="text" name="cell_phone" v-model="formFields.cell_phone.value" value="" :readonly="formFields.cell_phone.read_only == true">
                        <small class="error_message" v-if="formErrorFlag == true">{{formErrors['cell_phone_message']}}</small>
                    </div>

                    <div>
                        <label>Fax Number</label><br>
                        <input type="text" name="fax" v-model="formFields.fax.value" value="" :readonly="formFields.fax.read_only == true">
                        <small class="error_message" v-if="formErrorFlag == true">{{formErrors['fax_message']}}</small>
                    </div>
                   </div> 

                   <div class="button_container">
                    <button class="back" type="button" name="button" @click="navigatePrevious">Back</button>
                    <div class="button">
                    <!-- <a href="#"><button class="save">Save</button></a> -->
                    <button class="next" type="button" name="button" @click="saveContactInfo">Next</button>
                </div>
                </div>

            </div>
        </div>
    </div>
</template>



<style src="../../assets/css/contract_form/contact_info.css" scoped>

</style>
<script>
    import ContractFormSidebar from '../partial/ContractFormSidebar';
    import AgentInfo from "../common/AgentInfo.vue"
    import axios from 'axios';
    import html2canvas from 'html2canvas';
    export default{
        components:{ AgentInfo, ContractFormSidebar },
        data: function(){
            return{
                tempId: '',
                formFields: {
                    business_name: {
                        value: '',
                        required: false,
                        read_only: false,
                    },
                    email: {
                        value: '',
                        required: false,
                        read_only: false,
                    },
                    telephone: {
                        value: '',
                        required: false,
                        read_only: false,
                    },
                    cell_phone: {
                        value: '',
                        required: false,
                        read_only: false,
                    },
                    fax: {
                        value: '',
                        required: false,
                        read_only: false,
                    },
              },
              formErrorFlag: false,
              formErrors: [],
            }
        },
        mounted(){
            let app = this;
            app.tempId= window.localStorage.getItem('tempId');
            app.getContactInfo();
        },
        methods:{
            getContactInfo: function () {
                let loader = this.$loading.show();
                let app = this;
                axios.get(process.env.VUE_APP_CORENROLL_SYSTEM+'/get-form-data-contact-info?', {
                params: {
                    tempid: app.tempId,
                }
                })
                .then(function (response) {
                    console.log(response);
                    let fetchedData = [];
                    if (response.data.status == 'success') {
                        for(var i in response.data.data.result) {
                            fetchedData.push(response.data.data.result[i]);
                        }
                        console.log(fetchedData);
                        // app.tempId = response.data.tempid;
                        fetchedData.forEach(function(item) {
                          console.log(item);
                            // let key = item[0];
                            // let val = item[0]['value'];
                            app.formFields[item.field_name].value = item.value;
                            app.formFields[item.field_name].read_only = item.read_only;
                        });
                    }
                    })
                    .catch(function (error) {
                        if(error.response.data.message == 'Form completed.'){
                      window.location = '/contract-form/contract_message/completed';
                  }else if(error.response.data.message == 'Agent does not exist.'){
                      window.location = '/contract-form/contract_message/not_found';
                  } else {
                    window.location = '/contract-form/contract_message/error';
                  }
                }).finally(() => {
                loader.hide();
                });
            },
            saveContactInfo: function () {
                let loader = this.$loading.show();
                const el = this.$refs.save;
                (async () => {
                    this.saveScreenShot((await html2canvas(el)).toDataURL('image/png'), 'contact');
                })();
                let app = this;
                app.formErrorFlag = false;
                app.formErrors    = [];
                let params = {
                  tempid: app.tempId,
                  business_name:    app.formFields.business_name.value,
                  email:            app.formFields.email.value,
                  telephone:        app.formFields.telephone.value,
                  cell_phone:       app.formFields.cell_phone.value,
                  fax:              app.formFields.fax.value,
                };
                //discard null values
                for (const key of Object.keys(params)) {
                  if (params[key] === null) {
                      delete params[key];
                  }
                }

                axios.post(process.env.VUE_APP_CORENROLL_SYSTEM+'/save-contact-information', params)
                .then(function (response) {
                    if (response.data.status == 'success') {
                        console.log("success");
                        app.$router.push("address_info");
                        window.localStorage.setItem('contact_complete', 'complete');
                    }
                })
                .catch(function (error) {
                    console.log(error.response.data.data);
                    if (error.response.data.status == 'error') {
                      error.response.data.data.forEach(function(item) {
                          app.formErrorFlag = true;
                          app.formErrors[item.target_element+'_message'] = item.error_message;
                          window.localStorage.setItem('contact_complete', 'incomplete');
                      });
                    }
                }).finally(() => {
                loader.hide();
                });
            },
            navigatePrevious() {
                //Update APIs
                //Navigate to next page
                let contract_id = btoa(window.localStorage.getItem('contract_id'));
                let type = window.localStorage.getItem('contractType');
                this.$router.push("personal_info?type="+type+"&contract_id="+contract_id+"&progress="+'true');
                window.localStorage.setItem('personal_complete', 'incomplete');
            },
            saveScreenShot (image,name) {
                let data = {
                    "agent_id" : window.localStorage.getItem('agentId'),
                    "contract_type" : window.localStorage.getItem('contractType'),
                    "image" : image,
                    "tempid" : this.tempId,
                    "name" : name,
                };
                let config = {
                    method: 'post',
                    url: process.env.VUE_APP_CORENROLL_SYSTEM+'/save-contract-screenshot',
                    headers: { },
                    data : data
                };

                axios(config)
                    .then(function (response) {
                        if (response.data.status == 'success') {
                            console.log('screenshot saved');
                        }
                    })
                    .catch(function (error) {
                        console.log('screenshot not saved');
                        console.log(error);
                    });
            },
            scrollTop(){
                window.scrollTo(0,0);
            }    
        }
    };
</script>