<template>
    <div class="content_wrapper" >
         <agent-info></agent-info>
        <div class="contract_content" ref="save">
            <contract-form-sidebar/>

            <div class="right_container">
                <h3>MASTER COMMISSION SHEET - OF {{headerType}}</h3>
            
                <div class="master-commission" v-if="items.length > 0">
                    <table id="commission-sheet" style="width: 100%;">
                        <tr>
                            <th>Supplemental and Lifestyle Plans </th>
                            <th>TIER</th>
                            <th v-if="1 <= level">Level 1</th>                         
                            <th v-if="2 <= level">Level 2</th>                         
                            <th v-if="3 <= level">Level 3</th>                         
                            <th v-if="4 <= level">Level 4</th>                         
                            <th v-if="5 <= level">Level 5</th>                         
                            <th v-if="6 <= level">Level 6</th>                         
                            <th v-if="7 <= level">Level 7</th>                         
                            <th v-if="8 <= level">Level 8</th>                         
                            <th v-if="9 <= level">Level 9</th>                     
                        </tr>
                        <!-- <tr> -->
                        <tr v-for="(item,index) in items" :key="index">
                            <td>{{ item.plan_name }}</td>
                            <td>{{ item.tier_name }}</td>
                            <td v-if="item.l1">{{ item.l1 }}</td>
                            <td v-if="item.l2">{{ item.l2 }}</td>
                            <td v-if="item.l3">{{ item.l3 }}</td>
                            <td v-if="item.l4">{{ item.l4 }}</td>
                            <td v-if="item.l5">{{ item.l5 }}</td>
                            <td v-if="item.l6">{{ item.l6 }}</td>
                            <td v-if="item.l7">{{ item.l7 }}</td>
                            <td v-if="item.l8">{{ item.l8 }}</td>
                            <td v-if="item.l9">{{ item.l9 }}</td>
                        </tr>
                    </table>
                </div>
                <div v-else class="master-commission">
              <table  id="commission-sheet" style="width: 100%;">
                  <tr v-for="(value, index) in responseContract" :key="index">
                      <td>
                        <strong>{{ value.plan_name || capitalizeFirstLetter(value.contract_item_display_name) }}</strong>
                      </td>
                      <td v-if="value.configuration && value.configuration.hasOwnProperty('Lifeline Bronze') && value.configuration.hasOwnProperty('Lifeline Silver, Gold & Platinum')">
                        <table style="width: 100%;">
                          <thead>
                            <tr v-for="(configValue, configKey) in value.configuration" :key="'header-' + configKey">
                              <th >
                                <strong>{{ configKey }}</strong>
                              </th>
                              <th >
                                <table style="width: 100%;">
                                  <thead>
                                    <tr v-for="(configVal, configKey , index) in configValue" :key="'tier-header-' + configKey" >
                                      <template v-if="index === 0">
                                      <th>
                                        <strong>Tier</strong>
                                      </th>
                                      <th v-for="(tierVall, tierrKey) in configVal" :key="'tier-header-' + tierrKey">
                                        <strong >
                                                {{ tierrKey }}
                                              </strong>
                                      </th>
                                    </template>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr v-for="(configVal, configKey) in configValue" :key="'data-' + configKey">
                                      <td > 
                                        <strong >{{ configKey }}</strong>
                                      </td>
                                      <td v-for="(conval, conKey) in configVal" :key="'data-' + conKey">
                                        <strong >{{ conval }}</strong>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </th>
                            </tr>
                          </thead>
                        </table>
                      </td>
                      <td v-else-if="value.configuration ">
                        <table style="width: 100%;">
                          <thead>
                            <tr>
                              <th v-for="(configValue, configKey) in value.configuration" :key="'header-' + configKey">
                                <strong>{{ configKey }}</strong>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td v-for="(configValue, configKey) in value.configuration" :key="'data-' + configKey">
                                <strong v-if="configValue == '0.0%'">{{'Paid By Up-Line'}}</strong>  
                                <strong v-else>{{ configValue }}</strong>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                      <td v-else>
                        <strong v-if="value.commission == '0.00'">{{'Paid By Up-Line'}}</strong>                          
                        <strong v-else-if="value.commission == '' || value.commission == null">{{'N/A'}}</strong>                          
                        <strong v-else>{{value.commission}}</strong>                          
                      </td>
                  </tr>
              </table>
            </div>
                   

                   <div class="button_container">
                     <button class="back" type="button" name="button" @click="navigatePrevious()">Back</button>
                      <div class="button">
                    <!-- <a href="#"><button class="save">Save</button></a> -->

                    <button class="next" @click="saveMasterInfo()">Next</button>
                </div>
                </div>

            </div>
        </div>
    </div>
</template>
<script>
/*eslint-disable */
import ContractFormSidebar from '../partial/ContractFormSidebar.vue';
import axios from 'axios';
import AgentInfo from "../common/AgentInfo.vue"
import html2canvas from 'html2canvas';    
import * as  Helper from '../../utlis/helper.js';
export default {

    components:{ AgentInfo, ContractFormSidebar },
    data(){
        return{
            tempId: '',
            level: 0,
            agent_id: '',
            items : [], 
            contractType:'',
            headerType:'',
            responseContract:[],
        };
    },
    mounted(){
        let app = this;
        app.tempId = window.localStorage.getItem('tempId');
        app.agent_id= window.localStorage.getItem('agentId');
        app.headerType = localStorage.getItem('headerType')
        app.contractType= window.localStorage.getItem('contractType');
        app.contract_id= window.localStorage.getItem('contract_id');
        app.getCommissionSheet();
    },
    methods: {
        getCommissionSheet(){
          let loader = this.$loading.show();
            let app = this;
        let url = process.env.VUE_APP_PURENROLL_SYSTEM + `/masterSheetForCategoryLevel/${btoa(app.contract_id)}`;
        axios
          .get(url)
                .then(function (response) {
                    console.log(response.data.data);

                    let newData = [];
                    let responseData = response.data;
                    if(responseData.contract_type){
                        app.responseContract = responseData.data
                    }
                    else{
                        if (responseData.data.length > 0 && responseData.data[0].plan_tier.length > 0) {
                            const firstConfig = responseData.data[0].plan_tier[0].configuartion;
                            app.level = Object.keys(firstConfig).length;
                        }
                        for (const [key, value] of Object.entries(responseData.data)) {
                        console.log(key);
                        if (value.is_same == '1') {
                            var obj = {
                                plan_name:value.plan_name,
                                tier_name:'All',
                            }
                            let config = value.plan_tier[0].configuartion;
                            Object.keys(config).forEach(key => {
                                if(config[key] == '0.00') {
                                    config[key] = 'Paid By Up-Line';
                                }
                                if(config[key] == '' || config[key] == null) {
                                    config[key] = 'N/A';
                                }
                                
                                obj[key] = config[key];
                            });
                            newData.push(obj);
                        } else {
                           let allTierOrder = {'IO':'','IS':'','IC':'','IF':''}
                            for (const [keyTier, valueTier] of Object.entries(value.plan_tier)) {
                                // debugger;
                                console.log(keyTier);                         
                                if(valueTier.tier == 'IO') {
                                     obj = {
                                        tier_name:valueTier.tier,
                                        plan_name:value.plan_name
                                    }
                                } else {
                                     obj = {
                                        tier_name:valueTier.tier,
                                        plan_name:''
                                    }
                                }
                                let config = valueTier.configuartion;

                                Object.keys(config).forEach(key => {
                                    if(config[key] == '0.00') {
                                        config[key] = 'Paid By Up-Line';
                                    }
                                    if(config[key] == '' || config[key] == null) {
                                        config[key] = 'N/A';
                                    }
                                    obj[key] = config[key];
                                });
                                allTierOrder[valueTier.tier] = obj
                               
                                console.log(newData);
                            }
                            for (const [keyOrder, valueOrder] of Object.entries(allTierOrder)) {
                                console.log(keyOrder);
                                 newData.push(valueOrder);
                            }
                        }
                    }
                    app.items = newData;
                    }

                    
                })
                .catch(function (error) {
                    if(error.response.data.message == 'Form completed.'){
                      window.location = '/contract-form/contract_message/completed';
                  }else if(error.response.data.message == 'Agent does not exist.'){
                      window.location = '/contract-form/contract_message/not_found';
                  } else {
                    window.location = '/contract-form/contract_message/error';
                  }
                })
                .finally(() => {
                  loader.hide();
                });
        },
        capitalizeFirstLetter(str) {
        return Helper.capitalizeFirstLetter(str);
      },
        saveMasterInfo: function () {
          let loader = this.$loading.show();
            const el = this.$refs.save;
            (async () => {
                this.saveScreenShot((await html2canvas(el,{scrollY: -window.scrollY})).toDataURL('image/png'),'masterCommission');
            })();
            let app = this;
            let params = {
            tempid: app.tempId,
            };
            axios.post(process.env.VUE_APP_CORENROLL_SYSTEM+'/save-master-commission', params)
            .then(function (response) {
              if (response.data.status == 'success') {
                console.log("success");
                window.localStorage.setItem('sheet_complete', 'complete');
                app.$router.push("agreement_and_signature");

                
              }
            })
            .catch(function (error) {
              console.log(error.response.data.data);
              
            }).finally(() => {
          loader.hide();
        });
        },
        navigatePrevious() {
            //Update APIs
            //Navigate to next page
            this.$router.push("billing_info");
            window.localStorage.setItem('billing_complete', 'incomplete');
       
        },
        saveScreenShot (image,name) {
       
            let data = {
                "agent_id" : window.localStorage.getItem('agentId'),
                "contract_type" : window.localStorage.getItem('contractType'),
                "image" : image,
                "name" : name,
                "tempid" : this.tempId,
            };
            let config = {
                method: 'post',
                url: process.env.VUE_APP_CORENROLL_SYSTEM+'/save-contract-screenshot',
                headers: { },
                data : data
            };

            axios(config)
                .then(function (response) {
                    if (response.data.status == 'success') {
                        console.log('screenshot saved');
                    }
                })
                .catch(function (error) {
                    console.log('screenshot not saved');
                    console.log(error);
                });
        },
        scrollTop(){
            window.scrollTo(0,0);
        }
    },
}
</script>



<style src="../../assets/css/contract_form/commission.css" scoped>

</style>