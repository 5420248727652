<template>
  <div class="container-fluid">
    <div class="row body-main-row">
      <div class="member-body-content-wrap member_dashboard">
        <div class="view-message">
          <div class="contents">
            <div class="messages_new">
              <div class="message_dropdown">
                <h3 style="vertical-align:text-bottom;">Messages</h3>

              </div>
              <div class="message_table_new">
                <div >
                  <b-card no-body >
                    <b-skeleton-table v-if="message_loader" :rows="5" :columns="2" :table-props="{ bordered: true, striped: true }"></b-skeleton-table>
                    <b-tabs pills card vertical v-if="!message_loader">
                      <b-tab v-for="(item, index) in messages.records" :key="index" @click="openModal('message_' + item.mid,item.mid)">
                        <template #title>
                          <div class="list-icon">
                            <img src="../assets/images/message-icon.svg" alt="">
                          </div>
                          <div class="list-sub">
                            <h3>{{ item.subject }}</h3>
                            <p>{{ item.date_created }}</p>
                          </div>
                        </template>
                        
                      <b-card-text :id="'message_' + item.mid" class="message-details-div">
                        <b-skeleton-table v-if="message_loader" :rows="2" :columns="1" :table-props="{ bordered: true, striped: true }"></b-skeleton-table>
                        <div class="rcv-msgcontent">
             <div class="rcv-msg-sender">
            <div class="rcv-snder-icon">
             <img :src="item.sender_details.image ?? image"  alt="" class="msg-userIcon" />
              <div class="rcv-sender-details">
                <h5>{{item.sender_details?.name}} - {{item.from}}</h5>
                <p>
                  <i class="far fa-calendar-alt"></i>
                  {{
                    item.date_created | date_format("YYYY-MM-DD", "MM/DD/YYYY", item.date_created)
                  }}
                </p>
              </div>
            </div>
          </div>
          <h5>Title: {{ item.subject }}</h5>
          <hr />
          <div class="ql-snow">
            <div class="ql-editor">
              <p v-html="item.message" />
            </div>
          </div>
       <div v-if="item.attachments && item.attachments.length > 0">
        <h6>Attachments:</h6>
        <ul style="list-style: none">
          <li v-for="(attachment, attachmentindex) in item.attachments" :key="attachmentindex">
            <a :href="attachment.file_url" target="__blank" download>
              <i class="fa fa-file"></i>
              {{ attachment.title }}
            </a>
          </li>
        </ul>
      </div>
        </div>
                      </b-card-text></b-tab>
                    </b-tabs>
                  </b-card>
                </div>

                <!-- <div class="table_content">
                  <b-skeleton-table v-if="message_loader" :rows="5" :columns="3" :table-props="{ bordered: true, striped: true }"></b-skeleton-table>
                  <table v-if="!message_loader">
                    <thead>
                      <tr>
                        <td>Date</td>
                        <td style="text-align:center;">Subject</td>
                        <td style="text-align:center!important;">Action</td>
                      </tr>
                    </thead>

                    <tbody>
                      <tr v-for="(item, index) in messages.records" :key="index">
                        <td >{{ item.date_created }}</td>
                        <td style="text-align:center;">{{ item.subject }}</td>
                        <td style="text-align:center!important;"><button @click="openModal('message_' + item.mid,item.mid)" class="read">
                            Read More
                          </button>
                        <a class="delete-message-button" style="" @click="showDeleteModal(item.mid)"><i class="far fa-trash-alt" aria-hidden="true" style="color:#606060; font-size:18px;align-items:center; margin-left: 10px;" title="Delete Message"></i></a>
                          </td>
                      </tr>
                    </tbody>
                  </table>
                  <div class="overflow-auto">
                    // Use text in props
                    <b-pagination v-model="pagination.current" :total-rows="pagination.total" first-text="First" prev-text="Prev" next-text="Next" last-text="Last" :per-page="pagination.perPage" style="justify-content: center; margin-top: 20px" :page-click="changePage" :bvEvent="changePage" @change="changePage"></b-pagination>
                  </div>
                </div> -->
              </div>
              <!-- <b-modal v-for="(item, index) in messages.records" :key="index" :title="item.subject" :id="'message_' + item.mid" class="message_modal" :centered="true" :hide-footer="true">
                <div class="modal-body">
                  <div class="message_body" v-html="item.message"></div>

                  <div v-if="item.attachments.length" class="attchement_details">
                    <h5>Attachments:</h5>
                    <div class="row" v-for="(attachment, attachmentindex) in item.attachments" :key="attachmentindex">
                      <div class="icon_item" @click="downloadFile(attachment.file_url)">
                        <div class="icon">
                          <img src="../assets/images/document_icon.png" />
                        </div>
                        <div class="resource_item">
                          <p>{{ attachment.title }}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="modal-footer"></div>
              </b-modal> -->
            </div>
          <b-modal  @ok="deleteMessage" :id="'deleteMessageDetails'" title="Delete Message" ok-title="Confirm" :centered="true" size="lg">
            <p class="confirmation">
              Are you sure you want to delete this message?
            </p>
          </b-modal>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>



<script>
import dummyImage from "@/assets/images/user.svg";
import axios from "axios";
import helper from "../resource/Helper"

export default {
   computed: {
    image() {
      return dummyImage;
    },
  },
  data() {
    return {
      messages: [],
      selected: "All",
      view_options: [
        { item: "All", name: "All" },
        { item: "Archive", name: "Archive" },
        { item: "Active", name: "Active" },
      ],
      message_loader: true,
      pagination: {
        current: 1,
        total: 0,
        perPage: 10,
      },
      messageId:''
    };
  },
  mounted() {
    this.getMessage();
  },
  methods: {
    getMessage() {
       this.message_loader = true;
      let app = this;
      return axios
        .get(process.env.VUE_APP_CORENROLL_SYSTEM_API_V2+"get-received-message-list?page="+this.pagination.current+"&page_size=10")
        .then((response) => {
          app.messages = response.data;
          this.pagination.total = response.data.total_records
        }).finally((res) => {
        this.message_loader = false;
        })
    },
    openModal(id,mid) {
      axios.put(process.env.VUE_APP_CORENROLL_SYSTEM_API_V2+"update-message-seen-status?mid=" + mid);
    },
    downloadFile(fileUrl) {
      window.open(fileUrl, "_blank");
    },
   changePage(pageNumber) {
     this.pagination.current = pageNumber
     this.getMessage()
    },
    showDeleteModal(messaageId) {
      this.messageId = messaageId;
      this.$bvModal.show("deleteMessageDetails");
    },
    deleteMessage() {
        let app = this;
         axios
        .delete(process.env.VUE_APP_CORENROLL_SYSTEM_API_V2+"delete-received-message?mid=" + this.messageId)
        .then((response) => {
          if (response.data.status == "success") {
            helper.infoMessage(
              app,
              response.data.status,
              response.data.message
            );
            app.$bvModal.hide("deleteMessageDetails");
            //this.message_loader = true;
            this.getMessage();
          }
        })
        .catch((error) => {
          app.$bvModal.hide("deleteMessageDetails");
          if (error.response.data.status == "error") {
            helper.infoMessage(app, "error", error.response.data.message);
          }
        });
    },
        updateMessageSeenStatus(mid) {
      
        
    }
  },
};
</script>
<style>
 @import "../assets/css/view_messages.css"; 
</style>
<style>
.member-body-content-wrap.member_dashboard {
  min-width: calc(100% - 200px);
}
@media (max-width: 992px) {
  .member-body-content-wrap.member_dashboard {
    min-width: calc(100% - 45px);
  }
}
.contents {
  padding: 0!important;
  min-height: calc(100vh - 305px);
}
.message_dropdown h3 {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 20px;
}
.message_table_new .card {
  border: none;
  background: transparent;
  padding-bottom: 20px;
}
.tabs.row.no-gutters {
  padding: 0;
  border: 0;
  margin: 0;
  border-radius: 0;
  background: transparent;
}
.tabs.row.no-gutters .col-auto {
  background-color: #fff;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 2px 15px rgb(216 227 235 / 33%);
  max-width: 30%;
  margin-bottom: 30px;
}
.tabs.row.no-gutters .col-auto ul {
  max-height: 600px;
  min-height: 400px;
  overflow-y: auto;
  margin: 0!important;
  padding: 0!important;
  background: transparent;
  width: 100%;
  display: block;
}
.tabs.row.no-gutters .col-auto ul li {
  display: block;
  width: 100%;
}
.tabs.row.no-gutters .col-auto ul li .nav-link {
  color: #2c3f58;
  padding: 20px;
  border-bottom: 1px solid #eee;
}
.tabs.row.no-gutters .col-auto ul li .nav-link.active {
  background: #eee;
  border-radius: 0;
}
.tabs.row.no-gutters .col-auto ul li .nav-link .list-icon {
  display: inline-block;
  height: 35px;
  width: 35px;
}
.tabs.row.no-gutters .col-auto ul li .nav-link .list-sub {
  margin-left: 50px;
  margin-top: -40px;
}
.tabs.row.no-gutters .col-auto ul li .nav-link .list-sub h3 {
  font-size: 14px;
  margin-bottom: 8px;
  font-weight: bold;
}
.tabs.row.no-gutters .col-auto ul li .nav-link .list-sub p {
  font-size: 11px;
  opacity: 0.7;
  margin-bottom: 0;
}
.tabs.row.no-gutters .tab-content {
  padding-left: 30px;
}
.tabs.row.no-gutters .tab-content .card-body {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 15px rgb(216 227 235 / 33%);
  min-height: 300px;
  padding: 40px 25px;
}
.tab-content .card-body .message-details-div {
  position: relative;
}
.tab-content .card-body .message-details-div h5 {
  font-weight: bold;
  font-size: 18px;
}
.tab-content .card-body .message-details-div .delete-message-button {
  position: absolute;
  right: 0px;
  top: -15px;
  cursor: pointer;
}
.attchement_details .icon_item .icon {
  width: 36px;
  height: 32px;
  margin-top: 2px;
}
.attchement_details .icon_item .icon img {
  width: 14px;
  height: 18px;
  margin-top: 5px;
}
.at-list {
  margin-top: 10px;
}
.attchement_details .icon_item .resource_item {
  margin-left: 10px;
}
.tabs.row.no-gutters .col-auto ul::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}
.tabs.row.no-gutters .col-auto ul::-webkit-scrollbar-track {
    background: transparent;
    
}
.tabs.row.no-gutters .col-auto ul::-webkit-scrollbar-thumb {
    background: #d6d6d6;
    border-radius: 2px;
}
.tabs.row.no-gutters .col-auto ul::-webkit-scrollbar-thumb:hover {
    background: #8f8b8b80;
}
@media (max-width: 768px) {
  .tabs.row.no-gutters .col-auto {
    max-width: 100%;
    width: 100%;
  }
  .tabs.row.no-gutters .tab-content {
    padding-left: 0px;
  }
  .tabs.row.no-gutters .col-auto {
    /* max-width: 30% !important; */
  }
}
</style>