var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "container-fluid"
  }, [_c('div', {
    staticClass: "row body-main-row"
  }, [_c('div', {
    staticClass: "member_dashboard member-body-content-wrap"
  }, [_c('div', {
    staticClass: "provider-list-wrap"
  }, [_c('div', {
    staticClass: "contents"
  }, [_vm._m(0), _c('div', [_c('div', [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12"
  }, [_vm._m(1), _vm._l(_vm.providerList, function (provider, index) {
    return _c('div', {
      key: index,
      staticClass: "row provider-list-container"
    }, [_c('div', {
      staticClass: "col-3 d-flex justify-content-center provider-logo"
    }, [_c('img', {
      attrs: {
        "role": "button",
        "src": provider.logo,
        "alt": ""
      },
      on: {
        "click": function ($event) {
          return _vm.routeToProviderLink(provider.link);
        }
      }
    })]), _c('div', {
      staticClass: "col-3 provider-data-alignment"
    }, [_c('p', {
      staticClass: "d-flex justify-content-center mb-0"
    }, [_vm._v(" " + _vm._s(provider.name) + " ")])]), _c('div', {
      staticClass: "col-3 provider-data-alignment"
    }, [_c('p', {
      staticClass: "d-flex justify-content-center mb-0"
    }, [_vm._v(" " + _vm._s(provider.network) + " ")])]), _c('div', {
      staticClass: "col-3 text-center provider-data-alignment"
    }, [_c('a', {
      attrs: {
        "href": provider.link,
        "target": "_blank"
      }
    }, [_vm._v(" Provider Search")])])]);
  })], 2)])])])])])])])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "provider-title mb-4"
  }, [_c('h1', [_vm._v("Provider List")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "row provider-heading-container"
  }, [_c('div', {
    staticClass: "col-3 text-center"
  }, [_c('strong')]), _c('div', {
    staticClass: "col-3 text-center"
  }, [_c('strong', [_vm._v("Name")])]), _c('div', {
    staticClass: "col-3 text-center"
  }, [_c('strong', [_vm._v("Network")])]), _c('div', {
    staticClass: "col-3 text-center"
  }, [_c('strong', [_vm._v("Action")])])]);

}]

export { render, staticRenderFns }