<template>
  <div class="initial_page">
    <div class="container">
      <div class="row ticket-row-wrap mt-3">
        <div class="col-lg-4 col-md-12 p-0 ticket-col-left">
          <div class="ticket-left-bg-wrapper text-center">
            <h2>Having Problems ?</h2>
            <p class="mt-4">
              Please fill the form and submit the issue to support team.
            </p>
          </div>
        </div>
        <div class="col-lg-8 col-md-12">
          <div class="ticket-form-wrap login-form">
            <div class="auth-form pt-0">
              <div class="d-flex justify-content-between mb-4">
                <h2 class="clr-theme">Submit An Issue</h2>
                <div>
                  <router-link to="/" class="go-home-btn"
                    ><i class="fa fa-home home-icon"></i>Go To Home</router-link
                  >
                </div>
              </div>
              <div class="">
                <form @submit.stop.prevent="submit" ref="ticketForm">
                  <div class="form-group row">
                    <div class="col-lg-6 col-md-12 col-sm-12 mb-3">
                      <input-text
                        :rules="{ required: true }"
                        label="First Name"
                        label-for="first-name"
                        :vmodel.sync="form.first_name"
                        input-class="shadow-none"
                        :vObject="$v.form.first_name"
                      />
                    </div>
                    <div class="col-lg-6 col-md-12 col-sm-12 mb-3">
                      <input-text
                        :rules="{ required: true }"
                        label="Last Name"
                        label-for="last-name"
                        :vmodel.sync="form.last_name"
                        input-class="shadow-none"
                        :vObject="$v.form.last_name"
                      />
                    </div>
                  </div>
                  <div class="form-group row">
                    <div class="col-lg-6 col-md-12 col-sm-12 mb-3">
                      <input-text
                        :rules="{ required: true }"
                        label="Email"
                        label-for="email"
                        :vmodel.sync="form.email"
                        input-class="shadow-none"
                        :vObject="$v.form.email"
                      />
                    </div>
                    <div class="col-lg-6 col-md-12 col-sm-12 mb-3">
                      <input-text
                        label="Phone"
                        label-for="phone"
                        :vmodel.sync="form.phone"
                        input-class="shadow-none"
                      />
                    </div>
                  </div>
                  <div class="form-group row">
                    <div class="col-lg-6 col-md-12 col-sm-12 mb-3">
                      <input-text
                        :rules="{ required: true }"
                        label="Subject"
                        label-for="subject"
                        :vmodel.sync="form.subject"
                        input-class="shadow-none"
                        :vObject="$v.form.subject"
                      />
                    </div>
                    <div class="col-lg-6 col-md-12 col-sm-12 mb-3">
                      <input-single-select
                        label="Category"
                        :vmodel.sync="form.category_id"
                        :options="
                          categories.length
                            ? categories.map((item) => item.id)
                            : []
                        "
                        :custom-label="
                          categories.length
                            ? (id) =>
                                categories.find((item) => item.id == id).name
                            : () => {}
                        "
                        :rules="{ required: true }"
                        :vObject="$v.form.category_id"
                      />
                    </div>
                  </div>
                  <div class="form-group row">
                    <input-quill-editor
                      :vmodel.sync="form.message"
                      label="Message"
                      :rules="{ required: true }"
                      placeholder="Add your message here ..."
                      :vObject="$v.form.message"
                    />
                  </div>
                  <div class="form-group row">
                    <div class="col-sm-12 mb-3">
                      <input-file-multiple
                        label="Upload Document"
                        validation-label="document"
                        :vmodel.sync="form.issue_document"
                        :vObject="$v.form.issue_document"
                      >
                        <template #labelMessage>
                          <span
                            class="upload-message"
                            :id="`ticket-upload-files`"
                            ><i class="fa fa-exclamation"></i>
                            <b-popover
                              :target="`ticket-upload-files`"
                              placement="top"
                              triggers="hover focus"
                              :content="`Allowed file types : jpeg,png,jpg,gif,svg,pdf,xls,xlsx,doc,docx,csv & txt up to 4Mb`"
                              id="ticket-upload-file-popover"
                            ></b-popover>
                          </span>
                        </template>
                      </input-file-multiple>
                    </div>
                  </div>
                  <div class="form-group row justify-content-center">
                    <div class="col-lg-3 col-md-4">
                      <button
                        type="submit"
                        class="ticket-submit-btn pull-right"
                      >
                        <b-spinner class="custom-spin" v-if="isSubmit" />{{
                          isSubmit ? "Submitting ..." : "Submit"
                        }}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import InputFileMultiple from "../components/form/InputFileMultiple.vue";
import InputQuillEditor from "../components/form/InputQuillEditor.vue";
import InputSingleSelect from "../components/form/InputSingleSelect.vue";
import { required, email } from "vuelidate/lib/validators";
import InputText from "../components/form/InputText.vue";
import helper from "@/resource/Helper";
import axios from "axios";
const FORM = {
  email: "",
  first_name: "",
  last_name: "",
  phone: "",
  subject: "",
  message: "",
  user_type: "M", //for member dashboard tickets
  category_id: "",
  issue_document: [],
};

export default {
  components: {
    InputText,
    InputSingleSelect,
    InputQuillEditor,
    InputFileMultiple,
  },
  name: "TicketSubmission",
  data: () => ({
    form: {
      ...FORM,
    },
    categories: [],
    isSubmit: false,
  }),
  validations: {
    form: {
      first_name: {
        required,
      },
      last_name: {
        required,
      },
      email: {
        required,
        email,
      },
      subject: {
        required,
      },
      category_id: {
        required,
      },
      message: {
        required,
      },
    },
  },
  methods: {
    getCategories() {
      let loader = this.$loading.show({
        container: this.$refs.ticketForm,
      });
      const url = `${process.env.VUE_APP_TICKETING_API}/api/v1/tickets/category`;
      axios
        .get(url)
        .then((res) => {
          this.categories = res.data.data;
        })
        .catch(() => {
          this.categories = [];
        })
        .finally(() => {
          loader.hide();
        });
    },
    submit() {
      if (!this.isFormValid()) {
        return;
      }
      let loader = this.$loading.show({
        container: this.$refs.ticketForm,
      });
      const url = `${process.env.VUE_APP_TICKETING_API}/api/v1/tickets/send`;
      let formData = new FormData();
      for (const [key, value] of Object.entries(this.form)) {
        formData.append(key, value);
        if (
          key == "issue_document" &&
          this.form.issue_document &&
          this.form.issue_document.length
        ) {
          formData.delete("issue_document"); //reset
          for (let i = 0; i < this.form.issue_document.length; i++) {
            formData.append(`${key}[${i}]`, this.form.issue_document[i]);
          }
        }
      }
      this.isSubmit = true;
      axios
        .post(url, formData)
        .then((res) => {
          helper.infoMessage(this, "success", res.data.message);
          this.resetForm();
        })
        .catch((err) => {
          helper.infoMessage(this, "error", err.response.data.message);
        })
        .finally(() => {
          loader.hide();
          this.isSubmit = false;
        });
    },
    resetForm() {
      this.form = { ...FORM };
      this.$nextTick(() => this.$v.$reset());
    },
    isFormValid() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return false;
      }
      return true;
    },
  },
  created() {
    this.getCategories();
  },
};
</script>
<style>
.ticket-form-wrap label {
  font-size: 16px !important;
  color: #909090 !important;
  margin-bottom: 8px !important;
}
#ticket-upload-file-popover {
  border-radius: 8px !important;
  box-shadow: 0 0 8px #dfe8ea !important;
  border: none !important;
  top: -6px !important;
  min-width: 525px !important;
}
#ticket-upload-file-popover .arrow::before,
.bs-popover-auto[x-placement^="top"] > .arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #ddd8d840;
}
.ticket-form-wrap div.multiselect {
  width: 100% !important;
  margin-left: 0 !important;
}
.ticket-form-wrap .form-group {
  margin-bottom: 3px !important;
}
</style>
<style scoped>
.ticket-left-bg-wrapper {
  background: url("~@/assets/images/bubble-bg.png") no-repeat;
  background-size: cover;
  height: 100%;
  padding: 4rem 2rem;
  display: flex;
  flex-direction: column;
  color: #fff;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.ticket-form-wrap {
  padding: 4rem 2rem;
}
.upload-message {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background: #eee;
  margin: 0px 4px 2px 8px;
  font-size: 13px;
  cursor: pointer;
}
.ticket-submit-btn {
  border: 0;
  background: var(--secondary-color);
  color: #fff;
  padding: 10px 13px;
  font-weight: 600;
  border-radius: 6px;
  outline: none;
  transition: all ease 0.6s;
  min-width: 190px;
}
.go-home-btn {
  padding: 10px;
  background: #fff;
  color: var(--danger-text-color);
  transition: all 0.3s;
  border: 1px solid var(--secondary-color);
  border-radius: 8px;
  font-size: 14px;
}
.go-home-btn:hover {
  background: var(--secondary-color);
  color: #fff;
}
.custom-spin {
  height: 18px !important;
  width: 18px !important;
  margin-right: 5px;
}
.ticket-row-wrap {
  background: #fff;
  border-radius: 10px;
}
.home-icon {
  margin-right: 4px !important;
}
.clr-theme {
  color: var(--danger-text-color);
}
</style>
