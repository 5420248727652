var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "id": "footer"
    }
  }, [_c('div', {
    staticClass: "footer-new"
  }, [_c('div', {
    staticClass: "container-fluid"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-sm-6"
  }, [_c('div', {
    staticClass: "about"
  }, [_c('router-link', {
    attrs: {
      "to": {
        name: 'DashboardNew'
      }
    }
  }, [_c('img', {
    staticClass: "footer_logo",
    attrs: {
      "src": require("@/assets/images/logo-iha.svg"),
      "alt": "logo"
    }
  })]), _c('p', [_vm._v(" If you have some technical difficulties Go to: "), _c('a', {
    attrs: {
      "target": "_blank",
      "href": _vm.ticketing_url
    }
  }, [_vm._v(" Issues Dashboard")])]), _c('br'), _c('span', [_vm._v("© Copyright " + _vm._s(new Date().getFullYear()) + ". All Rights Reserved")])], 1)]), _c('div', {
    staticClass: "col-sm-6"
  })])])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }